import Holidays from "date-holidays";
import config from "../../config";
import moment from "moment-timezone";

export const loadPublicHolidays = (initialDate) => {
    const hd = new Holidays();
    hd.init('DE', 'NW');  // Initialisiere für Deutschland, Nordrhein-Westfalen
    const LOCAL_TIMEZONE = config.timeZone; // Definiere die lokale Zeitzone für die Arbeitszeiten
    const currentYear = initialDate ? moment(initialDate).year() : moment().year();

    // Lade Feiertage für das aktuelle Jahr und das Folgejahr und filtere nur gesetzliche Feiertage
    const holidaysThisYear = hd.getHolidays(currentYear).filter(holiday => holiday.type === 'public');
    const holidaysNextYear = hd.getHolidays(currentYear + 1).filter(holiday => holiday.type === 'public');

    const holidays = [...holidaysThisYear, ...holidaysNextYear];

    // Transformiere die Feiertage in das benötigte Format für FullCalendar
    return holidays.map(holiday => ({
        title: holiday.name,
        start: moment.tz(holiday.date, LOCAL_TIMEZONE).format('YYYY-MM-DD'), // Startdatum im lokalen Format
        end: moment.tz(holiday.date, LOCAL_TIMEZONE).add(1, 'days').format('YYYY-MM-DD'), // Enddatum als nächster Tag im lokalen Format
        allDay: true
    }));
};