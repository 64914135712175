export const academicGrid = {
    "winter": [
        {
            "name": "A1",
            "start": "2025-10-06",
            "end": "2025-10-10"
        },
        {
            "name": "A2",
            "start": "2025-10-13",
            "end": "2025-10-17"
        },
        {
            "name": "A3",
            "start": "2025-10-20",
            "end": "2025-10-24"
        },
        {
            "name": "A4",
            "start": "2025-10-27",
            "end": "2025-10-31"
        },
        {
            "name": "A5",
            "start": "2025-11-03",
            "end": "2025-11-07"
        },
        {
            "name": "B1",
            "start": "2025-11-10",
            "end": "2025-11-14"
        },
        {
            "name": "B2",
            "start": "2025-11-17",
            "end": "2025-11-21"
        },
        {
            "name": "B3",
            "start": "2025-11-24",
            "end": "2025-11-28"
        },
        {
            "name": "B4",
            "start": "2025-12-01",
            "end": "2025-12-05"
        },
        {
            "name": "B5",
            "start": "2025-12-08",
            "end": "2025-12-12"
        },
        {
            "name": "C1",
            "start": "2025-12-15",
            "end": "2025-12-19"
        },
        {
            "name": "C2",
            "start": "2026-01-05",
            "end": "2026-01-09"
        },
        {
            "name": "C3",
            "start": "2026-01-12",
            "end": "2026-01-16"
        },
        {
            "name": "C4",
            "start": "2026-01-19",
            "end": "2026-01-23"
        },
        {
            "name": "C5",
            "start": "2026-01-26",
            "end": "2026-01-30"
        }
    ],
    "summer": [
        {
            "name": "D1",
            "start": "2026-04-13",
            "end": "2026-04-17"
        },
        {
            "name": "D2",
            "start": "2026-04-20",
            "end": "2026-04-24"
        },
        {
            "name": "D3",
            "start": "2026-04-27",
            "end": "2026-05-01"
        },
        {
            "name": "D4",
            "start": "2026-05-04",
            "end": "2026-05-08"
        },
        {
            "name": "D5",
            "start": "2026-05-11",
            "end": "2026-05-15"
        },
        {
            "name": "E1",
            "start": "2026-05-18",
            "end": "2026-05-22"
        },
        {
            "name": "E2",
            "start": "2026-05-25",
            "end": "2026-05-29"
        },
        {
            "name": "E3",
            "start": "2026-06-01",
            "end": "2026-06-05"
        },
        {
            "name": "E4",
            "start": "2026-06-08",
            "end": "2026-06-12"
        },
        {
            "name": "E5",
            "start": "2026-06-15",
            "end": "2026-06-19"
        },
        {
            "name": "F1",
            "start": "2026-06-22",
            "end": "2026-06-26"
        },
        {
            "name": "F2",
            "start": "2026-06-29",
            "end": "2026-07-03"
        },
        {
            "name": "F3",
            "start": "2026-07-06",
            "end": "2026-07-10"
        },
        {
            "name": "F4",
            "start": "2026-07-13",
            "end": "2026-07-17"
        },
        {
            "name": "F5",
            "start": "2026-07-20",
            "end": "2026-07-24"
        }
    ]
};