// Kurse nach Modul filtern
import React from "react";
import {isCourseToBeScheduled} from "../utilityFunctions";

export const filterByModule = (courseObject, module) => {
    return courseObject.filter(course => course.module === module);
}

// Nicht zu terminierende Veranstaltungen filtern
export const filterByNonScheduled = (courseObject) => {
    return courseObject.filter(course => course.isNonScheduledCourse);
}

// Synchrone Veranstaltungen filtern
export const filterByNotAsynchronous = (courseObject) => {
    return courseObject.filter(course => !course.isAsynchronous);
}

// Asynchrone Veranstaltungen filtern
export const filterByAsynchronous = (courseObject) => {
    return courseObject.filter(course => course.isAsynchronous);
}

// Zählt Veranstaltungen ohne und mit eindeutiger Semesterzuordnung
function countScheduledSemesters(courseObject) {
    let scheduledSemesterCount = {};
    let noScheduledSemester = 0;

    courseObject.forEach(course => {
        // Durchlaufe jedes Event in jeder eventsGroup
        if (course.eventGroups) {
            course.eventGroups.forEach(group => {
                group.events.forEach(event => {
                    // Prüfe, ob das Event Semester im Array von schedulingConfigObjects in den extendedProps hat
                    if (event.extendedProps && event.extendedProps.schedulingConfig) {
                        let semesters = event.extendedProps.schedulingConfig.map(config => config.semester).filter(semester => semester != null);
                        if (semesters.length > 0) {
                            semesters.forEach(semester => {
                                scheduledSemesterCount[semester] = (scheduledSemesterCount[semester] || 0) + 1;
                            });
                        } else {
                            noScheduledSemester++;
                        }
                    } else {
                        noScheduledSemester++;
                    }
                });
            });
        }
    });

    // Konvertiere das Objekt in ein Array von { semester, count }-Objekten für die Rückgabe
    const scheduledSemester = Object.entries(scheduledSemesterCount).map(([semester, count]) => ({
        semester: semester,
        count: count
    }));

    return {
        noScheduledSemester,
        scheduledSemester
    };
}

// Veranstaltungsobjekt validieren
export const validateCourseObject = (courseObject) => {
    const analysis = courseObject.reduce((acc, course) => {
        if (!isCourseToBeScheduled(course)) {
            acc.totalNonScheduledCourses++; // Zähle nicht zu terminierende Veranstaltungen
            return acc;
        }

        // Grundlegende Datenvalidierung
        if (!course.id || !course.title) {
            acc.invalidCourses.push(course.id || 'Unbekannt'); // Füge Kurs-ID hinzu oder 'Unbekannt'
        }

        // Validiere eventGroups und events
        course.eventGroups.forEach(group => {
            if (!group.events.length) {
                acc.invalidEventGroups.push(group.id || 'Unbekannt');
            }
            group.events.forEach(event => {
                // Prüfe die schedulingConfig jedes Events
                if (!event.extendedProps.schedulingConfig || !event.extendedProps.schedulingConfig.length) {
                    acc.eventsWithInvalidSchedulingConfig.push(event.extendedProps.id || 'Unbekannt');
                } else {
                    // Stelle sicher, dass alle notwendigen Daten vorhanden sind
                    event.extendedProps.schedulingConfig.forEach(config => {
                        if (!config.earliestDate || !config.latestDate) {
                            acc.missingSchedulingDetails.push(event.extendedProps.id || 'Unbekannt');
                        }
                    });
                }
            });
        });

        acc.totalEventGroups += course.eventGroups.length;
        acc.totalEvents += course.eventGroups.reduce((sum, group) => sum + group.events.length, 0);
        return acc;
    }, {
        totalEventGroups: 0,
        totalEvents: 0,
        invalidCourses: [],
        invalidEventGroups: [],
        eventsWithInvalidSchedulingConfig: [],
        missingSchedulingDetails: [],
        totalNonScheduledCourses: 0
    });

    // Output für Debugging und Prüfung der Validierungsergebnisse
    // console.log('Analyseergebnisse:', analysis);

    // Hilfsfunktion zur Bestimmung der CSS-Klasse basierend auf der Anzahl von Problemen
    const getClass = (count, goal) => {
        switch (goal) {
            case "low":
                return count > 0 ? "data data-warning" : "data data-ok";
            case "high":
                return count > 0 ? "data data-ok" : "data data-warning";
            default:
                return "data data-info";
        }
    };

    return (
        <>
            <table className="siman-data">
                <tbody>
                <tr>
                    <td>Veranstaltungen insgesamt</td>
                    <td className={getClass(courseObject.length, "high")}>{courseObject.length}</td>
                </tr>
                <tr>
                    <td>Termine insgesamt</td>
                    <td className={getClass(analysis.totalEvents, "high")}>{analysis.totalEvents}</td>
                </tr>
                <tr>
                    <td>Veranstaltungen, die nicht im Stundenplan terminiert werden</td>
                    <td className={getClass(analysis.totalNonScheduledCourses, "high")}>{analysis.totalNonScheduledCourses}</td>
                </tr>
                <tr>
                    <td>
                        Ungültige Veranstaltungen
                        {analysis.invalidCourses.length > 0 &&
                            <p className="hint">{analysis.invalidCourses.join(', ')}</p>}
                    </td>
                    <td className={getClass(analysis.invalidCourses.length, "low")}>{analysis.invalidCourses.length}</td>
                </tr>
                <tr>
                    <td>
                        Termingruppen ohne gültige Termine
                        {analysis.invalidEventGroups.length > 0 &&
                            <p className="hint">{analysis.invalidEventGroups.join(', ')}</p>}
                    </td>
                    <td className={getClass(analysis.invalidEventGroups.length, "low")}>{analysis.invalidEventGroups.length}</td>
                </tr>
                <tr>
                    <td>
                        Termine mit ungültiger Planungskonfiguration
                        {analysis.eventsWithInvalidSchedulingConfig.length > 0 &&
                            <p className="hint">{analysis.eventsWithInvalidSchedulingConfig.join(', ')}</p>}
                    </td>
                    <td className={getClass(analysis.eventsWithInvalidSchedulingConfig.length, "low")}>{analysis.eventsWithInvalidSchedulingConfig.length}</td>
                </tr>
                </tbody>
            </table>
        </>
    );
}



