import { db } from '../../firebase';
import {collection, query, where, getDocs, doc} from 'firebase/firestore';
import Holidays from "date-holidays";
import config from "../../config";
import moment from "moment-timezone";

export const getEventColor = (courseType) => {
    switch (courseType) {
        case 'Seminar':
        case 'Hands On Seminar':
        case 'POL':
            return 'rgba(255, 140, 0, 1)';
        case 'Vorlesung':
            return 'rgba(26, 198, 179, 1)';
        case 'UaK':
        case 'UaKD':
        case 'UaKU':
        case 'UaKU (Blockpraktikum)':
            return 'rgba(66, 130, 180, 1)';
        case 'Blockpraktikum':
        case 'Patient*innenbegleitung':
        case 'Komm-Praktikum':
        case 'Laborpraktikum':
        case 'ÖGD-Praktikum (Exkursion)':
        case 'Praktikum':
        case 'Präparierkurs':
        case 'Praktikum (Forschung, 1 Wiederholung)':
        case 'Praktikum (Forschung, 5 Wiederholungen)':
        case 'Praktikum (Forschung)':
        case 'Interprofessionelles Praktikum':
            return 'rgba(240, 98, 146, 1)';
        case 'Tutorium':
            return 'rgba(153, 102, 204, 1)';
        case 'Skillslab':
        case 'Simulation':
            return 'rgba(0, 123, 255, 1)';
        case 'Prüfung':
        case 'PTM':
        case 'Extracurriculare Veranstaltung':
            return 'rgba(233, 85, 51, 1)';
        default:
            return 'rgba(49, 56, 61, 1)';
    }
    /*
    Kolloquium
    Wissenschaftliche Arbeit
    */
};
