import {collection, doc, getDoc, getDocs, orderBy, query, where} from "firebase/firestore";
import {db, push, serverTimestamp} from "../firebase";
import {academicGrid} from "../academicGrid";
import config from "../config";
import Holidays from "date-holidays";
import moment from "moment-timezone";

// Liefert das übergebene Semester-Array in zufälliger Reihenfolge zurück
export const shuffleSemester = (semester = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]) => {
    for (let i = semester.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [semester[i], semester[j]] = [semester[j], semester[i]];
    }
    return semester;
}

export const formatDateWithDay = (date = new Date()) => {
    return date.toLocaleString('de-DE', {
        weekday: 'long', // "long", "short", "narrow"
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false // Verwendet 24-Stunden-Format
    }) + ' Uhr'; // Fügt "Uhr" nach der Zeit hinzu
}

export const quarterHoursShift = (quarterHours = 1) => {
    // Ein Viertelstunden-Intervall in Millisekunden
    const quarterHourInMilliseconds = 15 * 60 * 1000;

    // Wähle eine zufällige Anzahl von Viertelstunden zwischen 1 und dem übergebenen Parameter quarterHours
    const randomQuarterHours = Math.floor(Math.random() * quarterHours) + 1;

    // Berechne die Zeitverschiebung basierend auf der zufällig gewählten Anzahl von Viertelstunden
    const shift = randomQuarterHours * quarterHourInMilliseconds;

    // Entscheide zufällig, ob die Verschiebung positiv oder negativ sein soll
    const direction = Math.random() < 0.5 ? -1 : 1;

    return shift * direction;
}

export const generateCustomId = (prefix = 'ID', type = 'default') => {
    const baseChars = 'ABCDEFGHJKLMNPQRSTUVWXYZ123456789'; // Verwechslungsähnliche Zeichen ausgeschlossen
    let idBody = '';

    switch (type) {
        case 'numeric':
            idBody = Array.from({ length: 4 }, () => Math.floor(Math.random() * 10)).join('');
            break;
        case 'alpha':
            idBody = Array.from({ length: 4 }, () => baseChars.charAt(Math.floor(Math.random() * 24))).join('');
            break;
        case 'alphanumeric':
        default:
            idBody = Array.from({ length: 4 }, () => baseChars.charAt(Math.floor(Math.random() * baseChars.length))).join('');
            break;
    }

    return `${prefix}-${idBody}`;
}

// Generiere die Studenten-IDs basierend auf der Anzahl von studentsPerYear aus dem config-Objekt
export const getStudentIds = Array.from({ length: config.studentsPerYear }, (_, i) => `studentID-${i + 1}`);

// Hole das Veranstaltungsobjekt zu einer Stundenplanlösung aus der Datenbank
export const fetchSolution = async (solutionId, logsRef) => {

    // Zugriff auf das Dokument mit der solutionId in der Sammlung 'solutions'
    const solutionRef = doc(db, "solutions", solutionId);

    // Versuche, das Dokument zu erhalten
    try {
        const solutionDoc = await getDoc(solutionRef);
        if (!solutionDoc.exists()) {
            console.error('Fehler: Dokument mit solutionId existiert nicht');
            return null; // Kein Ergebnis, wenn das Dokument nicht existiert
        }

        // Zugriff auf Metadaten der Lösung
        const solutionData = solutionDoc.data();
        console.log("Hauptdaten der Lösung geladen.");

        // Zugriff auf die Kurse der Lösung
        const coursesSnapshot = await getDocs(collection(solutionRef, "courses"));
        let courses = [];
        let i = 0;

        for (const courseDoc of coursesSnapshot.docs) {
            const courseRef = courseDoc.ref;
            const courseData = courseDoc.data();
            const eventGroupsCollectionRef = collection(solutionRef, "eventGroups");
            const eventGroupsQuery = query(eventGroupsCollectionRef, where("courseId", "==", courseRef));
            const eventGroupsSnapshot = await getDocs(eventGroupsQuery);
            let eventGroups = [];

            for (const groupDoc of eventGroupsSnapshot.docs) {
                const groupRef = groupDoc.ref;
                const groupData = groupDoc.data();
                const eventsCollectionRef = collection(solutionRef, "events");
                const eventsQuery = query(eventsCollectionRef, where("eventGroupId", "==", groupRef));
                const eventsSnapshot = await getDocs(eventsQuery);
                let events = [];

                eventsSnapshot.forEach(eventDoc => {
                    events.push(eventDoc.data());
                });

                eventGroups.push({
                    ...groupData,
                    events: events
                });
            }

            courses.push({
                ...courseData,
                eventGroups: eventGroups
            });

            console.log(`${i + 1} von ${coursesSnapshot.docs.length} Veranstaltungen geladen`);

            // Realtime DB Log
            if (logsRef) {
                await push(logsRef, {
                    message: `${i + 1} von ${coursesSnapshot.docs.length} Veranstaltungen geladen`,
                    timestamp: serverTimestamp()
                });
            }
            i++;
        }

        console.log("Alle Veranstaltungen und ihre Daten wurden geladen.");
        return {
            ...solutionData,
            courseObject: courses // Rückgabe des rekonstruierten courseObjects
        };

    } catch (error) {
        console.error('Fehler beim Abrufen der Ausgangslösung: ', error);
        return null;
    }
};

export const findGroupNamesByPrefix = (groupSize, groups) => {
    let matchedGroupNames = [];
    const targetPrefix = `${groupSize}G`;

    // Durchlaufe alle Gruppen und füge passende IDs zum Ergebnis hinzu
    groups.forEach(group => {
        if (group.id.startsWith(targetPrefix)) {
            matchedGroupNames.push(group.id);
        }
    });

    return matchedGroupNames;
}

export const getRandomSchedulingConfig = (event) => {
    const randomIndex = Math.floor(Math.random() * event.extendedProps.schedulingConfig.length);
    return event.extendedProps.schedulingConfig[randomIndex];
}

export const getAcademicGridDate = ({season = null, weekName = null, type = 'earliest'} = {}) => {
    /*
    // Beispiele für die Verwendung der Funktion
    console.log("Frühestes Datum insgesamt:", getAcademicGridDate({type: 'earliest'}).toLocaleDateString());
    console.log("Spätestes Datum insgesamt:", getAcademicGridDate({type: 'latest'}).toLocaleDateString());
    console.log("Frühestes Datum im Winter:", getAcademicGridDate({season: 'winter', type: 'earliest'}).toLocaleDateString());
    console.log("Spätestes Datum im Winter:", getAcademicGridDate({season: 'winter', type: 'latest'}).toLocaleDateString());
    console.log("Frühestes Datum in der Woche B2:", getAcademicGridDate({weekName: 'B2', type: 'earliest'}).toLocaleDateString());
    */
    let dates = [];

    if (season) {
        // Filter für ein spezifisches Semester, falls angegeben
        dates = academicGrid[season].map(period => period.start).concat(academicGrid[season].map(period => period.end));
    } else {
        // Kombiniere alle Daten, wenn kein Semester angegeben ist
        Object.values(academicGrid).forEach(season => {
            dates = dates.concat(season.map(period => period.start)).concat(season.map(period => period.end));
        });
    }

    if (weekName) {
        // Filter für eine spezifische Woche, wenn angegeben
        const week = (season ? academicGrid[season] : [].concat(...Object.values(academicGrid)))
            .find(period => period.name === weekName);
        if (week) {
            dates = [week.start, week.end];
        }
    }

    // Bestimme das früheste Datum
    if (type === 'earliest') {
        return new Date(Math.min(...dates.map(date => new Date(date).getTime())));
    } else if (type === 'latest') {
        return new Date(Math.max(...dates.map(date => new Date(date).getTime())));
    }

}

export const isCourseToBeScheduled = (course) => {
    // Überprüfe die neuen Bedingungen
    if (!course || course.isNonScheduledCourse) {
        return false;
    }
    if (!Array.isArray(course.eventGroups)) {
        return false;
    }
    for (const eventGroup of course.eventGroups) {
        if (!eventGroup || !Array.isArray(eventGroup.events)) {
            return false;
        }
        for (const event of eventGroup.events) {
            if (!event || !event.extendedProps || !Array.isArray(event.extendedProps.schedulingConfig)) {
                return false;
            }
            if (event.extendedProps.schedulingConfig.length === 0) {
                return false;
            }
        }
    }
    return true;
}

// Flatten des courseObjects, wobei angenommen wird, dass alle benötigten Informationen bereits in den Events enthalten sind
export const flattenCourseObject = (courseObject) => {
    return courseObject.flatMap(course =>
        course.eventGroups.flatMap(group =>
            group.events
        )
    );
}

// Rückumwandlung von allEvents zum courseObject
export const restructureCourseObject = (allEvents, originalCourseObject) => {
    // Rekonstruktion der Kursstruktur aus den Events
    let coursesMap = {};

    // Aufbau des Kurs- und Eventgruppen-Maps
    allEvents.forEach(event => {
        const eventProps = event.extendedProps;
        const courseID = eventProps.id.split('.')[0];
        const eventGroupID = `${courseID}.${eventProps.id.split('.')[1]}`;

        if (!coursesMap[courseID]) {
            // Finde den ursprünglichen Kurs im originalCourseObject, um das Schema beizubehalten
            const originalCourse = originalCourseObject.find(course => course.id === courseID);
            coursesMap[courseID] = {
                ...originalCourse,
                eventGroups: {}
            };
        }

        if (!coursesMap[courseID].eventGroups[eventGroupID]) {
            // Erstelle eine neue Eventgruppe, falls nicht vorhanden
            coursesMap[courseID].eventGroups[eventGroupID] = {
                id: eventGroupID,
                title: originalCourseObject.find(course => course.id === courseID).eventGroups.find(group => group.id === eventGroupID).title,
                events: []
            };
        }

        // Füge das Event der richtigen Eventgruppe hinzu
        coursesMap[courseID].eventGroups[eventGroupID].events.push(event);
    });

    // Umwandlung des Maps zurück in ein Array-Format
    return Object.values(coursesMap).map(course => ({
        ...course,
        eventGroups: Object.values(course.eventGroups)
    }));
};

// Liefert alle Semester aus der config
export const extractSemesters = () => {
    const sections = config.studySections;
    const allSemesters = [];
    Object.values(sections).forEach(section => {
        Object.values(section.studyYears).forEach(year => {
            allSemesters.push(...year);
        });
    });
    return allSemesters;
};

// Funktion zur Bestimmung des akademischen Jahres basierend auf dem aktuellen Datum
export const getAcademicYear = () => {
    // Wintersemester-Daten aus der Konfiguration
    const winterStart = new Date(config.lecturePeriods.lecturePeriodWinter.startDate);
    const winterEnd = new Date(config.lecturePeriods.lecturePeriodWinter.endDate);

    // Sommersemester-Daten aus der Konfiguration
    const summerStart = new Date(config.lecturePeriods.lecturePeriodSummer.startDate);
    const summerEnd = new Date(config.lecturePeriods.lecturePeriodSummer.endDate);

    // Berechnen des Start- und Enddatums für das akademische Jahr
    const winterSemesterStart = new Date(winterStart.getFullYear(), 9, 1); // 1. Oktober des Vorjahres
    const winterSemesterEnd = new Date(winterEnd.getFullYear(), 2, 31); // 31. März des Folgejahres

    const summerSemesterStart = new Date(summerStart.getFullYear(), 3, 1); // 1. April des gleichen Jahres
    const summerSemesterEnd = new Date(summerEnd.getFullYear(), 8, 30); // 30. September des gleichen Jahres

    return {
        winter: {
            startDate: winterSemesterStart,
            endDate: winterSemesterEnd
        },
        summer: {
            startDate: summerSemesterStart,
            endDate: summerSemesterEnd
        }
    };
};

// Liefere ein zufälliges Semester zurück
export const chooseRandomSemester = () => {
    let semesters = [];
    for (const section in config.studySections) {
        for (const year in config.studySections[section].studyYears) {
            // Hier fügen wir nur Semester hinzu, die größer oder gleich 7 sind
            semesters = semesters.concat(config.studySections[section].studyYears[year].filter(semester => semester === 1));
            // semesters = semesters.concat(config.studySections[section].studyYears[year]);
        }
    }
    if (semesters.length === 0) {
        console.error("Keine Semester >= 7 gefunden.");
        return null;
    }
    const randomIndex = Math.floor(Math.random() * semesters.length);
    return semesters[randomIndex];
}

// Funktion zum Abrufen aller Termine
export const fetchAllEvents = async (solutionId) => {
    if (!solutionId) {
        console.error('Keine solutionId übergeben.');
        return;
    }
    const solutionRef = doc(db, "solutions", solutionId);
    // Erstelle eine Query, die die Events nach 'start' und innerhalb dessen nach 'semester' sortiert
    const eventsQuery = query(
        collection(solutionRef, "events"),
        orderBy("start", "asc"),
        orderBy("extendedProps.semester", "asc") // Dies könnte eine Indexierung erfordern
    );
    const eventsSnapshot = await getDocs(eventsQuery);
    let allEvents = [];
    eventsSnapshot.forEach(eventDoc => {
        allEvents.push(eventDoc.data());
    });
    return allEvents;
};

// Funktion zur Prüfung, ob ein Datum ein Feiertag ist oder in der Weihnachtspause liegt
export const isHoliday = (date) => {
    const hd = new Holidays();
    hd.init('DE', 'NW'); // Deutschland, Nordrhein-Westfalen

    const momentDate = moment.tz(date, config.timeZone);
    const formattedDate = momentDate.format('YYYY-MM-DD');
    const checkDate = new Date(formattedDate + 'T00:00:00Z');
    const holidays = hd.isHoliday(checkDate);
    const christmasBreakStart = moment(config.christmasBreak.startDate).startOf('day');
    const christmasBreakEnd = moment(config.christmasBreak.endDate).endOf('day');

    if (momentDate.isBetween(christmasBreakStart, christmasBreakEnd, undefined, '[]')) {
        return true; // Das Datum liegt innerhalb der Weihnachtspause
    }

    if (holidays) {
        return holidays.some(holiday => holiday.type === 'public');
    }

    return false; // Kein Feiertag und nicht in der Weihnachtspause
};

// Funktion zum Filtern der Events nach einem bestimmten Kriterium
export const filterEvents = (events, criterion) => {
    return events.filter(event => {
        switch (criterion.type) {
            case 'subject':
                return event.extendedProps.subject === criterion.value;
            case 'id':
                return event.extendedProps.id === criterion.value;
            case 'module':
                // Angenommen, es gibt ein 'module' Feld im extendedProps
                return event.extendedProps.module === criterion.value;
            default:
                return false;
        }
    });
}

// Funktion, die prüft, ob alle Events im ersten Array vor allen Events im zweiten Array stattfinden
export const areAllEventsBefore = (allEvents, criterion1, criterion2) => {
    // Filtere die Events nach den gegebenen Kriterien
    const eventsGroup1 = filterEvents(allEvents, criterion1);
    // console.log('eventsGroup1: ', eventsGroup1);
    const eventsGroup2 = filterEvents(allEvents, criterion2);
    // console.log('eventsGroup2: ', eventsGroup2);

    // Finde das späteste Ende der Events in Gruppe 1
    const latestEndGroup1 = Math.max(...eventsGroup1.map(event => new Date(event.end).getTime()));

    // Finde den frühesten Start der Events in Gruppe 2
    const earliestStartGroup2 = Math.min(...eventsGroup2.map(event => new Date(event.start).getTime()));

    // Prüfe, ob das späteste Ende von Gruppe 1 vor dem frühesten Start von Gruppe 2 liegt
    return latestEndGroup1 < earliestStartGroup2;
}
