import React, { useState, useEffect } from 'react';
import { db } from '../../../firebase';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import SolutionSelector from "../SolutionSelector/SolutionSelector";

function SolutionAnalyzer() {

    // Lösung 1
    const [selectedResultId, setSelectedResultId] = useState("");
    const [objectives, setObjectives] = useState([]);
    const [totalCosts, setTotalCosts] = useState(0);
    const [eventsTotal, setEventsTotal] = useState(0);
    const [costsPerEvent, setCostsPerEvent] = useState(0);

    // Lösung 2
    const [selectedResultId2, setSelectedResultId2] = useState("");
    const [totalCosts2, setTotalCosts2] = useState(0);
    const [eventsTotal2, setEventsTotal2] = useState(0);
    const [costsPerEvent2, setCostsPerEvent2] = useState(0);
    const [objectives2, setObjectives2] = useState([]);

    const handleSelectResultId = (id) => setSelectedResultId(id);
    const handleSelectResultId2 = (id) => setSelectedResultId2(id);

    const fetchData = async (selectedId, setTotalCosts, setEventsTotal, setCostsPerEvent, setObjectives) => {
        const solutionSnapshot = doc(db, "solutions", selectedId);
        const docSnap = await getDoc(solutionSnapshot);

        if (docSnap.exists()) {
            const solutionData = docSnap.data();
            const total = parseFloat(solutionData.totalConstraintCosts);
            const events = parseFloat(solutionData.eventsTotal);

            if (solutionData.sourceDataRef) {
                const sourceDataSnap = await getDoc(solutionData.sourceDataRef);
                if (sourceDataSnap.exists()) {
                    const objectivesCol = collection(sourceDataSnap.ref, "objectives");
                    const objectivesSnap = await getDocs(objectivesCol);
                    const objectivesData = await Promise.all(objectivesSnap.docs.map(async doc => {
                        const objective = doc.data();
                        const constraintCosts = objective.constraints.map((constraintId) => {
                            const costDetails = solutionData.costsPerConstraint[constraintId];
                            return costDetails ? parseFloat(costDetails.totalCost || 0) : 0;
                        });
                        const totalConstraintCosts = constraintCosts.reduce((acc, curr) => acc + curr, 0);

                        return {
                            id: doc.id,
                            ...objective,
                            totalCostsPerConstraint: totalConstraintCosts
                        };
                    }));

                    setObjectives(objectivesData);
                }
            }
            setTotalCosts(Number(total.toFixed(2)));
            setEventsTotal(events);
            setCostsPerEvent(Number((total / events).toFixed(2)));
        } else {
            console.log("Kein Dokument gefunden!");
        }
    };

    useEffect(() => {
        if (selectedResultId) {
            fetchData(selectedResultId, setTotalCosts, setEventsTotal, setCostsPerEvent, setObjectives);
        }
    }, [selectedResultId]);

    useEffect(() => {
        if (selectedResultId2) {
            fetchData(selectedResultId2, setTotalCosts2, setEventsTotal2, setCostsPerEvent2, setObjectives2);
        }
    }, [selectedResultId2]);

    const combineObjectives = () => {
        const map = new Map();

        // Füge alle Ziele aus der ersten Liste hinzu
        objectives.forEach(obj => {
            map.set(obj.id, {...obj, totalCostsPerConstraint2: 0}); // initialisiere die zweiten Kosten als 0
        });

        // Aktualisiere oder füge Ziele aus der zweiten Liste hinzu
        objectives2.forEach(obj => {
            if (map.has(obj.id)) {
                const existingObj = map.get(obj.id);
                map.set(obj.id, {...existingObj, totalCostsPerConstraint2: obj.totalCostsPerConstraint});
            } else {
                map.set(obj.id, {...obj, totalCostsPerConstraint: 0, totalCostsPerConstraint2: obj.totalCostsPerConstraint}); // initialisiere die ersten Kosten als 0, wenn nicht vorhanden
            }
        });

        return Array.from(map.values());
    };

    const combinedObjectives = combineObjectives();

    return (
        <div>
            <h2>Stundenplanlösungen vergleichen</h2>
            <table className="siman-data">
                <thead>
                <tr>
                    <th>&nbsp;</th>
                    <th className="selector">
                        <SolutionSelector onSelect={handleSelectResultId}/>
                    </th>
                    <th className="selector">
                        <SolutionSelector onSelect={handleSelectResultId2}/>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Gesamtkosten</td>
                    <td className="data"><strong>{totalCosts.toFixed(2)}</strong></td>
                    <td className="data"><strong>{totalCosts2.toFixed(2)}</strong></td>
                </tr>
                <tr>
                    <td>Kosten pro Termin</td>
                    <td className="data">{costsPerEvent.toFixed(2)}</td>
                    <td className="data">{costsPerEvent2.toFixed(2)}</td>
                </tr>
                {combinedObjectives.map(({ id, name, description, weighting, constraints, totalCostsPerConstraint, totalCostsPerConstraint2 }) => (
                    <tr key={id}>
                        <td>
                            {name}
                            <p className="hint">{description}</p>
                        </td>
                        <td className={`data ${!totalCostsPerConstraint ? 'zero' : ''}`}>
                            {typeof totalCostsPerConstraint === 'number' && !isNaN(totalCostsPerConstraint) ? totalCostsPerConstraint.toFixed(2) : '0.00'}
                        </td>
                        <td className={`data ${!totalCostsPerConstraint2 ? 'zero' : ''}`}>
                            {typeof totalCostsPerConstraint2 === 'number' && !isNaN(totalCostsPerConstraint2) ? totalCostsPerConstraint2.toFixed(2) : '0.00'}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default SolutionAnalyzer;
