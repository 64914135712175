import {groups} from "../groups";

export default function noParticipantOverlap(event, allEvents) {

    if (!event || !event.extendedProps) {
        console.error("Fehler: event oder event.extendedProps fehlen.");
        return false;
    }

    // Extrahiere die notwendigen Informationen aus dem aktuellen Terminobjekt
    const {
        start,
        end,
        id = event.extendedProps.id,
        semester = event.extendedProps.semester,
        group = event.extendedProps.group,
        studentIntersectionGroups = event.extendedProps.studentIntersectionGroups
    } = event;

    // Durchlaufe alle Kurse im gleichen Fachsemester
    for (const otherEvent of allEvents) {

        if (!otherEvent || !otherEvent.extendedProps) {
            console.error("Fehler: otherEvent oder otherEvent.extendedProps fehlen.");
            continue;
        }

        // Überspringe die Prüfung, wenn es derselbe Kurs ist
        if (otherEvent.extendedProps.id === id) continue;

        let hasGroupOverlap = false;

        // Überprüfe Gruppenüberschneidungen für beide Richtungen
        const checkGroupOverlap = (group1, group2, intersectionGroups1, intersectionGroups2) => {
            if (group1.startsWith('1G')) {
                const studentID = group1.split('-')[1];
                const otherGroupMembers = groups.find(g => g.id === group2)?.members || [];
                return otherGroupMembers.includes(`studentID-${studentID}`);
            }
            return intersectionGroups1.includes(group2) || intersectionGroups2.includes(group1) || group1 === group2;
        };

        hasGroupOverlap = checkGroupOverlap(group, otherEvent.extendedProps.group, studentIntersectionGroups, otherEvent.extendedProps.studentIntersectionGroups) ||
                           checkGroupOverlap(otherEvent.extendedProps.group, group, otherEvent.extendedProps.studentIntersectionGroups, studentIntersectionGroups);

        // Wenn es Überschneidungen im Semester und in den Gruppen gibt, prüfe die Zeitüberschneidung
        if ((semester === otherEvent.extendedProps.semester) && hasGroupOverlap) {
            // Konvertiere die Start- und Endzeiten in Date-Objekte
            const otherStartTime = new Date(otherEvent.start);
            const otherEndTime = new Date(otherEvent.end);
            const courseStartTime = new Date(start);
            const courseEndTime = new Date(end);

            // Addiere 15 Minuten zur Endzeit und subtrahiere 15 Minuten von der Startzeit für die Pause
            const bufferEndTime = new Date(courseEndTime.getTime() + 15 * 59000); // 1 Sekunde Abzug
            const bufferStartTime = new Date(courseStartTime.getTime() - 15 * 59000); // 1 Sekunde Abzug

            // Überlappung tritt auf, wenn ein Kurs beginnt, bevor ein anderer endet und endet, nachdem ein anderer begonnen hat
            if (bufferStartTime <= otherEndTime && bufferEndTime >= otherStartTime) {
                return false; // Es gibt eine Überschneidung
            }
        }
    }

    // Keine Überschneidungen gefunden, Constraint ist erfüllt
    return true;
}