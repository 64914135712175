import React, { useEffect, useState } from 'react';
import { db } from '../../../firebase';
import { collection, getDocs } from 'firebase/firestore';

function SolutionSelector({ solutionId, onSelect }) {
    const [solutionList, setSolutionList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let isMounted = true;

        async function fetchSolutionsList() {
            setIsLoading(true);
            try {
                const snapshot = await getDocs(collection(db, 'solutions'));
                const solutions = snapshot.docs.map(doc => {
                    const docData = doc.data();
                    let details = [doc.id];

                    if (docData.createdAt) {
                        const date = new Date(docData.createdAt.seconds * 1000);
                        const dateString = date.toLocaleDateString("de-DE", {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric'
                        });
                        details.push(`vom ${dateString}`);
                    }

                    const iterationText = docData.iterationCount && docData.iterationCount > 0 ?
                        `(${docData.iterationCount}. Iteration)` : "(Anfangslösung)";
                    details.push(iterationText);

                    return {
                        id: doc.id,
                        name: details.join(' '),
                        timestamp: docData.createdAt ? new Date(docData.createdAt.seconds * 1000) : undefined
                    };
                });

                solutions.sort((a, b) => b.timestamp - a.timestamp);
                if (isMounted) {
                    setSolutionList(solutions);
                }
            } catch (error) {
                if (isMounted) {
                    console.error("Fehler beim Laden der Stundenplanliste: ", error);
                }
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        }

        fetchSolutionsList();

        return () => {
            isMounted = false;
        };
    }, [onSelect]);

    return (
        <div>
            <label htmlFor="solutionSelect">Stundenplanlösung</label>
            <select id="solutionSelect" value={solutionId} onChange={e => onSelect(e.target.value)} disabled={isLoading}>
                <option value="">Bitte wählen ...</option>
                {solutionList.map(solution => (
                    <option key={solution.id} value={solution.id}>{solution.name}</option>
                ))}
            </select>
        </div>
    );
}

export default SolutionSelector;
