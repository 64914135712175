import { collection, doc, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import moment from 'moment';
import { CheckboxCircleFill, ErrorWarningFill } from '../../../components/assets/Icons'
import {areAllEventsBefore, fetchAllEvents} from "../../utilityFunctions";

export const validate_recAHN2Fs2Qyfh1ol = async (solutionId) => {

    try {
        let isValid = true;  // Annahme, dass alle Events gültig sind, bis das Gegenteil bewiesen wird

        const criterion1 = { type: 'module', value: '5-I-MEDEINF' };
        const criterion2 = { type: 'module', value: '5-I-MED1' };

        const allEvents = await fetchAllEvents(solutionId);
        const checkEvents = areAllEventsBefore(allEvents, criterion1, criterion2);
        if (!checkEvents) {
            isValid = false;
        }

        // Das Gesamticon wird basierend auf der Gültigkeit des Ergebnisses bestimmt
        const overallIcon = isValid
            ? <CheckboxCircleFill color='rgba(26, 198, 179, 1)' size='18px' />
            : <ErrorWarningFill color='rgba(233, 85, 51, 1)' size='18px' />;

        return {
            type: 'text',
            data: `Validierung ${isValid ? '' : 'nicht ' }erfolgreich`,
            isValid: isValid,  // Rückgabe des Gesamtergebnisses
            overallIcon: overallIcon  // Rückgabe des Icons, das den Gesamtzustand anzeigt
        };
    } catch (error) {
        console.error('Fehler beim Abrufen der Events:', error);
        return {
            type: 'error',
            message: 'Es ist ein Fehler aufgetreten.',
            isValid: false,  // Rückgabe, dass bei einem Fehler die Validierung als ungültig betrachtet wird
            overallIcon: <ErrorWarningFill color='rgba(233, 85, 51, 1)' size='18px' />
        };
    }
}