/*
// Funktion zum Generieren des Gruppenobjekts

export default function generateGroupsJSON() {
    const baseId = "G";
    const hierarchy = [
        { level: 336, count: 1, subLevels: [
                { level: 120, count: 3, subLevels: [
                        { level: 60, count: 2, subLevels: [
                                { level: 20, count: 3, subLevels: [
                                        { level: 10, count: 2, subLevels: [] }
                                    ]},
                                { level: 12, count: 5, subLevels: [
                                        { level: 6, count: 2, subLevels: [
                                                { level: 3, count: 2, subLevels: [] }
                                            ]}
                                    ]}
                            ]}
                    ]}
            ]}
    ];

    // Hilfsfunktion zum Hinzufügen von Gruppen
    function addGroups(groups, levelInfo, prefix, currentIndex = 1, parentIndex = '', parentLevel = null, isLastInParent = false) {
        let tempSubLevels = JSON.parse(JSON.stringify(levelInfo.subLevels));
        for (let i = 1; i <= levelInfo.count; i++) {
            let isLast120 = levelInfo.level === 120 && i === levelInfo.count;
            let isLast60 = levelInfo.level === 60 && i === levelInfo.count && isLastInParent;

            if (isLast60) {
                tempSubLevels.forEach(subLevel => {
                    if (subLevel.level === 20) {
                        subLevel.count = 2; // Nur 2 Gruppen für die letzte 60er Gruppe auf Ebene 20
                    }
                    if (subLevel.level === 12) {
                        subLevel.count = 3; // Nur 3 Gruppen für die letzte 60er Gruppe auf Ebene 12
                    }
                });
            }

            const currentId = `${levelInfo.level}${baseId}-${prefix}${i}`;
            const groupId = `${prefix}${i}`;
            const group = {
                id: currentId,
                level: levelInfo.level,
                parentId: parentIndex && parentLevel ? `${parentLevel}${baseId}-${parentIndex}` : null,
                members: Array.from({ length: levelInfo.level }, (_, k) => `studentID-${currentIndex + k}`).slice(0, levelInfo.level)
            };
            groups.push(group);
            tempSubLevels.forEach(subLevel => {
                addGroups(groups, subLevel, `${prefix}${i}-`, currentIndex, groupId, levelInfo.level, isLast120 && i === levelInfo.count);
            });
            currentIndex += levelInfo.level;
        }
    }

    const groups = [];
    hierarchy.forEach(level => {
        addGroups(groups, level, "", 1, '', null, true);
    });

    return groups;
}

*/

export const groups = [
    {
        "id": "336G-1",
        "level": 336,
        "parentId": null,
        "members": [
            "studentID-1",
            "studentID-2",
            "studentID-3",
            "studentID-4",
            "studentID-5",
            "studentID-6",
            "studentID-7",
            "studentID-8",
            "studentID-9",
            "studentID-10",
            "studentID-11",
            "studentID-12",
            "studentID-13",
            "studentID-14",
            "studentID-15",
            "studentID-16",
            "studentID-17",
            "studentID-18",
            "studentID-19",
            "studentID-20",
            "studentID-21",
            "studentID-22",
            "studentID-23",
            "studentID-24",
            "studentID-25",
            "studentID-26",
            "studentID-27",
            "studentID-28",
            "studentID-29",
            "studentID-30",
            "studentID-31",
            "studentID-32",
            "studentID-33",
            "studentID-34",
            "studentID-35",
            "studentID-36",
            "studentID-37",
            "studentID-38",
            "studentID-39",
            "studentID-40",
            "studentID-41",
            "studentID-42",
            "studentID-43",
            "studentID-44",
            "studentID-45",
            "studentID-46",
            "studentID-47",
            "studentID-48",
            "studentID-49",
            "studentID-50",
            "studentID-51",
            "studentID-52",
            "studentID-53",
            "studentID-54",
            "studentID-55",
            "studentID-56",
            "studentID-57",
            "studentID-58",
            "studentID-59",
            "studentID-60",
            "studentID-61",
            "studentID-62",
            "studentID-63",
            "studentID-64",
            "studentID-65",
            "studentID-66",
            "studentID-67",
            "studentID-68",
            "studentID-69",
            "studentID-70",
            "studentID-71",
            "studentID-72",
            "studentID-73",
            "studentID-74",
            "studentID-75",
            "studentID-76",
            "studentID-77",
            "studentID-78",
            "studentID-79",
            "studentID-80",
            "studentID-81",
            "studentID-82",
            "studentID-83",
            "studentID-84",
            "studentID-85",
            "studentID-86",
            "studentID-87",
            "studentID-88",
            "studentID-89",
            "studentID-90",
            "studentID-91",
            "studentID-92",
            "studentID-93",
            "studentID-94",
            "studentID-95",
            "studentID-96",
            "studentID-97",
            "studentID-98",
            "studentID-99",
            "studentID-100",
            "studentID-101",
            "studentID-102",
            "studentID-103",
            "studentID-104",
            "studentID-105",
            "studentID-106",
            "studentID-107",
            "studentID-108",
            "studentID-109",
            "studentID-110",
            "studentID-111",
            "studentID-112",
            "studentID-113",
            "studentID-114",
            "studentID-115",
            "studentID-116",
            "studentID-117",
            "studentID-118",
            "studentID-119",
            "studentID-120",
            "studentID-121",
            "studentID-122",
            "studentID-123",
            "studentID-124",
            "studentID-125",
            "studentID-126",
            "studentID-127",
            "studentID-128",
            "studentID-129",
            "studentID-130",
            "studentID-131",
            "studentID-132",
            "studentID-133",
            "studentID-134",
            "studentID-135",
            "studentID-136",
            "studentID-137",
            "studentID-138",
            "studentID-139",
            "studentID-140",
            "studentID-141",
            "studentID-142",
            "studentID-143",
            "studentID-144",
            "studentID-145",
            "studentID-146",
            "studentID-147",
            "studentID-148",
            "studentID-149",
            "studentID-150",
            "studentID-151",
            "studentID-152",
            "studentID-153",
            "studentID-154",
            "studentID-155",
            "studentID-156",
            "studentID-157",
            "studentID-158",
            "studentID-159",
            "studentID-160",
            "studentID-161",
            "studentID-162",
            "studentID-163",
            "studentID-164",
            "studentID-165",
            "studentID-166",
            "studentID-167",
            "studentID-168",
            "studentID-169",
            "studentID-170",
            "studentID-171",
            "studentID-172",
            "studentID-173",
            "studentID-174",
            "studentID-175",
            "studentID-176",
            "studentID-177",
            "studentID-178",
            "studentID-179",
            "studentID-180",
            "studentID-181",
            "studentID-182",
            "studentID-183",
            "studentID-184",
            "studentID-185",
            "studentID-186",
            "studentID-187",
            "studentID-188",
            "studentID-189",
            "studentID-190",
            "studentID-191",
            "studentID-192",
            "studentID-193",
            "studentID-194",
            "studentID-195",
            "studentID-196",
            "studentID-197",
            "studentID-198",
            "studentID-199",
            "studentID-200",
            "studentID-201",
            "studentID-202",
            "studentID-203",
            "studentID-204",
            "studentID-205",
            "studentID-206",
            "studentID-207",
            "studentID-208",
            "studentID-209",
            "studentID-210",
            "studentID-211",
            "studentID-212",
            "studentID-213",
            "studentID-214",
            "studentID-215",
            "studentID-216",
            "studentID-217",
            "studentID-218",
            "studentID-219",
            "studentID-220",
            "studentID-221",
            "studentID-222",
            "studentID-223",
            "studentID-224",
            "studentID-225",
            "studentID-226",
            "studentID-227",
            "studentID-228",
            "studentID-229",
            "studentID-230",
            "studentID-231",
            "studentID-232",
            "studentID-233",
            "studentID-234",
            "studentID-235",
            "studentID-236",
            "studentID-237",
            "studentID-238",
            "studentID-239",
            "studentID-240",
            "studentID-241",
            "studentID-242",
            "studentID-243",
            "studentID-244",
            "studentID-245",
            "studentID-246",
            "studentID-247",
            "studentID-248",
            "studentID-249",
            "studentID-250",
            "studentID-251",
            "studentID-252",
            "studentID-253",
            "studentID-254",
            "studentID-255",
            "studentID-256",
            "studentID-257",
            "studentID-258",
            "studentID-259",
            "studentID-260",
            "studentID-261",
            "studentID-262",
            "studentID-263",
            "studentID-264",
            "studentID-265",
            "studentID-266",
            "studentID-267",
            "studentID-268",
            "studentID-269",
            "studentID-270",
            "studentID-271",
            "studentID-272",
            "studentID-273",
            "studentID-274",
            "studentID-275",
            "studentID-276",
            "studentID-277",
            "studentID-278",
            "studentID-279",
            "studentID-280",
            "studentID-281",
            "studentID-282",
            "studentID-283",
            "studentID-284",
            "studentID-285",
            "studentID-286",
            "studentID-287",
            "studentID-288",
            "studentID-289",
            "studentID-290",
            "studentID-291",
            "studentID-292",
            "studentID-293",
            "studentID-294",
            "studentID-295",
            "studentID-296",
            "studentID-297",
            "studentID-298",
            "studentID-299",
            "studentID-300",
            "studentID-301",
            "studentID-302",
            "studentID-303",
            "studentID-304",
            "studentID-305",
            "studentID-306",
            "studentID-307",
            "studentID-308",
            "studentID-309",
            "studentID-310",
            "studentID-311",
            "studentID-312",
            "studentID-313",
            "studentID-314",
            "studentID-315",
            "studentID-316",
            "studentID-317",
            "studentID-318",
            "studentID-319",
            "studentID-320",
            "studentID-321",
            "studentID-322",
            "studentID-323",
            "studentID-324",
            "studentID-325",
            "studentID-326",
            "studentID-327",
            "studentID-328",
            "studentID-329",
            "studentID-330",
            "studentID-331",
            "studentID-332",
            "studentID-333",
            "studentID-334",
            "studentID-335",
            "studentID-336"
        ]
    },
    {
        "id": "120G-1-1",
        "level": 120,
        "parentId": "336G-1",
        "members": [
            "studentID-1",
            "studentID-2",
            "studentID-3",
            "studentID-4",
            "studentID-5",
            "studentID-6",
            "studentID-7",
            "studentID-8",
            "studentID-9",
            "studentID-10",
            "studentID-11",
            "studentID-12",
            "studentID-13",
            "studentID-14",
            "studentID-15",
            "studentID-16",
            "studentID-17",
            "studentID-18",
            "studentID-19",
            "studentID-20",
            "studentID-21",
            "studentID-22",
            "studentID-23",
            "studentID-24",
            "studentID-25",
            "studentID-26",
            "studentID-27",
            "studentID-28",
            "studentID-29",
            "studentID-30",
            "studentID-31",
            "studentID-32",
            "studentID-33",
            "studentID-34",
            "studentID-35",
            "studentID-36",
            "studentID-37",
            "studentID-38",
            "studentID-39",
            "studentID-40",
            "studentID-41",
            "studentID-42",
            "studentID-43",
            "studentID-44",
            "studentID-45",
            "studentID-46",
            "studentID-47",
            "studentID-48",
            "studentID-49",
            "studentID-50",
            "studentID-51",
            "studentID-52",
            "studentID-53",
            "studentID-54",
            "studentID-55",
            "studentID-56",
            "studentID-57",
            "studentID-58",
            "studentID-59",
            "studentID-60",
            "studentID-61",
            "studentID-62",
            "studentID-63",
            "studentID-64",
            "studentID-65",
            "studentID-66",
            "studentID-67",
            "studentID-68",
            "studentID-69",
            "studentID-70",
            "studentID-71",
            "studentID-72",
            "studentID-73",
            "studentID-74",
            "studentID-75",
            "studentID-76",
            "studentID-77",
            "studentID-78",
            "studentID-79",
            "studentID-80",
            "studentID-81",
            "studentID-82",
            "studentID-83",
            "studentID-84",
            "studentID-85",
            "studentID-86",
            "studentID-87",
            "studentID-88",
            "studentID-89",
            "studentID-90",
            "studentID-91",
            "studentID-92",
            "studentID-93",
            "studentID-94",
            "studentID-95",
            "studentID-96",
            "studentID-97",
            "studentID-98",
            "studentID-99",
            "studentID-100",
            "studentID-101",
            "studentID-102",
            "studentID-103",
            "studentID-104",
            "studentID-105",
            "studentID-106",
            "studentID-107",
            "studentID-108",
            "studentID-109",
            "studentID-110",
            "studentID-111",
            "studentID-112",
            "studentID-113",
            "studentID-114",
            "studentID-115",
            "studentID-116",
            "studentID-117",
            "studentID-118",
            "studentID-119",
            "studentID-120"
        ]
    },
    {
        "id": "60G-1-1-1",
        "level": 60,
        "parentId": "120G-1-1",
        "members": [
            "studentID-1",
            "studentID-2",
            "studentID-3",
            "studentID-4",
            "studentID-5",
            "studentID-6",
            "studentID-7",
            "studentID-8",
            "studentID-9",
            "studentID-10",
            "studentID-11",
            "studentID-12",
            "studentID-13",
            "studentID-14",
            "studentID-15",
            "studentID-16",
            "studentID-17",
            "studentID-18",
            "studentID-19",
            "studentID-20",
            "studentID-21",
            "studentID-22",
            "studentID-23",
            "studentID-24",
            "studentID-25",
            "studentID-26",
            "studentID-27",
            "studentID-28",
            "studentID-29",
            "studentID-30",
            "studentID-31",
            "studentID-32",
            "studentID-33",
            "studentID-34",
            "studentID-35",
            "studentID-36",
            "studentID-37",
            "studentID-38",
            "studentID-39",
            "studentID-40",
            "studentID-41",
            "studentID-42",
            "studentID-43",
            "studentID-44",
            "studentID-45",
            "studentID-46",
            "studentID-47",
            "studentID-48",
            "studentID-49",
            "studentID-50",
            "studentID-51",
            "studentID-52",
            "studentID-53",
            "studentID-54",
            "studentID-55",
            "studentID-56",
            "studentID-57",
            "studentID-58",
            "studentID-59",
            "studentID-60"
        ]
    },
    {
        "id": "20G-1-1-1-1",
        "level": 20,
        "parentId": "60G-1-1-1",
        "members": [
            "studentID-1",
            "studentID-2",
            "studentID-3",
            "studentID-4",
            "studentID-5",
            "studentID-6",
            "studentID-7",
            "studentID-8",
            "studentID-9",
            "studentID-10",
            "studentID-11",
            "studentID-12",
            "studentID-13",
            "studentID-14",
            "studentID-15",
            "studentID-16",
            "studentID-17",
            "studentID-18",
            "studentID-19",
            "studentID-20"
        ]
    },
    {
        "id": "10G-1-1-1-1-1",
        "level": 10,
        "parentId": "20G-1-1-1-1",
        "members": [
            "studentID-1",
            "studentID-2",
            "studentID-3",
            "studentID-4",
            "studentID-5",
            "studentID-6",
            "studentID-7",
            "studentID-8",
            "studentID-9",
            "studentID-10"
        ]
    },
    {
        "id": "10G-1-1-1-1-2",
        "level": 10,
        "parentId": "20G-1-1-1-1",
        "members": [
            "studentID-11",
            "studentID-12",
            "studentID-13",
            "studentID-14",
            "studentID-15",
            "studentID-16",
            "studentID-17",
            "studentID-18",
            "studentID-19",
            "studentID-20"
        ]
    },
    {
        "id": "20G-1-1-1-2",
        "level": 20,
        "parentId": "60G-1-1-1",
        "members": [
            "studentID-21",
            "studentID-22",
            "studentID-23",
            "studentID-24",
            "studentID-25",
            "studentID-26",
            "studentID-27",
            "studentID-28",
            "studentID-29",
            "studentID-30",
            "studentID-31",
            "studentID-32",
            "studentID-33",
            "studentID-34",
            "studentID-35",
            "studentID-36",
            "studentID-37",
            "studentID-38",
            "studentID-39",
            "studentID-40"
        ]
    },
    {
        "id": "10G-1-1-1-2-1",
        "level": 10,
        "parentId": "20G-1-1-1-2",
        "members": [
            "studentID-21",
            "studentID-22",
            "studentID-23",
            "studentID-24",
            "studentID-25",
            "studentID-26",
            "studentID-27",
            "studentID-28",
            "studentID-29",
            "studentID-30"
        ]
    },
    {
        "id": "10G-1-1-1-2-2",
        "level": 10,
        "parentId": "20G-1-1-1-2",
        "members": [
            "studentID-31",
            "studentID-32",
            "studentID-33",
            "studentID-34",
            "studentID-35",
            "studentID-36",
            "studentID-37",
            "studentID-38",
            "studentID-39",
            "studentID-40"
        ]
    },
    {
        "id": "20G-1-1-1-3",
        "level": 20,
        "parentId": "60G-1-1-1",
        "members": [
            "studentID-41",
            "studentID-42",
            "studentID-43",
            "studentID-44",
            "studentID-45",
            "studentID-46",
            "studentID-47",
            "studentID-48",
            "studentID-49",
            "studentID-50",
            "studentID-51",
            "studentID-52",
            "studentID-53",
            "studentID-54",
            "studentID-55",
            "studentID-56",
            "studentID-57",
            "studentID-58",
            "studentID-59",
            "studentID-60"
        ]
    },
    {
        "id": "10G-1-1-1-3-1",
        "level": 10,
        "parentId": "20G-1-1-1-3",
        "members": [
            "studentID-41",
            "studentID-42",
            "studentID-43",
            "studentID-44",
            "studentID-45",
            "studentID-46",
            "studentID-47",
            "studentID-48",
            "studentID-49",
            "studentID-50"
        ]
    },
    {
        "id": "10G-1-1-1-3-2",
        "level": 10,
        "parentId": "20G-1-1-1-3",
        "members": [
            "studentID-51",
            "studentID-52",
            "studentID-53",
            "studentID-54",
            "studentID-55",
            "studentID-56",
            "studentID-57",
            "studentID-58",
            "studentID-59",
            "studentID-60"
        ]
    },
    {
        "id": "12G-1-1-1-1",
        "level": 12,
        "parentId": "60G-1-1-1",
        "members": [
            "studentID-1",
            "studentID-2",
            "studentID-3",
            "studentID-4",
            "studentID-5",
            "studentID-6",
            "studentID-7",
            "studentID-8",
            "studentID-9",
            "studentID-10",
            "studentID-11",
            "studentID-12"
        ]
    },
    {
        "id": "6G-1-1-1-1-1",
        "level": 6,
        "parentId": "12G-1-1-1-1",
        "members": [
            "studentID-1",
            "studentID-2",
            "studentID-3",
            "studentID-4",
            "studentID-5",
            "studentID-6"
        ]
    },
    {
        "id": "3G-1-1-1-1-1-1",
        "level": 3,
        "parentId": "6G-1-1-1-1-1",
        "members": [
            "studentID-1",
            "studentID-2",
            "studentID-3"
        ]
    },
    {
        "id": "3G-1-1-1-1-1-2",
        "level": 3,
        "parentId": "6G-1-1-1-1-1",
        "members": [
            "studentID-4",
            "studentID-5",
            "studentID-6"
        ]
    },
    {
        "id": "6G-1-1-1-1-2",
        "level": 6,
        "parentId": "12G-1-1-1-1",
        "members": [
            "studentID-7",
            "studentID-8",
            "studentID-9",
            "studentID-10",
            "studentID-11",
            "studentID-12"
        ]
    },
    {
        "id": "3G-1-1-1-1-2-1",
        "level": 3,
        "parentId": "6G-1-1-1-1-2",
        "members": [
            "studentID-7",
            "studentID-8",
            "studentID-9"
        ]
    },
    {
        "id": "3G-1-1-1-1-2-2",
        "level": 3,
        "parentId": "6G-1-1-1-1-2",
        "members": [
            "studentID-10",
            "studentID-11",
            "studentID-12"
        ]
    },
    {
        "id": "12G-1-1-1-2",
        "level": 12,
        "parentId": "60G-1-1-1",
        "members": [
            "studentID-13",
            "studentID-14",
            "studentID-15",
            "studentID-16",
            "studentID-17",
            "studentID-18",
            "studentID-19",
            "studentID-20",
            "studentID-21",
            "studentID-22",
            "studentID-23",
            "studentID-24"
        ]
    },
    {
        "id": "6G-1-1-1-2-1",
        "level": 6,
        "parentId": "12G-1-1-1-2",
        "members": [
            "studentID-13",
            "studentID-14",
            "studentID-15",
            "studentID-16",
            "studentID-17",
            "studentID-18"
        ]
    },
    {
        "id": "3G-1-1-1-2-1-1",
        "level": 3,
        "parentId": "6G-1-1-1-2-1",
        "members": [
            "studentID-13",
            "studentID-14",
            "studentID-15"
        ]
    },
    {
        "id": "3G-1-1-1-2-1-2",
        "level": 3,
        "parentId": "6G-1-1-1-2-1",
        "members": [
            "studentID-16",
            "studentID-17",
            "studentID-18"
        ]
    },
    {
        "id": "6G-1-1-1-2-2",
        "level": 6,
        "parentId": "12G-1-1-1-2",
        "members": [
            "studentID-19",
            "studentID-20",
            "studentID-21",
            "studentID-22",
            "studentID-23",
            "studentID-24"
        ]
    },
    {
        "id": "3G-1-1-1-2-2-1",
        "level": 3,
        "parentId": "6G-1-1-1-2-2",
        "members": [
            "studentID-19",
            "studentID-20",
            "studentID-21"
        ]
    },
    {
        "id": "3G-1-1-1-2-2-2",
        "level": 3,
        "parentId": "6G-1-1-1-2-2",
        "members": [
            "studentID-22",
            "studentID-23",
            "studentID-24"
        ]
    },
    {
        "id": "12G-1-1-1-3",
        "level": 12,
        "parentId": "60G-1-1-1",
        "members": [
            "studentID-25",
            "studentID-26",
            "studentID-27",
            "studentID-28",
            "studentID-29",
            "studentID-30",
            "studentID-31",
            "studentID-32",
            "studentID-33",
            "studentID-34",
            "studentID-35",
            "studentID-36"
        ]
    },
    {
        "id": "6G-1-1-1-3-1",
        "level": 6,
        "parentId": "12G-1-1-1-3",
        "members": [
            "studentID-25",
            "studentID-26",
            "studentID-27",
            "studentID-28",
            "studentID-29",
            "studentID-30"
        ]
    },
    {
        "id": "3G-1-1-1-3-1-1",
        "level": 3,
        "parentId": "6G-1-1-1-3-1",
        "members": [
            "studentID-25",
            "studentID-26",
            "studentID-27"
        ]
    },
    {
        "id": "3G-1-1-1-3-1-2",
        "level": 3,
        "parentId": "6G-1-1-1-3-1",
        "members": [
            "studentID-28",
            "studentID-29",
            "studentID-30"
        ]
    },
    {
        "id": "6G-1-1-1-3-2",
        "level": 6,
        "parentId": "12G-1-1-1-3",
        "members": [
            "studentID-31",
            "studentID-32",
            "studentID-33",
            "studentID-34",
            "studentID-35",
            "studentID-36"
        ]
    },
    {
        "id": "3G-1-1-1-3-2-1",
        "level": 3,
        "parentId": "6G-1-1-1-3-2",
        "members": [
            "studentID-31",
            "studentID-32",
            "studentID-33"
        ]
    },
    {
        "id": "3G-1-1-1-3-2-2",
        "level": 3,
        "parentId": "6G-1-1-1-3-2",
        "members": [
            "studentID-34",
            "studentID-35",
            "studentID-36"
        ]
    },
    {
        "id": "12G-1-1-1-4",
        "level": 12,
        "parentId": "60G-1-1-1",
        "members": [
            "studentID-37",
            "studentID-38",
            "studentID-39",
            "studentID-40",
            "studentID-41",
            "studentID-42",
            "studentID-43",
            "studentID-44",
            "studentID-45",
            "studentID-46",
            "studentID-47",
            "studentID-48"
        ]
    },
    {
        "id": "6G-1-1-1-4-1",
        "level": 6,
        "parentId": "12G-1-1-1-4",
        "members": [
            "studentID-37",
            "studentID-38",
            "studentID-39",
            "studentID-40",
            "studentID-41",
            "studentID-42"
        ]
    },
    {
        "id": "3G-1-1-1-4-1-1",
        "level": 3,
        "parentId": "6G-1-1-1-4-1",
        "members": [
            "studentID-37",
            "studentID-38",
            "studentID-39"
        ]
    },
    {
        "id": "3G-1-1-1-4-1-2",
        "level": 3,
        "parentId": "6G-1-1-1-4-1",
        "members": [
            "studentID-40",
            "studentID-41",
            "studentID-42"
        ]
    },
    {
        "id": "6G-1-1-1-4-2",
        "level": 6,
        "parentId": "12G-1-1-1-4",
        "members": [
            "studentID-43",
            "studentID-44",
            "studentID-45",
            "studentID-46",
            "studentID-47",
            "studentID-48"
        ]
    },
    {
        "id": "3G-1-1-1-4-2-1",
        "level": 3,
        "parentId": "6G-1-1-1-4-2",
        "members": [
            "studentID-43",
            "studentID-44",
            "studentID-45"
        ]
    },
    {
        "id": "3G-1-1-1-4-2-2",
        "level": 3,
        "parentId": "6G-1-1-1-4-2",
        "members": [
            "studentID-46",
            "studentID-47",
            "studentID-48"
        ]
    },
    {
        "id": "12G-1-1-1-5",
        "level": 12,
        "parentId": "60G-1-1-1",
        "members": [
            "studentID-49",
            "studentID-50",
            "studentID-51",
            "studentID-52",
            "studentID-53",
            "studentID-54",
            "studentID-55",
            "studentID-56",
            "studentID-57",
            "studentID-58",
            "studentID-59",
            "studentID-60"
        ]
    },
    {
        "id": "6G-1-1-1-5-1",
        "level": 6,
        "parentId": "12G-1-1-1-5",
        "members": [
            "studentID-49",
            "studentID-50",
            "studentID-51",
            "studentID-52",
            "studentID-53",
            "studentID-54"
        ]
    },
    {
        "id": "3G-1-1-1-5-1-1",
        "level": 3,
        "parentId": "6G-1-1-1-5-1",
        "members": [
            "studentID-49",
            "studentID-50",
            "studentID-51"
        ]
    },
    {
        "id": "3G-1-1-1-5-1-2",
        "level": 3,
        "parentId": "6G-1-1-1-5-1",
        "members": [
            "studentID-52",
            "studentID-53",
            "studentID-54"
        ]
    },
    {
        "id": "6G-1-1-1-5-2",
        "level": 6,
        "parentId": "12G-1-1-1-5",
        "members": [
            "studentID-55",
            "studentID-56",
            "studentID-57",
            "studentID-58",
            "studentID-59",
            "studentID-60"
        ]
    },
    {
        "id": "3G-1-1-1-5-2-1",
        "level": 3,
        "parentId": "6G-1-1-1-5-2",
        "members": [
            "studentID-55",
            "studentID-56",
            "studentID-57"
        ]
    },
    {
        "id": "3G-1-1-1-5-2-2",
        "level": 3,
        "parentId": "6G-1-1-1-5-2",
        "members": [
            "studentID-58",
            "studentID-59",
            "studentID-60"
        ]
    },
    {
        "id": "60G-1-1-2",
        "level": 60,
        "parentId": "120G-1-1",
        "members": [
            "studentID-61",
            "studentID-62",
            "studentID-63",
            "studentID-64",
            "studentID-65",
            "studentID-66",
            "studentID-67",
            "studentID-68",
            "studentID-69",
            "studentID-70",
            "studentID-71",
            "studentID-72",
            "studentID-73",
            "studentID-74",
            "studentID-75",
            "studentID-76",
            "studentID-77",
            "studentID-78",
            "studentID-79",
            "studentID-80",
            "studentID-81",
            "studentID-82",
            "studentID-83",
            "studentID-84",
            "studentID-85",
            "studentID-86",
            "studentID-87",
            "studentID-88",
            "studentID-89",
            "studentID-90",
            "studentID-91",
            "studentID-92",
            "studentID-93",
            "studentID-94",
            "studentID-95",
            "studentID-96",
            "studentID-97",
            "studentID-98",
            "studentID-99",
            "studentID-100",
            "studentID-101",
            "studentID-102",
            "studentID-103",
            "studentID-104",
            "studentID-105",
            "studentID-106",
            "studentID-107",
            "studentID-108",
            "studentID-109",
            "studentID-110",
            "studentID-111",
            "studentID-112",
            "studentID-113",
            "studentID-114",
            "studentID-115",
            "studentID-116",
            "studentID-117",
            "studentID-118",
            "studentID-119",
            "studentID-120"
        ]
    },
    {
        "id": "20G-1-1-2-1",
        "level": 20,
        "parentId": "60G-1-1-2",
        "members": [
            "studentID-61",
            "studentID-62",
            "studentID-63",
            "studentID-64",
            "studentID-65",
            "studentID-66",
            "studentID-67",
            "studentID-68",
            "studentID-69",
            "studentID-70",
            "studentID-71",
            "studentID-72",
            "studentID-73",
            "studentID-74",
            "studentID-75",
            "studentID-76",
            "studentID-77",
            "studentID-78",
            "studentID-79",
            "studentID-80"
        ]
    },
    {
        "id": "10G-1-1-2-1-1",
        "level": 10,
        "parentId": "20G-1-1-2-1",
        "members": [
            "studentID-61",
            "studentID-62",
            "studentID-63",
            "studentID-64",
            "studentID-65",
            "studentID-66",
            "studentID-67",
            "studentID-68",
            "studentID-69",
            "studentID-70"
        ]
    },
    {
        "id": "10G-1-1-2-1-2",
        "level": 10,
        "parentId": "20G-1-1-2-1",
        "members": [
            "studentID-71",
            "studentID-72",
            "studentID-73",
            "studentID-74",
            "studentID-75",
            "studentID-76",
            "studentID-77",
            "studentID-78",
            "studentID-79",
            "studentID-80"
        ]
    },
    {
        "id": "20G-1-1-2-2",
        "level": 20,
        "parentId": "60G-1-1-2",
        "members": [
            "studentID-81",
            "studentID-82",
            "studentID-83",
            "studentID-84",
            "studentID-85",
            "studentID-86",
            "studentID-87",
            "studentID-88",
            "studentID-89",
            "studentID-90",
            "studentID-91",
            "studentID-92",
            "studentID-93",
            "studentID-94",
            "studentID-95",
            "studentID-96",
            "studentID-97",
            "studentID-98",
            "studentID-99",
            "studentID-100"
        ]
    },
    {
        "id": "10G-1-1-2-2-1",
        "level": 10,
        "parentId": "20G-1-1-2-2",
        "members": [
            "studentID-81",
            "studentID-82",
            "studentID-83",
            "studentID-84",
            "studentID-85",
            "studentID-86",
            "studentID-87",
            "studentID-88",
            "studentID-89",
            "studentID-90"
        ]
    },
    {
        "id": "10G-1-1-2-2-2",
        "level": 10,
        "parentId": "20G-1-1-2-2",
        "members": [
            "studentID-91",
            "studentID-92",
            "studentID-93",
            "studentID-94",
            "studentID-95",
            "studentID-96",
            "studentID-97",
            "studentID-98",
            "studentID-99",
            "studentID-100"
        ]
    },
    {
        "id": "20G-1-1-2-3",
        "level": 20,
        "parentId": "60G-1-1-2",
        "members": [
            "studentID-101",
            "studentID-102",
            "studentID-103",
            "studentID-104",
            "studentID-105",
            "studentID-106",
            "studentID-107",
            "studentID-108",
            "studentID-109",
            "studentID-110",
            "studentID-111",
            "studentID-112",
            "studentID-113",
            "studentID-114",
            "studentID-115",
            "studentID-116",
            "studentID-117",
            "studentID-118",
            "studentID-119",
            "studentID-120"
        ]
    },
    {
        "id": "10G-1-1-2-3-1",
        "level": 10,
        "parentId": "20G-1-1-2-3",
        "members": [
            "studentID-101",
            "studentID-102",
            "studentID-103",
            "studentID-104",
            "studentID-105",
            "studentID-106",
            "studentID-107",
            "studentID-108",
            "studentID-109",
            "studentID-110"
        ]
    },
    {
        "id": "10G-1-1-2-3-2",
        "level": 10,
        "parentId": "20G-1-1-2-3",
        "members": [
            "studentID-111",
            "studentID-112",
            "studentID-113",
            "studentID-114",
            "studentID-115",
            "studentID-116",
            "studentID-117",
            "studentID-118",
            "studentID-119",
            "studentID-120"
        ]
    },
    {
        "id": "12G-1-1-2-1",
        "level": 12,
        "parentId": "60G-1-1-2",
        "members": [
            "studentID-61",
            "studentID-62",
            "studentID-63",
            "studentID-64",
            "studentID-65",
            "studentID-66",
            "studentID-67",
            "studentID-68",
            "studentID-69",
            "studentID-70",
            "studentID-71",
            "studentID-72"
        ]
    },
    {
        "id": "6G-1-1-2-1-1",
        "level": 6,
        "parentId": "12G-1-1-2-1",
        "members": [
            "studentID-61",
            "studentID-62",
            "studentID-63",
            "studentID-64",
            "studentID-65",
            "studentID-66"
        ]
    },
    {
        "id": "3G-1-1-2-1-1-1",
        "level": 3,
        "parentId": "6G-1-1-2-1-1",
        "members": [
            "studentID-61",
            "studentID-62",
            "studentID-63"
        ]
    },
    {
        "id": "3G-1-1-2-1-1-2",
        "level": 3,
        "parentId": "6G-1-1-2-1-1",
        "members": [
            "studentID-64",
            "studentID-65",
            "studentID-66"
        ]
    },
    {
        "id": "6G-1-1-2-1-2",
        "level": 6,
        "parentId": "12G-1-1-2-1",
        "members": [
            "studentID-67",
            "studentID-68",
            "studentID-69",
            "studentID-70",
            "studentID-71",
            "studentID-72"
        ]
    },
    {
        "id": "3G-1-1-2-1-2-1",
        "level": 3,
        "parentId": "6G-1-1-2-1-2",
        "members": [
            "studentID-67",
            "studentID-68",
            "studentID-69"
        ]
    },
    {
        "id": "3G-1-1-2-1-2-2",
        "level": 3,
        "parentId": "6G-1-1-2-1-2",
        "members": [
            "studentID-70",
            "studentID-71",
            "studentID-72"
        ]
    },
    {
        "id": "12G-1-1-2-2",
        "level": 12,
        "parentId": "60G-1-1-2",
        "members": [
            "studentID-73",
            "studentID-74",
            "studentID-75",
            "studentID-76",
            "studentID-77",
            "studentID-78",
            "studentID-79",
            "studentID-80",
            "studentID-81",
            "studentID-82",
            "studentID-83",
            "studentID-84"
        ]
    },
    {
        "id": "6G-1-1-2-2-1",
        "level": 6,
        "parentId": "12G-1-1-2-2",
        "members": [
            "studentID-73",
            "studentID-74",
            "studentID-75",
            "studentID-76",
            "studentID-77",
            "studentID-78"
        ]
    },
    {
        "id": "3G-1-1-2-2-1-1",
        "level": 3,
        "parentId": "6G-1-1-2-2-1",
        "members": [
            "studentID-73",
            "studentID-74",
            "studentID-75"
        ]
    },
    {
        "id": "3G-1-1-2-2-1-2",
        "level": 3,
        "parentId": "6G-1-1-2-2-1",
        "members": [
            "studentID-76",
            "studentID-77",
            "studentID-78"
        ]
    },
    {
        "id": "6G-1-1-2-2-2",
        "level": 6,
        "parentId": "12G-1-1-2-2",
        "members": [
            "studentID-79",
            "studentID-80",
            "studentID-81",
            "studentID-82",
            "studentID-83",
            "studentID-84"
        ]
    },
    {
        "id": "3G-1-1-2-2-2-1",
        "level": 3,
        "parentId": "6G-1-1-2-2-2",
        "members": [
            "studentID-79",
            "studentID-80",
            "studentID-81"
        ]
    },
    {
        "id": "3G-1-1-2-2-2-2",
        "level": 3,
        "parentId": "6G-1-1-2-2-2",
        "members": [
            "studentID-82",
            "studentID-83",
            "studentID-84"
        ]
    },
    {
        "id": "12G-1-1-2-3",
        "level": 12,
        "parentId": "60G-1-1-2",
        "members": [
            "studentID-85",
            "studentID-86",
            "studentID-87",
            "studentID-88",
            "studentID-89",
            "studentID-90",
            "studentID-91",
            "studentID-92",
            "studentID-93",
            "studentID-94",
            "studentID-95",
            "studentID-96"
        ]
    },
    {
        "id": "6G-1-1-2-3-1",
        "level": 6,
        "parentId": "12G-1-1-2-3",
        "members": [
            "studentID-85",
            "studentID-86",
            "studentID-87",
            "studentID-88",
            "studentID-89",
            "studentID-90"
        ]
    },
    {
        "id": "3G-1-1-2-3-1-1",
        "level": 3,
        "parentId": "6G-1-1-2-3-1",
        "members": [
            "studentID-85",
            "studentID-86",
            "studentID-87"
        ]
    },
    {
        "id": "3G-1-1-2-3-1-2",
        "level": 3,
        "parentId": "6G-1-1-2-3-1",
        "members": [
            "studentID-88",
            "studentID-89",
            "studentID-90"
        ]
    },
    {
        "id": "6G-1-1-2-3-2",
        "level": 6,
        "parentId": "12G-1-1-2-3",
        "members": [
            "studentID-91",
            "studentID-92",
            "studentID-93",
            "studentID-94",
            "studentID-95",
            "studentID-96"
        ]
    },
    {
        "id": "3G-1-1-2-3-2-1",
        "level": 3,
        "parentId": "6G-1-1-2-3-2",
        "members": [
            "studentID-91",
            "studentID-92",
            "studentID-93"
        ]
    },
    {
        "id": "3G-1-1-2-3-2-2",
        "level": 3,
        "parentId": "6G-1-1-2-3-2",
        "members": [
            "studentID-94",
            "studentID-95",
            "studentID-96"
        ]
    },
    {
        "id": "12G-1-1-2-4",
        "level": 12,
        "parentId": "60G-1-1-2",
        "members": [
            "studentID-97",
            "studentID-98",
            "studentID-99",
            "studentID-100",
            "studentID-101",
            "studentID-102",
            "studentID-103",
            "studentID-104",
            "studentID-105",
            "studentID-106",
            "studentID-107",
            "studentID-108"
        ]
    },
    {
        "id": "6G-1-1-2-4-1",
        "level": 6,
        "parentId": "12G-1-1-2-4",
        "members": [
            "studentID-97",
            "studentID-98",
            "studentID-99",
            "studentID-100",
            "studentID-101",
            "studentID-102"
        ]
    },
    {
        "id": "3G-1-1-2-4-1-1",
        "level": 3,
        "parentId": "6G-1-1-2-4-1",
        "members": [
            "studentID-97",
            "studentID-98",
            "studentID-99"
        ]
    },
    {
        "id": "3G-1-1-2-4-1-2",
        "level": 3,
        "parentId": "6G-1-1-2-4-1",
        "members": [
            "studentID-100",
            "studentID-101",
            "studentID-102"
        ]
    },
    {
        "id": "6G-1-1-2-4-2",
        "level": 6,
        "parentId": "12G-1-1-2-4",
        "members": [
            "studentID-103",
            "studentID-104",
            "studentID-105",
            "studentID-106",
            "studentID-107",
            "studentID-108"
        ]
    },
    {
        "id": "3G-1-1-2-4-2-1",
        "level": 3,
        "parentId": "6G-1-1-2-4-2",
        "members": [
            "studentID-103",
            "studentID-104",
            "studentID-105"
        ]
    },
    {
        "id": "3G-1-1-2-4-2-2",
        "level": 3,
        "parentId": "6G-1-1-2-4-2",
        "members": [
            "studentID-106",
            "studentID-107",
            "studentID-108"
        ]
    },
    {
        "id": "12G-1-1-2-5",
        "level": 12,
        "parentId": "60G-1-1-2",
        "members": [
            "studentID-109",
            "studentID-110",
            "studentID-111",
            "studentID-112",
            "studentID-113",
            "studentID-114",
            "studentID-115",
            "studentID-116",
            "studentID-117",
            "studentID-118",
            "studentID-119",
            "studentID-120"
        ]
    },
    {
        "id": "6G-1-1-2-5-1",
        "level": 6,
        "parentId": "12G-1-1-2-5",
        "members": [
            "studentID-109",
            "studentID-110",
            "studentID-111",
            "studentID-112",
            "studentID-113",
            "studentID-114"
        ]
    },
    {
        "id": "3G-1-1-2-5-1-1",
        "level": 3,
        "parentId": "6G-1-1-2-5-1",
        "members": [
            "studentID-109",
            "studentID-110",
            "studentID-111"
        ]
    },
    {
        "id": "3G-1-1-2-5-1-2",
        "level": 3,
        "parentId": "6G-1-1-2-5-1",
        "members": [
            "studentID-112",
            "studentID-113",
            "studentID-114"
        ]
    },
    {
        "id": "6G-1-1-2-5-2",
        "level": 6,
        "parentId": "12G-1-1-2-5",
        "members": [
            "studentID-115",
            "studentID-116",
            "studentID-117",
            "studentID-118",
            "studentID-119",
            "studentID-120"
        ]
    },
    {
        "id": "3G-1-1-2-5-2-1",
        "level": 3,
        "parentId": "6G-1-1-2-5-2",
        "members": [
            "studentID-115",
            "studentID-116",
            "studentID-117"
        ]
    },
    {
        "id": "3G-1-1-2-5-2-2",
        "level": 3,
        "parentId": "6G-1-1-2-5-2",
        "members": [
            "studentID-118",
            "studentID-119",
            "studentID-120"
        ]
    },
    {
        "id": "120G-1-2",
        "level": 120,
        "parentId": "336G-1",
        "members": [
            "studentID-121",
            "studentID-122",
            "studentID-123",
            "studentID-124",
            "studentID-125",
            "studentID-126",
            "studentID-127",
            "studentID-128",
            "studentID-129",
            "studentID-130",
            "studentID-131",
            "studentID-132",
            "studentID-133",
            "studentID-134",
            "studentID-135",
            "studentID-136",
            "studentID-137",
            "studentID-138",
            "studentID-139",
            "studentID-140",
            "studentID-141",
            "studentID-142",
            "studentID-143",
            "studentID-144",
            "studentID-145",
            "studentID-146",
            "studentID-147",
            "studentID-148",
            "studentID-149",
            "studentID-150",
            "studentID-151",
            "studentID-152",
            "studentID-153",
            "studentID-154",
            "studentID-155",
            "studentID-156",
            "studentID-157",
            "studentID-158",
            "studentID-159",
            "studentID-160",
            "studentID-161",
            "studentID-162",
            "studentID-163",
            "studentID-164",
            "studentID-165",
            "studentID-166",
            "studentID-167",
            "studentID-168",
            "studentID-169",
            "studentID-170",
            "studentID-171",
            "studentID-172",
            "studentID-173",
            "studentID-174",
            "studentID-175",
            "studentID-176",
            "studentID-177",
            "studentID-178",
            "studentID-179",
            "studentID-180",
            "studentID-181",
            "studentID-182",
            "studentID-183",
            "studentID-184",
            "studentID-185",
            "studentID-186",
            "studentID-187",
            "studentID-188",
            "studentID-189",
            "studentID-190",
            "studentID-191",
            "studentID-192",
            "studentID-193",
            "studentID-194",
            "studentID-195",
            "studentID-196",
            "studentID-197",
            "studentID-198",
            "studentID-199",
            "studentID-200",
            "studentID-201",
            "studentID-202",
            "studentID-203",
            "studentID-204",
            "studentID-205",
            "studentID-206",
            "studentID-207",
            "studentID-208",
            "studentID-209",
            "studentID-210",
            "studentID-211",
            "studentID-212",
            "studentID-213",
            "studentID-214",
            "studentID-215",
            "studentID-216",
            "studentID-217",
            "studentID-218",
            "studentID-219",
            "studentID-220",
            "studentID-221",
            "studentID-222",
            "studentID-223",
            "studentID-224",
            "studentID-225",
            "studentID-226",
            "studentID-227",
            "studentID-228",
            "studentID-229",
            "studentID-230",
            "studentID-231",
            "studentID-232",
            "studentID-233",
            "studentID-234",
            "studentID-235",
            "studentID-236",
            "studentID-237",
            "studentID-238",
            "studentID-239",
            "studentID-240"
        ]
    },
    {
        "id": "60G-1-2-1",
        "level": 60,
        "parentId": "120G-1-2",
        "members": [
            "studentID-121",
            "studentID-122",
            "studentID-123",
            "studentID-124",
            "studentID-125",
            "studentID-126",
            "studentID-127",
            "studentID-128",
            "studentID-129",
            "studentID-130",
            "studentID-131",
            "studentID-132",
            "studentID-133",
            "studentID-134",
            "studentID-135",
            "studentID-136",
            "studentID-137",
            "studentID-138",
            "studentID-139",
            "studentID-140",
            "studentID-141",
            "studentID-142",
            "studentID-143",
            "studentID-144",
            "studentID-145",
            "studentID-146",
            "studentID-147",
            "studentID-148",
            "studentID-149",
            "studentID-150",
            "studentID-151",
            "studentID-152",
            "studentID-153",
            "studentID-154",
            "studentID-155",
            "studentID-156",
            "studentID-157",
            "studentID-158",
            "studentID-159",
            "studentID-160",
            "studentID-161",
            "studentID-162",
            "studentID-163",
            "studentID-164",
            "studentID-165",
            "studentID-166",
            "studentID-167",
            "studentID-168",
            "studentID-169",
            "studentID-170",
            "studentID-171",
            "studentID-172",
            "studentID-173",
            "studentID-174",
            "studentID-175",
            "studentID-176",
            "studentID-177",
            "studentID-178",
            "studentID-179",
            "studentID-180"
        ]
    },
    {
        "id": "20G-1-2-1-1",
        "level": 20,
        "parentId": "60G-1-2-1",
        "members": [
            "studentID-121",
            "studentID-122",
            "studentID-123",
            "studentID-124",
            "studentID-125",
            "studentID-126",
            "studentID-127",
            "studentID-128",
            "studentID-129",
            "studentID-130",
            "studentID-131",
            "studentID-132",
            "studentID-133",
            "studentID-134",
            "studentID-135",
            "studentID-136",
            "studentID-137",
            "studentID-138",
            "studentID-139",
            "studentID-140"
        ]
    },
    {
        "id": "10G-1-2-1-1-1",
        "level": 10,
        "parentId": "20G-1-2-1-1",
        "members": [
            "studentID-121",
            "studentID-122",
            "studentID-123",
            "studentID-124",
            "studentID-125",
            "studentID-126",
            "studentID-127",
            "studentID-128",
            "studentID-129",
            "studentID-130"
        ]
    },
    {
        "id": "10G-1-2-1-1-2",
        "level": 10,
        "parentId": "20G-1-2-1-1",
        "members": [
            "studentID-131",
            "studentID-132",
            "studentID-133",
            "studentID-134",
            "studentID-135",
            "studentID-136",
            "studentID-137",
            "studentID-138",
            "studentID-139",
            "studentID-140"
        ]
    },
    {
        "id": "20G-1-2-1-2",
        "level": 20,
        "parentId": "60G-1-2-1",
        "members": [
            "studentID-141",
            "studentID-142",
            "studentID-143",
            "studentID-144",
            "studentID-145",
            "studentID-146",
            "studentID-147",
            "studentID-148",
            "studentID-149",
            "studentID-150",
            "studentID-151",
            "studentID-152",
            "studentID-153",
            "studentID-154",
            "studentID-155",
            "studentID-156",
            "studentID-157",
            "studentID-158",
            "studentID-159",
            "studentID-160"
        ]
    },
    {
        "id": "10G-1-2-1-2-1",
        "level": 10,
        "parentId": "20G-1-2-1-2",
        "members": [
            "studentID-141",
            "studentID-142",
            "studentID-143",
            "studentID-144",
            "studentID-145",
            "studentID-146",
            "studentID-147",
            "studentID-148",
            "studentID-149",
            "studentID-150"
        ]
    },
    {
        "id": "10G-1-2-1-2-2",
        "level": 10,
        "parentId": "20G-1-2-1-2",
        "members": [
            "studentID-151",
            "studentID-152",
            "studentID-153",
            "studentID-154",
            "studentID-155",
            "studentID-156",
            "studentID-157",
            "studentID-158",
            "studentID-159",
            "studentID-160"
        ]
    },
    {
        "id": "20G-1-2-1-3",
        "level": 20,
        "parentId": "60G-1-2-1",
        "members": [
            "studentID-161",
            "studentID-162",
            "studentID-163",
            "studentID-164",
            "studentID-165",
            "studentID-166",
            "studentID-167",
            "studentID-168",
            "studentID-169",
            "studentID-170",
            "studentID-171",
            "studentID-172",
            "studentID-173",
            "studentID-174",
            "studentID-175",
            "studentID-176",
            "studentID-177",
            "studentID-178",
            "studentID-179",
            "studentID-180"
        ]
    },
    {
        "id": "10G-1-2-1-3-1",
        "level": 10,
        "parentId": "20G-1-2-1-3",
        "members": [
            "studentID-161",
            "studentID-162",
            "studentID-163",
            "studentID-164",
            "studentID-165",
            "studentID-166",
            "studentID-167",
            "studentID-168",
            "studentID-169",
            "studentID-170"
        ]
    },
    {
        "id": "10G-1-2-1-3-2",
        "level": 10,
        "parentId": "20G-1-2-1-3",
        "members": [
            "studentID-171",
            "studentID-172",
            "studentID-173",
            "studentID-174",
            "studentID-175",
            "studentID-176",
            "studentID-177",
            "studentID-178",
            "studentID-179",
            "studentID-180"
        ]
    },
    {
        "id": "12G-1-2-1-1",
        "level": 12,
        "parentId": "60G-1-2-1",
        "members": [
            "studentID-121",
            "studentID-122",
            "studentID-123",
            "studentID-124",
            "studentID-125",
            "studentID-126",
            "studentID-127",
            "studentID-128",
            "studentID-129",
            "studentID-130",
            "studentID-131",
            "studentID-132"
        ]
    },
    {
        "id": "6G-1-2-1-1-1",
        "level": 6,
        "parentId": "12G-1-2-1-1",
        "members": [
            "studentID-121",
            "studentID-122",
            "studentID-123",
            "studentID-124",
            "studentID-125",
            "studentID-126"
        ]
    },
    {
        "id": "3G-1-2-1-1-1-1",
        "level": 3,
        "parentId": "6G-1-2-1-1-1",
        "members": [
            "studentID-121",
            "studentID-122",
            "studentID-123"
        ]
    },
    {
        "id": "3G-1-2-1-1-1-2",
        "level": 3,
        "parentId": "6G-1-2-1-1-1",
        "members": [
            "studentID-124",
            "studentID-125",
            "studentID-126"
        ]
    },
    {
        "id": "6G-1-2-1-1-2",
        "level": 6,
        "parentId": "12G-1-2-1-1",
        "members": [
            "studentID-127",
            "studentID-128",
            "studentID-129",
            "studentID-130",
            "studentID-131",
            "studentID-132"
        ]
    },
    {
        "id": "3G-1-2-1-1-2-1",
        "level": 3,
        "parentId": "6G-1-2-1-1-2",
        "members": [
            "studentID-127",
            "studentID-128",
            "studentID-129"
        ]
    },
    {
        "id": "3G-1-2-1-1-2-2",
        "level": 3,
        "parentId": "6G-1-2-1-1-2",
        "members": [
            "studentID-130",
            "studentID-131",
            "studentID-132"
        ]
    },
    {
        "id": "12G-1-2-1-2",
        "level": 12,
        "parentId": "60G-1-2-1",
        "members": [
            "studentID-133",
            "studentID-134",
            "studentID-135",
            "studentID-136",
            "studentID-137",
            "studentID-138",
            "studentID-139",
            "studentID-140",
            "studentID-141",
            "studentID-142",
            "studentID-143",
            "studentID-144"
        ]
    },
    {
        "id": "6G-1-2-1-2-1",
        "level": 6,
        "parentId": "12G-1-2-1-2",
        "members": [
            "studentID-133",
            "studentID-134",
            "studentID-135",
            "studentID-136",
            "studentID-137",
            "studentID-138"
        ]
    },
    {
        "id": "3G-1-2-1-2-1-1",
        "level": 3,
        "parentId": "6G-1-2-1-2-1",
        "members": [
            "studentID-133",
            "studentID-134",
            "studentID-135"
        ]
    },
    {
        "id": "3G-1-2-1-2-1-2",
        "level": 3,
        "parentId": "6G-1-2-1-2-1",
        "members": [
            "studentID-136",
            "studentID-137",
            "studentID-138"
        ]
    },
    {
        "id": "6G-1-2-1-2-2",
        "level": 6,
        "parentId": "12G-1-2-1-2",
        "members": [
            "studentID-139",
            "studentID-140",
            "studentID-141",
            "studentID-142",
            "studentID-143",
            "studentID-144"
        ]
    },
    {
        "id": "3G-1-2-1-2-2-1",
        "level": 3,
        "parentId": "6G-1-2-1-2-2",
        "members": [
            "studentID-139",
            "studentID-140",
            "studentID-141"
        ]
    },
    {
        "id": "3G-1-2-1-2-2-2",
        "level": 3,
        "parentId": "6G-1-2-1-2-2",
        "members": [
            "studentID-142",
            "studentID-143",
            "studentID-144"
        ]
    },
    {
        "id": "12G-1-2-1-3",
        "level": 12,
        "parentId": "60G-1-2-1",
        "members": [
            "studentID-145",
            "studentID-146",
            "studentID-147",
            "studentID-148",
            "studentID-149",
            "studentID-150",
            "studentID-151",
            "studentID-152",
            "studentID-153",
            "studentID-154",
            "studentID-155",
            "studentID-156"
        ]
    },
    {
        "id": "6G-1-2-1-3-1",
        "level": 6,
        "parentId": "12G-1-2-1-3",
        "members": [
            "studentID-145",
            "studentID-146",
            "studentID-147",
            "studentID-148",
            "studentID-149",
            "studentID-150"
        ]
    },
    {
        "id": "3G-1-2-1-3-1-1",
        "level": 3,
        "parentId": "6G-1-2-1-3-1",
        "members": [
            "studentID-145",
            "studentID-146",
            "studentID-147"
        ]
    },
    {
        "id": "3G-1-2-1-3-1-2",
        "level": 3,
        "parentId": "6G-1-2-1-3-1",
        "members": [
            "studentID-148",
            "studentID-149",
            "studentID-150"
        ]
    },
    {
        "id": "6G-1-2-1-3-2",
        "level": 6,
        "parentId": "12G-1-2-1-3",
        "members": [
            "studentID-151",
            "studentID-152",
            "studentID-153",
            "studentID-154",
            "studentID-155",
            "studentID-156"
        ]
    },
    {
        "id": "3G-1-2-1-3-2-1",
        "level": 3,
        "parentId": "6G-1-2-1-3-2",
        "members": [
            "studentID-151",
            "studentID-152",
            "studentID-153"
        ]
    },
    {
        "id": "3G-1-2-1-3-2-2",
        "level": 3,
        "parentId": "6G-1-2-1-3-2",
        "members": [
            "studentID-154",
            "studentID-155",
            "studentID-156"
        ]
    },
    {
        "id": "12G-1-2-1-4",
        "level": 12,
        "parentId": "60G-1-2-1",
        "members": [
            "studentID-157",
            "studentID-158",
            "studentID-159",
            "studentID-160",
            "studentID-161",
            "studentID-162",
            "studentID-163",
            "studentID-164",
            "studentID-165",
            "studentID-166",
            "studentID-167",
            "studentID-168"
        ]
    },
    {
        "id": "6G-1-2-1-4-1",
        "level": 6,
        "parentId": "12G-1-2-1-4",
        "members": [
            "studentID-157",
            "studentID-158",
            "studentID-159",
            "studentID-160",
            "studentID-161",
            "studentID-162"
        ]
    },
    {
        "id": "3G-1-2-1-4-1-1",
        "level": 3,
        "parentId": "6G-1-2-1-4-1",
        "members": [
            "studentID-157",
            "studentID-158",
            "studentID-159"
        ]
    },
    {
        "id": "3G-1-2-1-4-1-2",
        "level": 3,
        "parentId": "6G-1-2-1-4-1",
        "members": [
            "studentID-160",
            "studentID-161",
            "studentID-162"
        ]
    },
    {
        "id": "6G-1-2-1-4-2",
        "level": 6,
        "parentId": "12G-1-2-1-4",
        "members": [
            "studentID-163",
            "studentID-164",
            "studentID-165",
            "studentID-166",
            "studentID-167",
            "studentID-168"
        ]
    },
    {
        "id": "3G-1-2-1-4-2-1",
        "level": 3,
        "parentId": "6G-1-2-1-4-2",
        "members": [
            "studentID-163",
            "studentID-164",
            "studentID-165"
        ]
    },
    {
        "id": "3G-1-2-1-4-2-2",
        "level": 3,
        "parentId": "6G-1-2-1-4-2",
        "members": [
            "studentID-166",
            "studentID-167",
            "studentID-168"
        ]
    },
    {
        "id": "12G-1-2-1-5",
        "level": 12,
        "parentId": "60G-1-2-1",
        "members": [
            "studentID-169",
            "studentID-170",
            "studentID-171",
            "studentID-172",
            "studentID-173",
            "studentID-174",
            "studentID-175",
            "studentID-176",
            "studentID-177",
            "studentID-178",
            "studentID-179",
            "studentID-180"
        ]
    },
    {
        "id": "6G-1-2-1-5-1",
        "level": 6,
        "parentId": "12G-1-2-1-5",
        "members": [
            "studentID-169",
            "studentID-170",
            "studentID-171",
            "studentID-172",
            "studentID-173",
            "studentID-174"
        ]
    },
    {
        "id": "3G-1-2-1-5-1-1",
        "level": 3,
        "parentId": "6G-1-2-1-5-1",
        "members": [
            "studentID-169",
            "studentID-170",
            "studentID-171"
        ]
    },
    {
        "id": "3G-1-2-1-5-1-2",
        "level": 3,
        "parentId": "6G-1-2-1-5-1",
        "members": [
            "studentID-172",
            "studentID-173",
            "studentID-174"
        ]
    },
    {
        "id": "6G-1-2-1-5-2",
        "level": 6,
        "parentId": "12G-1-2-1-5",
        "members": [
            "studentID-175",
            "studentID-176",
            "studentID-177",
            "studentID-178",
            "studentID-179",
            "studentID-180"
        ]
    },
    {
        "id": "3G-1-2-1-5-2-1",
        "level": 3,
        "parentId": "6G-1-2-1-5-2",
        "members": [
            "studentID-175",
            "studentID-176",
            "studentID-177"
        ]
    },
    {
        "id": "3G-1-2-1-5-2-2",
        "level": 3,
        "parentId": "6G-1-2-1-5-2",
        "members": [
            "studentID-178",
            "studentID-179",
            "studentID-180"
        ]
    },
    {
        "id": "60G-1-2-2",
        "level": 60,
        "parentId": "120G-1-2",
        "members": [
            "studentID-181",
            "studentID-182",
            "studentID-183",
            "studentID-184",
            "studentID-185",
            "studentID-186",
            "studentID-187",
            "studentID-188",
            "studentID-189",
            "studentID-190",
            "studentID-191",
            "studentID-192",
            "studentID-193",
            "studentID-194",
            "studentID-195",
            "studentID-196",
            "studentID-197",
            "studentID-198",
            "studentID-199",
            "studentID-200",
            "studentID-201",
            "studentID-202",
            "studentID-203",
            "studentID-204",
            "studentID-205",
            "studentID-206",
            "studentID-207",
            "studentID-208",
            "studentID-209",
            "studentID-210",
            "studentID-211",
            "studentID-212",
            "studentID-213",
            "studentID-214",
            "studentID-215",
            "studentID-216",
            "studentID-217",
            "studentID-218",
            "studentID-219",
            "studentID-220",
            "studentID-221",
            "studentID-222",
            "studentID-223",
            "studentID-224",
            "studentID-225",
            "studentID-226",
            "studentID-227",
            "studentID-228",
            "studentID-229",
            "studentID-230",
            "studentID-231",
            "studentID-232",
            "studentID-233",
            "studentID-234",
            "studentID-235",
            "studentID-236",
            "studentID-237",
            "studentID-238",
            "studentID-239",
            "studentID-240"
        ]
    },
    {
        "id": "20G-1-2-2-1",
        "level": 20,
        "parentId": "60G-1-2-2",
        "members": [
            "studentID-181",
            "studentID-182",
            "studentID-183",
            "studentID-184",
            "studentID-185",
            "studentID-186",
            "studentID-187",
            "studentID-188",
            "studentID-189",
            "studentID-190",
            "studentID-191",
            "studentID-192",
            "studentID-193",
            "studentID-194",
            "studentID-195",
            "studentID-196",
            "studentID-197",
            "studentID-198",
            "studentID-199",
            "studentID-200"
        ]
    },
    {
        "id": "10G-1-2-2-1-1",
        "level": 10,
        "parentId": "20G-1-2-2-1",
        "members": [
            "studentID-181",
            "studentID-182",
            "studentID-183",
            "studentID-184",
            "studentID-185",
            "studentID-186",
            "studentID-187",
            "studentID-188",
            "studentID-189",
            "studentID-190"
        ]
    },
    {
        "id": "10G-1-2-2-1-2",
        "level": 10,
        "parentId": "20G-1-2-2-1",
        "members": [
            "studentID-191",
            "studentID-192",
            "studentID-193",
            "studentID-194",
            "studentID-195",
            "studentID-196",
            "studentID-197",
            "studentID-198",
            "studentID-199",
            "studentID-200"
        ]
    },
    {
        "id": "20G-1-2-2-2",
        "level": 20,
        "parentId": "60G-1-2-2",
        "members": [
            "studentID-201",
            "studentID-202",
            "studentID-203",
            "studentID-204",
            "studentID-205",
            "studentID-206",
            "studentID-207",
            "studentID-208",
            "studentID-209",
            "studentID-210",
            "studentID-211",
            "studentID-212",
            "studentID-213",
            "studentID-214",
            "studentID-215",
            "studentID-216",
            "studentID-217",
            "studentID-218",
            "studentID-219",
            "studentID-220"
        ]
    },
    {
        "id": "10G-1-2-2-2-1",
        "level": 10,
        "parentId": "20G-1-2-2-2",
        "members": [
            "studentID-201",
            "studentID-202",
            "studentID-203",
            "studentID-204",
            "studentID-205",
            "studentID-206",
            "studentID-207",
            "studentID-208",
            "studentID-209",
            "studentID-210"
        ]
    },
    {
        "id": "10G-1-2-2-2-2",
        "level": 10,
        "parentId": "20G-1-2-2-2",
        "members": [
            "studentID-211",
            "studentID-212",
            "studentID-213",
            "studentID-214",
            "studentID-215",
            "studentID-216",
            "studentID-217",
            "studentID-218",
            "studentID-219",
            "studentID-220"
        ]
    },
    {
        "id": "20G-1-2-2-3",
        "level": 20,
        "parentId": "60G-1-2-2",
        "members": [
            "studentID-221",
            "studentID-222",
            "studentID-223",
            "studentID-224",
            "studentID-225",
            "studentID-226",
            "studentID-227",
            "studentID-228",
            "studentID-229",
            "studentID-230",
            "studentID-231",
            "studentID-232",
            "studentID-233",
            "studentID-234",
            "studentID-235",
            "studentID-236",
            "studentID-237",
            "studentID-238",
            "studentID-239",
            "studentID-240"
        ]
    },
    {
        "id": "10G-1-2-2-3-1",
        "level": 10,
        "parentId": "20G-1-2-2-3",
        "members": [
            "studentID-221",
            "studentID-222",
            "studentID-223",
            "studentID-224",
            "studentID-225",
            "studentID-226",
            "studentID-227",
            "studentID-228",
            "studentID-229",
            "studentID-230"
        ]
    },
    {
        "id": "10G-1-2-2-3-2",
        "level": 10,
        "parentId": "20G-1-2-2-3",
        "members": [
            "studentID-231",
            "studentID-232",
            "studentID-233",
            "studentID-234",
            "studentID-235",
            "studentID-236",
            "studentID-237",
            "studentID-238",
            "studentID-239",
            "studentID-240"
        ]
    },
    {
        "id": "12G-1-2-2-1",
        "level": 12,
        "parentId": "60G-1-2-2",
        "members": [
            "studentID-181",
            "studentID-182",
            "studentID-183",
            "studentID-184",
            "studentID-185",
            "studentID-186",
            "studentID-187",
            "studentID-188",
            "studentID-189",
            "studentID-190",
            "studentID-191",
            "studentID-192"
        ]
    },
    {
        "id": "6G-1-2-2-1-1",
        "level": 6,
        "parentId": "12G-1-2-2-1",
        "members": [
            "studentID-181",
            "studentID-182",
            "studentID-183",
            "studentID-184",
            "studentID-185",
            "studentID-186"
        ]
    },
    {
        "id": "3G-1-2-2-1-1-1",
        "level": 3,
        "parentId": "6G-1-2-2-1-1",
        "members": [
            "studentID-181",
            "studentID-182",
            "studentID-183"
        ]
    },
    {
        "id": "3G-1-2-2-1-1-2",
        "level": 3,
        "parentId": "6G-1-2-2-1-1",
        "members": [
            "studentID-184",
            "studentID-185",
            "studentID-186"
        ]
    },
    {
        "id": "6G-1-2-2-1-2",
        "level": 6,
        "parentId": "12G-1-2-2-1",
        "members": [
            "studentID-187",
            "studentID-188",
            "studentID-189",
            "studentID-190",
            "studentID-191",
            "studentID-192"
        ]
    },
    {
        "id": "3G-1-2-2-1-2-1",
        "level": 3,
        "parentId": "6G-1-2-2-1-2",
        "members": [
            "studentID-187",
            "studentID-188",
            "studentID-189"
        ]
    },
    {
        "id": "3G-1-2-2-1-2-2",
        "level": 3,
        "parentId": "6G-1-2-2-1-2",
        "members": [
            "studentID-190",
            "studentID-191",
            "studentID-192"
        ]
    },
    {
        "id": "12G-1-2-2-2",
        "level": 12,
        "parentId": "60G-1-2-2",
        "members": [
            "studentID-193",
            "studentID-194",
            "studentID-195",
            "studentID-196",
            "studentID-197",
            "studentID-198",
            "studentID-199",
            "studentID-200",
            "studentID-201",
            "studentID-202",
            "studentID-203",
            "studentID-204"
        ]
    },
    {
        "id": "6G-1-2-2-2-1",
        "level": 6,
        "parentId": "12G-1-2-2-2",
        "members": [
            "studentID-193",
            "studentID-194",
            "studentID-195",
            "studentID-196",
            "studentID-197",
            "studentID-198"
        ]
    },
    {
        "id": "3G-1-2-2-2-1-1",
        "level": 3,
        "parentId": "6G-1-2-2-2-1",
        "members": [
            "studentID-193",
            "studentID-194",
            "studentID-195"
        ]
    },
    {
        "id": "3G-1-2-2-2-1-2",
        "level": 3,
        "parentId": "6G-1-2-2-2-1",
        "members": [
            "studentID-196",
            "studentID-197",
            "studentID-198"
        ]
    },
    {
        "id": "6G-1-2-2-2-2",
        "level": 6,
        "parentId": "12G-1-2-2-2",
        "members": [
            "studentID-199",
            "studentID-200",
            "studentID-201",
            "studentID-202",
            "studentID-203",
            "studentID-204"
        ]
    },
    {
        "id": "3G-1-2-2-2-2-1",
        "level": 3,
        "parentId": "6G-1-2-2-2-2",
        "members": [
            "studentID-199",
            "studentID-200",
            "studentID-201"
        ]
    },
    {
        "id": "3G-1-2-2-2-2-2",
        "level": 3,
        "parentId": "6G-1-2-2-2-2",
        "members": [
            "studentID-202",
            "studentID-203",
            "studentID-204"
        ]
    },
    {
        "id": "12G-1-2-2-3",
        "level": 12,
        "parentId": "60G-1-2-2",
        "members": [
            "studentID-205",
            "studentID-206",
            "studentID-207",
            "studentID-208",
            "studentID-209",
            "studentID-210",
            "studentID-211",
            "studentID-212",
            "studentID-213",
            "studentID-214",
            "studentID-215",
            "studentID-216"
        ]
    },
    {
        "id": "6G-1-2-2-3-1",
        "level": 6,
        "parentId": "12G-1-2-2-3",
        "members": [
            "studentID-205",
            "studentID-206",
            "studentID-207",
            "studentID-208",
            "studentID-209",
            "studentID-210"
        ]
    },
    {
        "id": "3G-1-2-2-3-1-1",
        "level": 3,
        "parentId": "6G-1-2-2-3-1",
        "members": [
            "studentID-205",
            "studentID-206",
            "studentID-207"
        ]
    },
    {
        "id": "3G-1-2-2-3-1-2",
        "level": 3,
        "parentId": "6G-1-2-2-3-1",
        "members": [
            "studentID-208",
            "studentID-209",
            "studentID-210"
        ]
    },
    {
        "id": "6G-1-2-2-3-2",
        "level": 6,
        "parentId": "12G-1-2-2-3",
        "members": [
            "studentID-211",
            "studentID-212",
            "studentID-213",
            "studentID-214",
            "studentID-215",
            "studentID-216"
        ]
    },
    {
        "id": "3G-1-2-2-3-2-1",
        "level": 3,
        "parentId": "6G-1-2-2-3-2",
        "members": [
            "studentID-211",
            "studentID-212",
            "studentID-213"
        ]
    },
    {
        "id": "3G-1-2-2-3-2-2",
        "level": 3,
        "parentId": "6G-1-2-2-3-2",
        "members": [
            "studentID-214",
            "studentID-215",
            "studentID-216"
        ]
    },
    {
        "id": "12G-1-2-2-4",
        "level": 12,
        "parentId": "60G-1-2-2",
        "members": [
            "studentID-217",
            "studentID-218",
            "studentID-219",
            "studentID-220",
            "studentID-221",
            "studentID-222",
            "studentID-223",
            "studentID-224",
            "studentID-225",
            "studentID-226",
            "studentID-227",
            "studentID-228"
        ]
    },
    {
        "id": "6G-1-2-2-4-1",
        "level": 6,
        "parentId": "12G-1-2-2-4",
        "members": [
            "studentID-217",
            "studentID-218",
            "studentID-219",
            "studentID-220",
            "studentID-221",
            "studentID-222"
        ]
    },
    {
        "id": "3G-1-2-2-4-1-1",
        "level": 3,
        "parentId": "6G-1-2-2-4-1",
        "members": [
            "studentID-217",
            "studentID-218",
            "studentID-219"
        ]
    },
    {
        "id": "3G-1-2-2-4-1-2",
        "level": 3,
        "parentId": "6G-1-2-2-4-1",
        "members": [
            "studentID-220",
            "studentID-221",
            "studentID-222"
        ]
    },
    {
        "id": "6G-1-2-2-4-2",
        "level": 6,
        "parentId": "12G-1-2-2-4",
        "members": [
            "studentID-223",
            "studentID-224",
            "studentID-225",
            "studentID-226",
            "studentID-227",
            "studentID-228"
        ]
    },
    {
        "id": "3G-1-2-2-4-2-1",
        "level": 3,
        "parentId": "6G-1-2-2-4-2",
        "members": [
            "studentID-223",
            "studentID-224",
            "studentID-225"
        ]
    },
    {
        "id": "3G-1-2-2-4-2-2",
        "level": 3,
        "parentId": "6G-1-2-2-4-2",
        "members": [
            "studentID-226",
            "studentID-227",
            "studentID-228"
        ]
    },
    {
        "id": "12G-1-2-2-5",
        "level": 12,
        "parentId": "60G-1-2-2",
        "members": [
            "studentID-229",
            "studentID-230",
            "studentID-231",
            "studentID-232",
            "studentID-233",
            "studentID-234",
            "studentID-235",
            "studentID-236",
            "studentID-237",
            "studentID-238",
            "studentID-239",
            "studentID-240"
        ]
    },
    {
        "id": "6G-1-2-2-5-1",
        "level": 6,
        "parentId": "12G-1-2-2-5",
        "members": [
            "studentID-229",
            "studentID-230",
            "studentID-231",
            "studentID-232",
            "studentID-233",
            "studentID-234"
        ]
    },
    {
        "id": "3G-1-2-2-5-1-1",
        "level": 3,
        "parentId": "6G-1-2-2-5-1",
        "members": [
            "studentID-229",
            "studentID-230",
            "studentID-231"
        ]
    },
    {
        "id": "3G-1-2-2-5-1-2",
        "level": 3,
        "parentId": "6G-1-2-2-5-1",
        "members": [
            "studentID-232",
            "studentID-233",
            "studentID-234"
        ]
    },
    {
        "id": "6G-1-2-2-5-2",
        "level": 6,
        "parentId": "12G-1-2-2-5",
        "members": [
            "studentID-235",
            "studentID-236",
            "studentID-237",
            "studentID-238",
            "studentID-239",
            "studentID-240"
        ]
    },
    {
        "id": "3G-1-2-2-5-2-1",
        "level": 3,
        "parentId": "6G-1-2-2-5-2",
        "members": [
            "studentID-235",
            "studentID-236",
            "studentID-237"
        ]
    },
    {
        "id": "3G-1-2-2-5-2-2",
        "level": 3,
        "parentId": "6G-1-2-2-5-2",
        "members": [
            "studentID-238",
            "studentID-239",
            "studentID-240"
        ]
    },
    {
        "id": "120G-1-3",
        "level": 120,
        "parentId": "336G-1",
        "members": [
            "studentID-241",
            "studentID-242",
            "studentID-243",
            "studentID-244",
            "studentID-245",
            "studentID-246",
            "studentID-247",
            "studentID-248",
            "studentID-249",
            "studentID-250",
            "studentID-251",
            "studentID-252",
            "studentID-253",
            "studentID-254",
            "studentID-255",
            "studentID-256",
            "studentID-257",
            "studentID-258",
            "studentID-259",
            "studentID-260",
            "studentID-261",
            "studentID-262",
            "studentID-263",
            "studentID-264",
            "studentID-265",
            "studentID-266",
            "studentID-267",
            "studentID-268",
            "studentID-269",
            "studentID-270",
            "studentID-271",
            "studentID-272",
            "studentID-273",
            "studentID-274",
            "studentID-275",
            "studentID-276",
            "studentID-277",
            "studentID-278",
            "studentID-279",
            "studentID-280",
            "studentID-281",
            "studentID-282",
            "studentID-283",
            "studentID-284",
            "studentID-285",
            "studentID-286",
            "studentID-287",
            "studentID-288",
            "studentID-289",
            "studentID-290",
            "studentID-291",
            "studentID-292",
            "studentID-293",
            "studentID-294",
            "studentID-295",
            "studentID-296",
            "studentID-297",
            "studentID-298",
            "studentID-299",
            "studentID-300",
            "studentID-301",
            "studentID-302",
            "studentID-303",
            "studentID-304",
            "studentID-305",
            "studentID-306",
            "studentID-307",
            "studentID-308",
            "studentID-309",
            "studentID-310",
            "studentID-311",
            "studentID-312",
            "studentID-313",
            "studentID-314",
            "studentID-315",
            "studentID-316",
            "studentID-317",
            "studentID-318",
            "studentID-319",
            "studentID-320",
            "studentID-321",
            "studentID-322",
            "studentID-323",
            "studentID-324",
            "studentID-325",
            "studentID-326",
            "studentID-327",
            "studentID-328",
            "studentID-329",
            "studentID-330",
            "studentID-331",
            "studentID-332",
            "studentID-333",
            "studentID-334",
            "studentID-335",
            "studentID-336",
            "studentID-337",
            "studentID-338",
            "studentID-339",
            "studentID-340",
            "studentID-341",
            "studentID-342",
            "studentID-343",
            "studentID-344",
            "studentID-345",
            "studentID-346",
            "studentID-347",
            "studentID-348",
            "studentID-349",
            "studentID-350",
            "studentID-351",
            "studentID-352",
            "studentID-353",
            "studentID-354",
            "studentID-355",
            "studentID-356",
            "studentID-357",
            "studentID-358",
            "studentID-359",
            "studentID-360"
        ]
    },
    {
        "id": "60G-1-3-1",
        "level": 60,
        "parentId": "120G-1-3",
        "members": [
            "studentID-241",
            "studentID-242",
            "studentID-243",
            "studentID-244",
            "studentID-245",
            "studentID-246",
            "studentID-247",
            "studentID-248",
            "studentID-249",
            "studentID-250",
            "studentID-251",
            "studentID-252",
            "studentID-253",
            "studentID-254",
            "studentID-255",
            "studentID-256",
            "studentID-257",
            "studentID-258",
            "studentID-259",
            "studentID-260",
            "studentID-261",
            "studentID-262",
            "studentID-263",
            "studentID-264",
            "studentID-265",
            "studentID-266",
            "studentID-267",
            "studentID-268",
            "studentID-269",
            "studentID-270",
            "studentID-271",
            "studentID-272",
            "studentID-273",
            "studentID-274",
            "studentID-275",
            "studentID-276",
            "studentID-277",
            "studentID-278",
            "studentID-279",
            "studentID-280",
            "studentID-281",
            "studentID-282",
            "studentID-283",
            "studentID-284",
            "studentID-285",
            "studentID-286",
            "studentID-287",
            "studentID-288",
            "studentID-289",
            "studentID-290",
            "studentID-291",
            "studentID-292",
            "studentID-293",
            "studentID-294",
            "studentID-295",
            "studentID-296",
            "studentID-297",
            "studentID-298",
            "studentID-299",
            "studentID-300"
        ]
    },
    {
        "id": "20G-1-3-1-1",
        "level": 20,
        "parentId": "60G-1-3-1",
        "members": [
            "studentID-241",
            "studentID-242",
            "studentID-243",
            "studentID-244",
            "studentID-245",
            "studentID-246",
            "studentID-247",
            "studentID-248",
            "studentID-249",
            "studentID-250",
            "studentID-251",
            "studentID-252",
            "studentID-253",
            "studentID-254",
            "studentID-255",
            "studentID-256",
            "studentID-257",
            "studentID-258",
            "studentID-259",
            "studentID-260"
        ]
    },
    {
        "id": "10G-1-3-1-1-1",
        "level": 10,
        "parentId": "20G-1-3-1-1",
        "members": [
            "studentID-241",
            "studentID-242",
            "studentID-243",
            "studentID-244",
            "studentID-245",
            "studentID-246",
            "studentID-247",
            "studentID-248",
            "studentID-249",
            "studentID-250"
        ]
    },
    {
        "id": "10G-1-3-1-1-2",
        "level": 10,
        "parentId": "20G-1-3-1-1",
        "members": [
            "studentID-251",
            "studentID-252",
            "studentID-253",
            "studentID-254",
            "studentID-255",
            "studentID-256",
            "studentID-257",
            "studentID-258",
            "studentID-259",
            "studentID-260"
        ]
    },
    {
        "id": "20G-1-3-1-2",
        "level": 20,
        "parentId": "60G-1-3-1",
        "members": [
            "studentID-261",
            "studentID-262",
            "studentID-263",
            "studentID-264",
            "studentID-265",
            "studentID-266",
            "studentID-267",
            "studentID-268",
            "studentID-269",
            "studentID-270",
            "studentID-271",
            "studentID-272",
            "studentID-273",
            "studentID-274",
            "studentID-275",
            "studentID-276",
            "studentID-277",
            "studentID-278",
            "studentID-279",
            "studentID-280"
        ]
    },
    {
        "id": "10G-1-3-1-2-1",
        "level": 10,
        "parentId": "20G-1-3-1-2",
        "members": [
            "studentID-261",
            "studentID-262",
            "studentID-263",
            "studentID-264",
            "studentID-265",
            "studentID-266",
            "studentID-267",
            "studentID-268",
            "studentID-269",
            "studentID-270"
        ]
    },
    {
        "id": "10G-1-3-1-2-2",
        "level": 10,
        "parentId": "20G-1-3-1-2",
        "members": [
            "studentID-271",
            "studentID-272",
            "studentID-273",
            "studentID-274",
            "studentID-275",
            "studentID-276",
            "studentID-277",
            "studentID-278",
            "studentID-279",
            "studentID-280"
        ]
    },
    {
        "id": "20G-1-3-1-3",
        "level": 20,
        "parentId": "60G-1-3-1",
        "members": [
            "studentID-281",
            "studentID-282",
            "studentID-283",
            "studentID-284",
            "studentID-285",
            "studentID-286",
            "studentID-287",
            "studentID-288",
            "studentID-289",
            "studentID-290",
            "studentID-291",
            "studentID-292",
            "studentID-293",
            "studentID-294",
            "studentID-295",
            "studentID-296",
            "studentID-297",
            "studentID-298",
            "studentID-299",
            "studentID-300"
        ]
    },
    {
        "id": "10G-1-3-1-3-1",
        "level": 10,
        "parentId": "20G-1-3-1-3",
        "members": [
            "studentID-281",
            "studentID-282",
            "studentID-283",
            "studentID-284",
            "studentID-285",
            "studentID-286",
            "studentID-287",
            "studentID-288",
            "studentID-289",
            "studentID-290"
        ]
    },
    {
        "id": "10G-1-3-1-3-2",
        "level": 10,
        "parentId": "20G-1-3-1-3",
        "members": [
            "studentID-291",
            "studentID-292",
            "studentID-293",
            "studentID-294",
            "studentID-295",
            "studentID-296",
            "studentID-297",
            "studentID-298",
            "studentID-299",
            "studentID-300"
        ]
    },
    {
        "id": "12G-1-3-1-1",
        "level": 12,
        "parentId": "60G-1-3-1",
        "members": [
            "studentID-241",
            "studentID-242",
            "studentID-243",
            "studentID-244",
            "studentID-245",
            "studentID-246",
            "studentID-247",
            "studentID-248",
            "studentID-249",
            "studentID-250",
            "studentID-251",
            "studentID-252"
        ]
    },
    {
        "id": "6G-1-3-1-1-1",
        "level": 6,
        "parentId": "12G-1-3-1-1",
        "members": [
            "studentID-241",
            "studentID-242",
            "studentID-243",
            "studentID-244",
            "studentID-245",
            "studentID-246"
        ]
    },
    {
        "id": "3G-1-3-1-1-1-1",
        "level": 3,
        "parentId": "6G-1-3-1-1-1",
        "members": [
            "studentID-241",
            "studentID-242",
            "studentID-243"
        ]
    },
    {
        "id": "3G-1-3-1-1-1-2",
        "level": 3,
        "parentId": "6G-1-3-1-1-1",
        "members": [
            "studentID-244",
            "studentID-245",
            "studentID-246"
        ]
    },
    {
        "id": "6G-1-3-1-1-2",
        "level": 6,
        "parentId": "12G-1-3-1-1",
        "members": [
            "studentID-247",
            "studentID-248",
            "studentID-249",
            "studentID-250",
            "studentID-251",
            "studentID-252"
        ]
    },
    {
        "id": "3G-1-3-1-1-2-1",
        "level": 3,
        "parentId": "6G-1-3-1-1-2",
        "members": [
            "studentID-247",
            "studentID-248",
            "studentID-249"
        ]
    },
    {
        "id": "3G-1-3-1-1-2-2",
        "level": 3,
        "parentId": "6G-1-3-1-1-2",
        "members": [
            "studentID-250",
            "studentID-251",
            "studentID-252"
        ]
    },
    {
        "id": "12G-1-3-1-2",
        "level": 12,
        "parentId": "60G-1-3-1",
        "members": [
            "studentID-253",
            "studentID-254",
            "studentID-255",
            "studentID-256",
            "studentID-257",
            "studentID-258",
            "studentID-259",
            "studentID-260",
            "studentID-261",
            "studentID-262",
            "studentID-263",
            "studentID-264"
        ]
    },
    {
        "id": "6G-1-3-1-2-1",
        "level": 6,
        "parentId": "12G-1-3-1-2",
        "members": [
            "studentID-253",
            "studentID-254",
            "studentID-255",
            "studentID-256",
            "studentID-257",
            "studentID-258"
        ]
    },
    {
        "id": "3G-1-3-1-2-1-1",
        "level": 3,
        "parentId": "6G-1-3-1-2-1",
        "members": [
            "studentID-253",
            "studentID-254",
            "studentID-255"
        ]
    },
    {
        "id": "3G-1-3-1-2-1-2",
        "level": 3,
        "parentId": "6G-1-3-1-2-1",
        "members": [
            "studentID-256",
            "studentID-257",
            "studentID-258"
        ]
    },
    {
        "id": "6G-1-3-1-2-2",
        "level": 6,
        "parentId": "12G-1-3-1-2",
        "members": [
            "studentID-259",
            "studentID-260",
            "studentID-261",
            "studentID-262",
            "studentID-263",
            "studentID-264"
        ]
    },
    {
        "id": "3G-1-3-1-2-2-1",
        "level": 3,
        "parentId": "6G-1-3-1-2-2",
        "members": [
            "studentID-259",
            "studentID-260",
            "studentID-261"
        ]
    },
    {
        "id": "3G-1-3-1-2-2-2",
        "level": 3,
        "parentId": "6G-1-3-1-2-2",
        "members": [
            "studentID-262",
            "studentID-263",
            "studentID-264"
        ]
    },
    {
        "id": "12G-1-3-1-3",
        "level": 12,
        "parentId": "60G-1-3-1",
        "members": [
            "studentID-265",
            "studentID-266",
            "studentID-267",
            "studentID-268",
            "studentID-269",
            "studentID-270",
            "studentID-271",
            "studentID-272",
            "studentID-273",
            "studentID-274",
            "studentID-275",
            "studentID-276"
        ]
    },
    {
        "id": "6G-1-3-1-3-1",
        "level": 6,
        "parentId": "12G-1-3-1-3",
        "members": [
            "studentID-265",
            "studentID-266",
            "studentID-267",
            "studentID-268",
            "studentID-269",
            "studentID-270"
        ]
    },
    {
        "id": "3G-1-3-1-3-1-1",
        "level": 3,
        "parentId": "6G-1-3-1-3-1",
        "members": [
            "studentID-265",
            "studentID-266",
            "studentID-267"
        ]
    },
    {
        "id": "3G-1-3-1-3-1-2",
        "level": 3,
        "parentId": "6G-1-3-1-3-1",
        "members": [
            "studentID-268",
            "studentID-269",
            "studentID-270"
        ]
    },
    {
        "id": "6G-1-3-1-3-2",
        "level": 6,
        "parentId": "12G-1-3-1-3",
        "members": [
            "studentID-271",
            "studentID-272",
            "studentID-273",
            "studentID-274",
            "studentID-275",
            "studentID-276"
        ]
    },
    {
        "id": "3G-1-3-1-3-2-1",
        "level": 3,
        "parentId": "6G-1-3-1-3-2",
        "members": [
            "studentID-271",
            "studentID-272",
            "studentID-273"
        ]
    },
    {
        "id": "3G-1-3-1-3-2-2",
        "level": 3,
        "parentId": "6G-1-3-1-3-2",
        "members": [
            "studentID-274",
            "studentID-275",
            "studentID-276"
        ]
    },
    {
        "id": "12G-1-3-1-4",
        "level": 12,
        "parentId": "60G-1-3-1",
        "members": [
            "studentID-277",
            "studentID-278",
            "studentID-279",
            "studentID-280",
            "studentID-281",
            "studentID-282",
            "studentID-283",
            "studentID-284",
            "studentID-285",
            "studentID-286",
            "studentID-287",
            "studentID-288"
        ]
    },
    {
        "id": "6G-1-3-1-4-1",
        "level": 6,
        "parentId": "12G-1-3-1-4",
        "members": [
            "studentID-277",
            "studentID-278",
            "studentID-279",
            "studentID-280",
            "studentID-281",
            "studentID-282"
        ]
    },
    {
        "id": "3G-1-3-1-4-1-1",
        "level": 3,
        "parentId": "6G-1-3-1-4-1",
        "members": [
            "studentID-277",
            "studentID-278",
            "studentID-279"
        ]
    },
    {
        "id": "3G-1-3-1-4-1-2",
        "level": 3,
        "parentId": "6G-1-3-1-4-1",
        "members": [
            "studentID-280",
            "studentID-281",
            "studentID-282"
        ]
    },
    {
        "id": "6G-1-3-1-4-2",
        "level": 6,
        "parentId": "12G-1-3-1-4",
        "members": [
            "studentID-283",
            "studentID-284",
            "studentID-285",
            "studentID-286",
            "studentID-287",
            "studentID-288"
        ]
    },
    {
        "id": "3G-1-3-1-4-2-1",
        "level": 3,
        "parentId": "6G-1-3-1-4-2",
        "members": [
            "studentID-283",
            "studentID-284",
            "studentID-285"
        ]
    },
    {
        "id": "3G-1-3-1-4-2-2",
        "level": 3,
        "parentId": "6G-1-3-1-4-2",
        "members": [
            "studentID-286",
            "studentID-287",
            "studentID-288"
        ]
    },
    {
        "id": "12G-1-3-1-5",
        "level": 12,
        "parentId": "60G-1-3-1",
        "members": [
            "studentID-289",
            "studentID-290",
            "studentID-291",
            "studentID-292",
            "studentID-293",
            "studentID-294",
            "studentID-295",
            "studentID-296",
            "studentID-297",
            "studentID-298",
            "studentID-299",
            "studentID-300"
        ]
    },
    {
        "id": "6G-1-3-1-5-1",
        "level": 6,
        "parentId": "12G-1-3-1-5",
        "members": [
            "studentID-289",
            "studentID-290",
            "studentID-291",
            "studentID-292",
            "studentID-293",
            "studentID-294"
        ]
    },
    {
        "id": "3G-1-3-1-5-1-1",
        "level": 3,
        "parentId": "6G-1-3-1-5-1",
        "members": [
            "studentID-289",
            "studentID-290",
            "studentID-291"
        ]
    },
    {
        "id": "3G-1-3-1-5-1-2",
        "level": 3,
        "parentId": "6G-1-3-1-5-1",
        "members": [
            "studentID-292",
            "studentID-293",
            "studentID-294"
        ]
    },
    {
        "id": "6G-1-3-1-5-2",
        "level": 6,
        "parentId": "12G-1-3-1-5",
        "members": [
            "studentID-295",
            "studentID-296",
            "studentID-297",
            "studentID-298",
            "studentID-299",
            "studentID-300"
        ]
    },
    {
        "id": "3G-1-3-1-5-2-1",
        "level": 3,
        "parentId": "6G-1-3-1-5-2",
        "members": [
            "studentID-295",
            "studentID-296",
            "studentID-297"
        ]
    },
    {
        "id": "3G-1-3-1-5-2-2",
        "level": 3,
        "parentId": "6G-1-3-1-5-2",
        "members": [
            "studentID-298",
            "studentID-299",
            "studentID-300"
        ]
    },
    {
        "id": "60G-1-3-2",
        "level": 60,
        "parentId": "120G-1-3",
        "members": [
            "studentID-301",
            "studentID-302",
            "studentID-303",
            "studentID-304",
            "studentID-305",
            "studentID-306",
            "studentID-307",
            "studentID-308",
            "studentID-309",
            "studentID-310",
            "studentID-311",
            "studentID-312",
            "studentID-313",
            "studentID-314",
            "studentID-315",
            "studentID-316",
            "studentID-317",
            "studentID-318",
            "studentID-319",
            "studentID-320",
            "studentID-321",
            "studentID-322",
            "studentID-323",
            "studentID-324",
            "studentID-325",
            "studentID-326",
            "studentID-327",
            "studentID-328",
            "studentID-329",
            "studentID-330",
            "studentID-331",
            "studentID-332",
            "studentID-333",
            "studentID-334",
            "studentID-335",
            "studentID-336",
            "studentID-337",
            "studentID-338",
            "studentID-339",
            "studentID-340",
            "studentID-341",
            "studentID-342",
            "studentID-343",
            "studentID-344",
            "studentID-345",
            "studentID-346",
            "studentID-347",
            "studentID-348",
            "studentID-349",
            "studentID-350",
            "studentID-351",
            "studentID-352",
            "studentID-353",
            "studentID-354",
            "studentID-355",
            "studentID-356",
            "studentID-357",
            "studentID-358",
            "studentID-359",
            "studentID-360"
        ]
    },
    {
        "id": "20G-1-3-2-1",
        "level": 20,
        "parentId": "60G-1-3-2",
        "members": [
            "studentID-301",
            "studentID-302",
            "studentID-303",
            "studentID-304",
            "studentID-305",
            "studentID-306",
            "studentID-307",
            "studentID-308",
            "studentID-309",
            "studentID-310",
            "studentID-311",
            "studentID-312",
            "studentID-313",
            "studentID-314",
            "studentID-315",
            "studentID-316",
            "studentID-317",
            "studentID-318",
            "studentID-319",
            "studentID-320"
        ]
    },
    {
        "id": "10G-1-3-2-1-1",
        "level": 10,
        "parentId": "20G-1-3-2-1",
        "members": [
            "studentID-301",
            "studentID-302",
            "studentID-303",
            "studentID-304",
            "studentID-305",
            "studentID-306",
            "studentID-307",
            "studentID-308",
            "studentID-309",
            "studentID-310"
        ]
    },
    {
        "id": "10G-1-3-2-1-2",
        "level": 10,
        "parentId": "20G-1-3-2-1",
        "members": [
            "studentID-311",
            "studentID-312",
            "studentID-313",
            "studentID-314",
            "studentID-315",
            "studentID-316",
            "studentID-317",
            "studentID-318",
            "studentID-319",
            "studentID-320"
        ]
    },
    {
        "id": "20G-1-3-2-2",
        "level": 20,
        "parentId": "60G-1-3-2",
        "members": [
            "studentID-321",
            "studentID-322",
            "studentID-323",
            "studentID-324",
            "studentID-325",
            "studentID-326",
            "studentID-327",
            "studentID-328",
            "studentID-329",
            "studentID-330",
            "studentID-331",
            "studentID-332",
            "studentID-333",
            "studentID-334",
            "studentID-335",
            "studentID-336",
            "studentID-337",
            "studentID-338",
            "studentID-339",
            "studentID-340"
        ]
    },
    {
        "id": "10G-1-3-2-2-1",
        "level": 10,
        "parentId": "20G-1-3-2-2",
        "members": [
            "studentID-321",
            "studentID-322",
            "studentID-323",
            "studentID-324",
            "studentID-325",
            "studentID-326",
            "studentID-327",
            "studentID-328",
            "studentID-329",
            "studentID-330"
        ]
    },
    {
        "id": "10G-1-3-2-2-2",
        "level": 10,
        "parentId": "20G-1-3-2-2",
        "members": [
            "studentID-331",
            "studentID-332",
            "studentID-333",
            "studentID-334",
            "studentID-335",
            "studentID-336",
            "studentID-337",
            "studentID-338",
            "studentID-339",
            "studentID-340"
        ]
    },
    {
        "id": "12G-1-3-2-1",
        "level": 12,
        "parentId": "60G-1-3-2",
        "members": [
            "studentID-301",
            "studentID-302",
            "studentID-303",
            "studentID-304",
            "studentID-305",
            "studentID-306",
            "studentID-307",
            "studentID-308",
            "studentID-309",
            "studentID-310",
            "studentID-311",
            "studentID-312"
        ]
    },
    {
        "id": "6G-1-3-2-1-1",
        "level": 6,
        "parentId": "12G-1-3-2-1",
        "members": [
            "studentID-301",
            "studentID-302",
            "studentID-303",
            "studentID-304",
            "studentID-305",
            "studentID-306"
        ]
    },
    {
        "id": "3G-1-3-2-1-1-1",
        "level": 3,
        "parentId": "6G-1-3-2-1-1",
        "members": [
            "studentID-301",
            "studentID-302",
            "studentID-303"
        ]
    },
    {
        "id": "3G-1-3-2-1-1-2",
        "level": 3,
        "parentId": "6G-1-3-2-1-1",
        "members": [
            "studentID-304",
            "studentID-305",
            "studentID-306"
        ]
    },
    {
        "id": "6G-1-3-2-1-2",
        "level": 6,
        "parentId": "12G-1-3-2-1",
        "members": [
            "studentID-307",
            "studentID-308",
            "studentID-309",
            "studentID-310",
            "studentID-311",
            "studentID-312"
        ]
    },
    {
        "id": "3G-1-3-2-1-2-1",
        "level": 3,
        "parentId": "6G-1-3-2-1-2",
        "members": [
            "studentID-307",
            "studentID-308",
            "studentID-309"
        ]
    },
    {
        "id": "3G-1-3-2-1-2-2",
        "level": 3,
        "parentId": "6G-1-3-2-1-2",
        "members": [
            "studentID-310",
            "studentID-311",
            "studentID-312"
        ]
    },
    {
        "id": "12G-1-3-2-2",
        "level": 12,
        "parentId": "60G-1-3-2",
        "members": [
            "studentID-313",
            "studentID-314",
            "studentID-315",
            "studentID-316",
            "studentID-317",
            "studentID-318",
            "studentID-319",
            "studentID-320",
            "studentID-321",
            "studentID-322",
            "studentID-323",
            "studentID-324"
        ]
    },
    {
        "id": "6G-1-3-2-2-1",
        "level": 6,
        "parentId": "12G-1-3-2-2",
        "members": [
            "studentID-313",
            "studentID-314",
            "studentID-315",
            "studentID-316",
            "studentID-317",
            "studentID-318"
        ]
    },
    {
        "id": "3G-1-3-2-2-1-1",
        "level": 3,
        "parentId": "6G-1-3-2-2-1",
        "members": [
            "studentID-313",
            "studentID-314",
            "studentID-315"
        ]
    },
    {
        "id": "3G-1-3-2-2-1-2",
        "level": 3,
        "parentId": "6G-1-3-2-2-1",
        "members": [
            "studentID-316",
            "studentID-317",
            "studentID-318"
        ]
    },
    {
        "id": "6G-1-3-2-2-2",
        "level": 6,
        "parentId": "12G-1-3-2-2",
        "members": [
            "studentID-319",
            "studentID-320",
            "studentID-321",
            "studentID-322",
            "studentID-323",
            "studentID-324"
        ]
    },
    {
        "id": "3G-1-3-2-2-2-1",
        "level": 3,
        "parentId": "6G-1-3-2-2-2",
        "members": [
            "studentID-319",
            "studentID-320",
            "studentID-321"
        ]
    },
    {
        "id": "3G-1-3-2-2-2-2",
        "level": 3,
        "parentId": "6G-1-3-2-2-2",
        "members": [
            "studentID-322",
            "studentID-323",
            "studentID-324"
        ]
    },
    {
        "id": "12G-1-3-2-3",
        "level": 12,
        "parentId": "60G-1-3-2",
        "members": [
            "studentID-325",
            "studentID-326",
            "studentID-327",
            "studentID-328",
            "studentID-329",
            "studentID-330",
            "studentID-331",
            "studentID-332",
            "studentID-333",
            "studentID-334",
            "studentID-335",
            "studentID-336"
        ]
    },
    {
        "id": "6G-1-3-2-3-1",
        "level": 6,
        "parentId": "12G-1-3-2-3",
        "members": [
            "studentID-325",
            "studentID-326",
            "studentID-327",
            "studentID-328",
            "studentID-329",
            "studentID-330"
        ]
    },
    {
        "id": "3G-1-3-2-3-1-1",
        "level": 3,
        "parentId": "6G-1-3-2-3-1",
        "members": [
            "studentID-325",
            "studentID-326",
            "studentID-327"
        ]
    },
    {
        "id": "3G-1-3-2-3-1-2",
        "level": 3,
        "parentId": "6G-1-3-2-3-1",
        "members": [
            "studentID-328",
            "studentID-329",
            "studentID-330"
        ]
    },
    {
        "id": "6G-1-3-2-3-2",
        "level": 6,
        "parentId": "12G-1-3-2-3",
        "members": [
            "studentID-331",
            "studentID-332",
            "studentID-333",
            "studentID-334",
            "studentID-335",
            "studentID-336"
        ]
    },
    {
        "id": "3G-1-3-2-3-2-1",
        "level": 3,
        "parentId": "6G-1-3-2-3-2",
        "members": [
            "studentID-331",
            "studentID-332",
            "studentID-333"
        ]
    },
    {
        "id": "3G-1-3-2-3-2-2",
        "level": 3,
        "parentId": "6G-1-3-2-3-2",
        "members": [
            "studentID-334",
            "studentID-335",
            "studentID-336"
        ]
    }
];