import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import {collection, getDocs, query, where} from 'firebase/firestore';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './Anforderungen.css';

const customStyle = {
    ...darcula,
    'code[class*="language-"]': {
        ...darcula['code[class*="language-"]'],
        fontSize: '13px',
        fontFamily: 'Monaco, Menlo, "Courier New", monospace'
    },
    'pre[class*="language-"]': {
        ...darcula['pre[class*="language-"]'],
        padding: '20px',
        lineHeight: '1.2',
        overflow: 'auto',
        backgroundColor: '#1F2428',
        borderRadius: '5px'
    }
};

function Anforderungen() {
    const [vorgabenListe, setVorgabenListe] = useState([]);
    const [ausgewaehlteVorgabe, setAusgewaehlteVorgabe] = useState(null);

    // Daten von Firestore laden
    useEffect(() => {
        const loadConstraints = async () => {
            try {
                const vorgabenCol = collection(db, 'constraints');
                const constraintsQuery = query(
                    vorgabenCol,
                    where("deleted", "!=", true)  // Abfrage nach Dokumenten, bei denen 'deleted' nicht 'true' ist
                );
                const vorgabenSnapshot = await getDocs(constraintsQuery);
                const vorgabenArray = vorgabenSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setVorgabenListe(vorgabenArray);
            } catch (error) {
                console.error("Fehler beim Laden der Daten:", error);
            }
        };

        loadConstraints();
    }, []);

    return (
        <>
            <div className="page-content">
                <div className="vorgaben-liste">
                    <ul>
                        {vorgabenListe.map(vorgabe => (
                            <li key={vorgabe.id} onClick={() => setAusgewaehlteVorgabe(vorgabe)}>
                                {vorgabe.name}
                                {vorgabe.isHardConstraint ? <span>Hard</span> :
                                    <span>Soft</span>}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="vorgaben-details">
                    {ausgewaehlteVorgabe ? (
                        <div>
                            <h2>{ausgewaehlteVorgabe.name}</h2>
                            <p><span className="label">Name der Funktion</span>{ausgewaehlteVorgabe.function}</p>
                            <p><span className="label">Beschreibung</span>{ausgewaehlteVorgabe.description}</p>
                            <p><span className="label">Anforderungsart</span>{ausgewaehlteVorgabe.isHardConstraint ? <span>Hard constraint</span> : <span>Soft constraint</span>}
                            </p>
                            <div>
                                <span className="label">Code</span>
                                <SyntaxHighlighter language="javascript" style={customStyle} showLineNumbers>
                                    {ausgewaehlteVorgabe.code}
                                </SyntaxHighlighter>
                            </div>
                        </div>
                    ) : (
                        <p>Wählen Sie eine Vorgabe aus der Liste aus.</p>
                    )}
                </div>
            </div>
        </>
    );
}

export default Anforderungen;
