// processControlling.js
import { dbRT, push, ref, serverTimestamp, set } from '../firebase';
import {get} from "firebase/database";

function initializeNewProcess() {
    const newProcessId = `process-${Date.now()}`;
    const logsRef = ref(dbRT, `processes/${newProcessId}/logs`);
    const progressRef = ref(dbRT, `processes/${newProcessId}/progress`);
    const processedEventsRef = ref(dbRT, `processes/${newProcessId}/processedEvents`);

    // Datenbank-Initialisierung
    const initPromises = [
        set(logsRef, []), // Log initialisieren
        set(progressRef, { value: 0 }), // Fortschritt initialisieren
        set(processedEventsRef, {}) // Anzahl verarbeiteter Termine initialisieren
    ];

    return Promise.all(initPromises).then(() => newProcessId);
}

function logMessage(processId, message) {
    const logsRef = ref(dbRT, `processes/${processId}/logs`);
    return push(logsRef, {
        message,
        timestamp: serverTimestamp()
    });
}

function updateProgress(processId, currentPercentage) {
    const progressRef = ref(dbRT, `processes/${processId}/progress`);
    return set(progressRef, { value: currentPercentage });
}

async function stopProcess(processId) {
    if (!processId) {
        console.error("stopProcess wurde ohne processId aufgerufen");
        return;
    }
    const stoppedRef = ref(dbRT, `processes/${processId}/stopped`);
    try {
        await set(stoppedRef, true);
        console.log("Prozess erfolgreich gestoppt.");
    } catch (error) {
        console.error("Fehler beim Setzen des Stop-Flags:", error);
    }
}

function checkIfStopped(processId) {
    const stoppedRef = ref(dbRT, `processes/${processId}/stopped`);
    return get(stoppedRef).then(snapshot => snapshot.val() === true);
}

export { initializeNewProcess, logMessage, updateProgress, stopProcess, checkIfStopped };
