import { groups } from '../groups.js';

export default function isStudentInGroup(studentId, groupId) {
    // Suche die Gruppe, die der groupId entspricht
    const group = groups.find(g => g.id === groupId);

    // Spezialfall für "1G" Präfix in der groupId
    if (groupId.startsWith('1G')) {
        const groupNumber = groupId.split('1G-')[1];
        const studentNumber = studentId.split('studentID-')[1];
        if (groupNumber === studentNumber) {
            return true;
        }
    }

    // Überprüfe, ob der studentId im Mitgliederarray der gefundenen Gruppe ist
    if (group && group.members.includes(studentId)) {
        return true;
    }

    // Prüfe Untergruppen nur, wenn eine Hauptgruppe gefunden wurde
    if (group) {
        const subGroups = groups.filter(g => g.parentId === groupId);
        for (let subGroup of subGroups) {
            if (isStudentInGroup(studentId, subGroup.id)) {
                return true;
            }
        }
    }

    return false;
}
