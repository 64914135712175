export const CloseLineIcon = ({color = 'rgba(31, 36, 40, 1)', size = '24px'}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={color} style={{ width: size, height: size }}>
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path
            d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path>
    </svg>
);

export const MenuLineIcon = ({color = 'rgba(31, 36, 40, 1)', size = '24px'}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={color} style={{ width: size, height: size }}>
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path d="M3 4H21V6H3V4ZM3 11H21V13H3V11ZM3 18H21V20H3V18Z"></path>
    </svg>
);

export const CheckboxCircleFill = ({color = 'rgba(31, 36, 40, 1)', size = '24px'}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={color} style={{ width: size, height: size }}>
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path
            d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z"></path>
    </svg>
);

export const ErrorWarningFill = ({color = 'rgba(31, 36, 40, 1)', size = '24px'}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={color} style={{ width: size, height: size }}>
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path
            d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM11 7V13H13V7H11Z"></path>
    </svg>
);
