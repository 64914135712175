import React from 'react';
import {
    countEventsPerDay,
    getWorkloadHeatmapColor
} from "../../../utilities/analyse/analyseUtilityFunctions";
import '../../Analyse/Analyse.css';

function HeatmapLegend() {
    const legendItems = [
        { min: 1, max: 2, label: "1 bis 2 Termine" },
        { min: 3, max: 4, label: "3 bis 4 Termine" },
        { min: 5, max: 6, label: "5 bis 6 Termine" },
        { min: 7, max: 8, label: "7 bis 8 Termine" },
        { min: 9, max: 10, label: "9 bis 10 Termine" },
        { min: 11, max: 12, label: "11 bis 12 Termine" },
        { min: 13, max: 14, label: "13 bis 14 Termine" },
        { min: 15, label: "15 und mehr Termine" }
    ];

    return (
        <div className="heatmap-legend">
            {legendItems.map((item, index) => (
                <div key={index} className="legend-item">
                    <div className={`legend-color ${getWorkloadHeatmapColor(item.min)}`}></div>
                    <div className="legend-label">{item.label}</div>
                </div>
            ))}
        </div>
    );
}

function YearlySubjectWorkloadHeatmap({subject, startDate, endDate, allEvents, title, loading}) {

    const dataList = countEventsPerDay(subject, startDate, endDate, allEvents);
    const data = dataList.reduce((acc, item) => {
        acc[item.date] = item.value;
        return acc;
    }, {});

    const months = [];
    let currentMonthStart = new Date(startDate);
    currentMonthStart.setDate(1);

    while (currentMonthStart <= new Date(endDate)) {
        months.push(new Date(currentMonthStart));
        currentMonthStart = new Date(currentMonthStart.getFullYear(), currentMonthStart.getMonth() + 1, 1);
    }

    const daysByMonth = months.map(monthStart => {
        let days = [];
        let day = new Date(monthStart);
        let endOfMonth = new Date(day.getFullYear(), day.getMonth() + 1, 0);
        let firstDayAdjusted = (day.getDay() + 6) % 7;

        while (day <= endOfMonth) {
            const formattedDate = day.toLocaleDateString('en-CA');
            const value = data[formattedDate] || 0;
            days.push({
                date: formattedDate,
                value,
                colorClass: getWorkloadHeatmapColor(value),
                weekday: (day.getDay() + 6) % 7,
                weekNumber: Math.floor(((day.getDate() - 1) + firstDayAdjusted) / 7),
                dayOfMonth: day.getDate()
            });
            day.setDate(day.getDate() + 1);
        }

        return {
            month: monthStart.toLocaleString('default', { month: 'long', year: 'numeric' }),
            days
        };
    });

    const daySize = 20;
    const daySpacing = 3;
    const monthSpacing = 30;
    const monthsPerRow = 2; // Anzahl der Monate pro Zeile
    const columnWidth = 7 * (daySize + daySpacing) + monthSpacing; // Breite für jede Monatsspalte
    const svgWidth = columnWidth * monthsPerRow - monthSpacing; // SVG-Breite anpassen, um zwei Monate pro Zeile zu passen
    const rowHeight = (5 * (daySize + daySpacing)) + 75; // Höhe für jede Monatszeile einschließlich Label
    const totalRows = Math.ceil(months.length / monthsPerRow);
    const svgHeight = totalRows * rowHeight - 10; // Gesamte SVG-Höhe basierend auf der Anzahl der Zeilen

    return (
        <>
            <h2>{title}</h2>
            <p>
                Die Abbildung zeigt, wie viele Termine pro Tag innerhalb des Fachs {subject} stattfinden.
            </p>
            <div className="calendar-heatmap-container">
            {loading ? <p>Lade Daten...</p> : (
                <>
                    <svg width={svgWidth} height={svgHeight} className="calendar-heatmap">
                        {daysByMonth.map((month, monthIndex) => {
                            const column = monthIndex % monthsPerRow;
                            const row = Math.floor(monthIndex / monthsPerRow);
                            return (
                                <g key={`month-${month.month}`}>
                                    <text
                                        x={column * columnWidth}
                                        y={row * rowHeight} // Position the month label at the top of each month block
                                        dominantBaseline='text-before-edge'
                                        style={{fontSize: '13px', fill: 'rgba(49, 56, 61, 1)', fontWeight: '500'}}
                                    >
                                        {month.month}
                                    </text>
                                    <line
                                        x1={column * columnWidth}
                                        y1={row * rowHeight + 25}  // Line slightly below the label
                                        x2={column * columnWidth + columnWidth - monthSpacing - 3} // End of the line
                                        y2={row * rowHeight + 25} // Same y to make it horizontal
                                        stroke="rgba(49, 56, 61, 0.2)" // Line color
                                        strokeWidth="1" // Line width
                                    />
                                    {month.days.map(day => (
                                        <g
                                            key={day.date}
                                            data-tooltip-id="tooltip"
                                            data-tooltip-html={`${day.dayOfMonth}. ${month.month}<br />${day.value.toFixed(0)} Termine`}
                                        >
                                            <rect
                                                x={column * columnWidth + day.weekday * (daySize + daySpacing)}
                                                y={row * rowHeight + 40 + day.weekNumber * (daySize + daySpacing)} // Offset y um 30 für das Label
                                                width={daySize}
                                                height={daySize}
                                                className={day.colorClass}
                                                rx="5"
                                                ry="5"
                                            />
                                            <text
                                                x={column * columnWidth + day.weekday * (daySize + daySpacing) + 10} // Center text in box
                                                y={row * rowHeight + 51 + day.weekNumber * (daySize + daySpacing)} // Adjust text position
                                                style={{fontSize: '9px', fill: 'rgba(49, 56, 61, 1)'}}
                                                textAnchor='middle'
                                                dominantBaseline='middle'
                                            >
                                                {day.dayOfMonth}
                                            </text>
                                        </g>
                                    ))}
                                </g>
                            );
                        })}
                    </svg>
                </>
            )}
            </div>
            <HeatmapLegend/>
        </>
    );
}

export default YearlySubjectWorkloadHeatmap;
