import React, { useEffect, useState } from 'react';
import { db } from '../../../firebase';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';

function SubjectSelector({ solutionId, onSelect }) {
    const [subjectList, setSubjectList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!solutionId) return; // Frühes Beenden, wenn keine solutionId vorliegt

        let isMounted = true;

        async function fetchSubjectsList() {
            setIsLoading(true);
            try {
                // Zugriff auf das Dokument der Lösung
                const solutionRef = doc(db, 'solutions', solutionId);
                const solutionSnap = await getDoc(solutionRef);

                if (!solutionSnap.exists()) {
                    throw new Error('No such solution exists!');
                }

                const solutionData = solutionSnap.data();
                const sourceDataRef = solutionData.sourceDataRef;

                // Abfragen der 'subjects' Untersammlung von `sourceDataRef`
                const subjectsSnapshot = await getDocs(collection(sourceDataRef, 'subjects'));
                let subjects = subjectsSnapshot.docs.map(doc => ({
                    id: doc.id,
                    name: doc.data().name // oder jede andere relevante Datenstruktur
                }));

                // Sortiere die Fächer alphabetisch und kürze den Namen, falls nötig
                subjects.sort((a, b) => a.name.localeCompare(b.name));
                subjects = subjects.map(subject => ({
                    id: subject.id,
                    name: subject.name.length > 30 ? `${subject.name.substring(0, 30)} ...` : subject.name
                }));

                if (isMounted) {
                    setSubjectList(subjects);
                }
            } catch (error) {
                if (isMounted) {
                    console.error("Fehler beim Laden der Fächerliste: ", error);
                }
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        }

        fetchSubjectsList();

        return () => {
            isMounted = false;
        };
    }, [solutionId]); // Nur ausführen, wenn sich `solutionId` ändert

    return (
        <div>
            <label htmlFor="subjectSelect">Fach</label>
            <select id="subjectSelect" onChange={e => {
                const selectedOption = e.target.options[e.target.selectedIndex];
                onSelect(selectedOption.value, selectedOption.text);
            }} disabled={isLoading || !solutionId || !subjectList.length}>
                <option value="">{!solutionId ? 'Bitte erst Stundenplanlösung wählen' : 'Bitte wählen ...'}</option>
                {subjectList.map(subject => (
                    <option key={subject.id} value={subject.id}>{subject.name}</option>
                ))}
            </select>
        </div>
    );
}

export default SubjectSelector;
