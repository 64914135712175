import {groups} from "../../groups";

export default function getSupGroup(groupId, groupSize) {
    // Funktion, um eine Gruppe anhand ihrer ID zu finden
    function findGroupById(id) {
        return groups.find(group => group.id === id);
    }

    // Funktion, um eine Gruppe anhand der Bedingungen für "1G-" Gruppen zu finden
    function findSpecialGroup(suffix, sizePrefix) {
        return groups.find(group =>
            group.id.startsWith(sizePrefix) &&
            group.members.some(member => member.endsWith(suffix))
        );
    }

    // Überprüfe, ob groupId mit "1G" oder "1G-" beginnt
    if (groupId.startsWith("1G")) {
        const suffix = groupId.split('-').pop(); // Letzter Teil nach dem letzten '-'
        const sizePrefix = `${groupSize}G-`; // Präfix zusammensetzen
        const specialGroup = findSpecialGroup(suffix, sizePrefix);

        if (specialGroup) {
            return specialGroup.id; // Gibt die ID der gefundenen Gruppe zurück
        } else {
            // console.error('Keine passende Gruppe gefunden.');
            return null;
        }
    }

    // Beginne mit der gegebenen Gruppe
    let currentGroup = findGroupById(groupId);

    // Überprüfe, ob Gruppe gefunden wurde
    if (!currentGroup) {
        // console.error('Gruppe mit der ID ' + groupId + ' wurde nicht gefunden.');
        return null;
    }

    // Navigiere in Hierarchie nach oben, bis Gruppe der gewünschten Größe gefunden wird
    while (currentGroup && currentGroup.level !== groupSize) {
        if (currentGroup.parentId) {
            currentGroup = findGroupById(currentGroup.parentId);
        } else {
            // Keine weiteren parentIds, Ende der Suche
            // console.error('Keine übergeordnete Gruppe der Größe ' + groupSize + ' gefunden.');
            return null;
        }
    }

    return currentGroup.id;
}