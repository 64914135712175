import {db} from '../firebase';
import {collection, getDocs} from 'firebase/firestore';

export const loadDistanceData = async (globalDistances, selectedSourceId) => {
    if (globalDistances === null) {
        const distancesCollection = collection(db, `/sourceData/${selectedSourceId}/distances`);
        const querySnapshot = await getDocs(distancesCollection);
        globalDistances = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        return globalDistances;
    }
}

// Funktion zum Abrufen der Reisedauer zwischen zwei Gebäuden
export const getTravelTime = (buildingA, buildingB, globalDistances) => {
    const distance = globalDistances.find(doc =>
        (doc.roomsBuildingA.includes(buildingA) && doc.roomsBuildingB.includes(buildingB)) ||
        (doc.roomsBuildingA.includes(buildingB) && doc.roomsBuildingB.includes(buildingA))
    );
    return distance;
    // return distance ? distance.travelDurationByPublicTransport : 0; // oder je nach Anforderung andere Verkehrsmittel
}
