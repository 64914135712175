const config = {
    timeZone: 'Europe/Berlin',
    studySections: {
        'I': {
            studyYears: {
                1: [1, 2],
                2: [3, 4],
                3: [5, 6]
            },
        },
        'II': {
            studyYears: {
                4: [7, 8],
                5: [9, 10]
            }
        }
    },
    workHours: {
        startHour: 8,  // 08:00 Uhr morgens als Dezimalzahl
        endHour: 20    // 20:00 Uhr abends
    },
    workHoursClinic: {
        startHour: 8,  // 08:00 Uhr morgens als Dezimalzahl
        endHour: 16    // 16:00 Uhr abends
    },
    lecturePeriods: {
        'lecturePeriodWinter': { // Vorlesungszeiten Wintersemester
            title: 'Vorlesungszeit Wintersemester',
            semesters: [1, 3, 5, 7, 9],
            startDate: '2025-10-06',
            endDate: '2026-01-31'
        },
        'lecturePeriodSummer': { // Vorlesungszeiten Sommersemester
            title: 'Vorlesungszeit Sommersemester',
            semesters: [2, 4, 6, 8, 10],
            startDate: '2026-04-13',
            endDate: '2026-07-24'
        }
    },
    christmasBreak: { // Weihnachtspause vom Lehrbetrieb
        title: 'Weihnachtspause vom Lehrbetrieb',
        startDate: '2025-12-20', // erster Tag der Pause
        endDate: '2026-01-04' // letzter Tag der Pause
    },
    studentsPerYear: 336,  // Anzahl der Studierenden pro Jahr
    airtableURLs: {
        interfaceBaseURL: 'https://airtable.com/appwn3Z3agbThrRe5/pagkjSTUc0SZw2Jrt',
        interfaceConstraintsURL: 'https://airtable.com/appwn3Z3agbThrRe5/pag1CspRXpGwf6FOy'
    }
};

export default config;
