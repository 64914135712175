import React from 'react';
import { Outlet, NavLink } from 'react-router-dom';

function Analyse() {

    return (
        <>
            <div className="subnav">
                <NavLink to="/analyse" end className={({isActive}) => isActive ? 'active' : ''}>Übersicht</NavLink>
                <NavLink to="/analyse/kostenvergleich"
                         className={({isActive}) => isActive ? 'active' : ''}>Kostenvergleich</NavLink>
                <NavLink to="/analyse/fachbezogen"
                         className={({isActive}) => isActive ? 'active' : ''}>Fachbezogen</NavLink>
                <NavLink to="/analyse/validierung"
                         className={({isActive}) => isActive ? 'active' : ''}>Validierung</NavLink>
            </div>
            <Outlet/>
        </>
    );

}

export default Analyse;
