// Konfiguration der Studienmodule
export const moduleConfigs = {
    // Allgemeines: F1 bis F6 im 10. Semester auf jeden Fall frei
    "5-II-MED1": {
        // 9. oder 10. Semester
        // 5 Wochen am Stück (also X1...5)
        // immer nur eine 60er-Gruppe gleichzeitig, Ausnahme: Kombination mit 60G-1-3-2 möglich
        "60G-1-1-1": [
            { semester: 9, weekRange: { start: 'A1', end: 'A5' } }
        ],
        "60G-1-1-2": [
            { semester: 9, weekRange: { start: 'B1', end: 'B5' } }
        ],
        "60G-1-2-1": [
            { semester: 9, weekRange: { start: 'C1', end: 'C5' } }
        ],
        "60G-1-2-2": [
            { semester: 10, weekRange: { start: 'D1', end: 'D5' } }
        ],
        "60G-1-3-1": [
            { semester: 10, weekRange: { start: 'E1', end: 'E5' } }
        ],
        "60G-1-3-2": [
            { semester: 9, weekRange: { start: 'A1', end: 'A5' } }
        ]
    },
    "5-II-MED2": {
        // 9. oder 10. Semester
        // 5 Wochen am Stück (also X1...5)
        // immer nur eine 60er-Gruppe gleichzeitig, Ausnahme: Kombination mit 60G-1-3-2 möglich
        "60G-1-1-1": [
            { semester: 9, weekRange: { start: 'B1', end: 'B5' } }
        ],
        "60G-1-1-2": [
            { semester: 9, weekRange: { start: 'C1', end: 'C5' } }
        ],
        "60G-1-2-1": [
            { semester: 10, weekRange: { start: 'D1', end: 'D5' } }
        ],
        "60G-1-2-2": [
            { semester: 10, weekRange: { start: 'E1', end: 'E5' } }
        ],
        "60G-1-3-1": [
            { semester: 9, weekRange: { start: 'A1', end: 'A5' } }
        ],
        "60G-1-3-2": [
            { semester: 9, weekRange: { start: 'B1', end: 'B5' } }
        ],
    },
    "5-II-MED3": {
        // 9. oder 10. Semester
        // 5 Wochen am Stück (also X1...5)
        // immer nur eine 60er-Gruppe gleichzeitig, Ausnahme: Kombination mit 60G-1-3-2 möglich
        "60G-1-1-1": [
            { semester: 9, weekRange: { start: 'C1', end: 'C5' } }
        ],
        "60G-1-1-2": [
            { semester: 10, weekRange: { start: 'D1', end: 'D5' } }
        ],
        "60G-1-2-1": [
            { semester: 10, weekRange: { start: 'E1', end: 'E5' } }
        ],
        "60G-1-2-2": [
            { semester: 9, weekRange: { start: 'A1', end: 'A5' } }
        ],
        "60G-1-3-1": [
            { semester: 9, weekRange: { start: 'B1', end: 'B5' } }
        ],
        "60G-1-3-2": [
            { semester: 9, weekRange: { start: 'C1', end: 'C5' } }
        ],
    },
    "5-II-MED4": {
        // 9. oder 10. Semester
        // 5 Wochen am Stück (also X1...5)
        // immer nur eine 60er-Gruppe gleichzeitig, Ausnahme: Kombination mit 60G-1-3-2 möglich
        "60G-1-1-1": [
            { semester: 10, weekRange: { start: 'D1', end: 'D5' } }
        ],
        "60G-1-1-2": [
            { semester: 10, weekRange: { start: 'E1', end: 'E5' } }
        ],
        "60G-1-2-1": [
            { semester: 9, weekRange: { start: 'A1', end: 'A5' } }
        ],
        "60G-1-2-2": [
            { semester: 9, weekRange: { start: 'B1', end: 'B5' } }
        ],
        "60G-1-3-1": [
            { semester: 9, weekRange: { start: 'C1', end: 'C5' } }
        ],
        "60G-1-3-2": [
            { semester: 10, weekRange: { start: 'D1', end: 'D5' } }
        ],
    },
    "5-II-MED5": {
        // 7. oder 8. Semester
        // 5 Wochen am Stück (also X1...5)
        // immer nur eine 60er-Gruppe gleichzeitig, Ausnahme: Kombination mit 60G-1-3-2 möglich
        "60G-1-1-1": [
            { semester: 8, weekRange: { start: 'D1', end: 'D5' } }
        ],
        "60G-1-1-2": [
            { semester: 8, weekRange: { start: 'E1', end: 'E5' } }
        ],
        "60G-1-2-1": [
            { semester: 8, weekRange: { start: 'F1', end: 'F5' } }
        ],
        "60G-1-2-2": [
            { semester: 7, weekRange: { start: 'A1', end: 'A5' } }
        ],
        "60G-1-3-1": [
            { semester: 7, weekRange: { start: 'B1', end: 'B5' } }
        ],
        "60G-1-3-2": [
            { semester: 7, weekRange: { start: 'C1', end: 'C5' } }
        ],
    },
    "5-II-MED6": {
        // 7. oder 8. Semester
        // 5 Wochen am Stück (also X1...5)
        // immer nur eine 60er-Gruppe gleichzeitig, Ausnahme: Kombination mit 60G-1-3-2 möglich
        "60G-1-1-1": [
            { semester: 8, weekRange: { start: 'E1', end: 'E5' } }
        ],
        "60G-1-1-2": [
            { semester: 8, weekRange: { start: 'F1', end: 'F5' } }
        ],
        "60G-1-2-1": [
            { semester: 8, weekRange: { start: 'D1', end: 'D5' } }
        ],
        "60G-1-2-2": [
            { semester: 7, weekRange: { start: 'B1', end: 'B5' } }
        ],
        "60G-1-3-1": [
            { semester: 7, weekRange: { start: 'C1', end: 'C5' } }
        ],
        "60G-1-3-2": [
            { semester: 7, weekRange: { start: 'A1', end: 'A5' } }
        ],
    },
    "5-II-MEDBP": {
        "60G-1-1-1": [
            { semester: 7, weekRange: { start: 'A1', end: 'A3' } },
            { semester: 8, weekRange: { start: 'F1', end: 'F5' } },
        ],
        "60G-1-1-2": [
            { semester: 7, weekRange: { start: 'A4', end: 'B1' } },
            { semester: 8, weekRange: { start: 'D1', end: 'D5' } },
        ],
        "60G-1-2-1": [
            { semester: 7, weekRange: { start: 'C3', end: 'C5' } },
            { semester: 8, weekRange: { start: 'E1', end: 'E5' } },
        ],
        "60G-1-2-2": [
            { semester: 7, weekRange: { start: 'C1', end: 'C5' } },
            { semester: 8, weekRange: { start: 'D1', end: 'D3' } },
        ],
        "60G-1-3-1": [
            { semester: 7, weekRange: { start: 'A1', end: 'A5' } },
            { semester: 8, weekRange: { start: 'D4', end: 'E1' } },
        ],
        "60G-1-3-2": [
            { semester: 7, weekRange: { start: 'B1', end: 'B5' } },
            { semester: 8, weekRange: { start: 'F3', end: 'F3' } },
        ],
    },
    "5-II-MEDW": {
        // 7. oder 8. Semester
        // wenn möglich 12 Wochen am Stück, sonst aufgeteilt in 3 Wochen und 9 Wochen am Stück
        // liegt vor 5-II-MEDBP, nach 5-II-MEDBP oder umschließt 5-II-MEDBP
        // !!! darf auch an Feiertagen platziert werden !!!
        "60G-1-1-1": [
            { semester: 7, weekRange: { start: 'A4', end: 'C5' } },
        ],
        "60G-1-1-2": [
            { semester: 7, weekRange: { start: 'A1', end: 'A3' } },
            { semester: 7, weekRange: { start: 'B2', end: 'C5' } },
        ],
        "60G-1-2-1": [
            { semester: 7, weekRange: { start: 'A1', end: 'C2' } },
        ],
        "60G-1-2-2": [
            { semester: 8, weekRange: { start: 'D4', end: 'F5' } },
        ],
        "60G-1-3-1": [
            { semester: 8, weekRange: { start: 'D1', end: 'D3' } },
            { semester: 8, weekRange: { start: 'E2', end: 'F5' } },
        ],
        "60G-1-3-2": [
            { semester: 8, weekRange: { start: 'D1', end: 'F2' } },
        ],
    },
};