// Planungskonfiguration als Grundlage für Generierung der Anfangslösung ergänzen
import { getAcademicGridDate } from "../utilityFunctions";
import getSupGroup from "./getSupGroup";
import { moduleConfigs } from "./config/configModules";
import config from "../../config";

//
// Hauptfunktion
//
export const addSchedulingConfig = (course, medv1Rotation) => {

    // Wenn die Veranstaltung nicht terminiert werden soll, frühzeitig zurückkehren
    if (course.isNonScheduledCourse) {
        return course;
    }

    // Versuche zuerst, eine spezifische Scheduling Config zu erhalten
    let specificConfigApplied = getSpecificSchedulingConfig(course, medv1Rotation);

    // Wenn spezifische Config erfolgreich, nur noch fehlende Config-Daten ergänzen
    if (specificConfigApplied) {
        const standardConfigs = getStandardSchedulingConfig(course.semesterOptions);
        if (standardConfigs) {
            course.eventGroups.forEach(eventGroup => {
                eventGroup.events.forEach(event => {
                    if (!event.extendedProps.schedulingConfig || event.extendedProps.schedulingConfig.length === 0) {
                        event.extendedProps.schedulingConfig = standardConfigs;
                    }
                    else {
                        // Merge spezifische Konfiguration mit der Standardkonfiguration
                        event.extendedProps.schedulingConfig = event.extendedProps.schedulingConfig.map(specificConfig =>
                            mergeConfigs(specificConfig, standardConfigs[0])
                        );
                    }
                });
            });
        }
        console.log('specificConfigApplied: ', course);
        return course;
    }

    // Versuche, eine modulbasierte Scheduling Config zu erhalten
    getModuleBasedSchedulingConfig(course);

    // Überprüfe, ob die Events bereits Scheduling Configs haben
    let hasSchedulingConfigs = course.eventGroups.some(eventGroup =>
        eventGroup.events.some(event =>
            event.extendedProps.schedulingConfig && event.extendedProps.schedulingConfig.length > 0
        )
    );

    // Wenn keine Scheduling Config gefunden wurde, benutze die Standardlogik
    const standardConfigs = getStandardSchedulingConfig(course.semesterOptions);
    if (!hasSchedulingConfigs && standardConfigs) {
        course.eventGroups.forEach(eventGroup => {
            eventGroup.events.forEach(event => {
                event.extendedProps.schedulingConfig = standardConfigs;
            });
        });
    } else if (!hasSchedulingConfigs) {
        console.warn("Keine Planungskonfiguration festgelegt für:", course);
    }

    return course;
};


//
// Hilfsfunktionen
//

// Regeln für spezifische Planungskonfigurationen
const schedulingRules = new Map([
    ["Anatomie_Praktikum", [{ // Praktikum Anatomie (Histologiekurs) in Histologiesaal
        roomOptions: ['recP8K2M2BbzKaa2h']
    }]],
    ["Seminar_Allgemeinmedizin", [{ // Allgemeinmedizin: Seminare mittwochs oder freitags
        excludeDays: [0,1,2,4,6],
        earliestHour: 14
    }]],
    ["UaKD_Chirurgie (Orthopädie / Unfallchirurgie)", [{ // UaKD mind. 2 Tage nach Prüfung MEDEINF T2
        earliestDate: new Date('2025-11-05'),
    }]],
    ["UaKD_Dermatologie, Venerologie", [{ // UaKD mind. 2 Tage nach Prüfung MEDEINF T2
        earliestDate: new Date('2025-11-05'),
    }]],
]);

function getSpecificSchedulingConfig(course, medv1Rotation = [3, 4, 5]) {

    const key = `${course.courseType}_${course.subject}`;
    let schedulingConfig = schedulingRules.get(key); // Hole die allgemeine Konfiguration

    // Modulweite Konfigurationen basierend auf course.module
    switch (course.module) {
        case "5-I-MEDEINF": // Einführungsmodul in A1-A2
            schedulingConfig = [{
                earliestDate: getAcademicGridDate({weekName: 'A1', type: 'earliest'}).toISOString(),
                latestDate: getAcademicGridDate({weekName: 'A2', type: 'latest'}).toISOString(),
            }];
            break;
        case "5-I-MED1": //
            schedulingConfig = [{
                earliestDate: getAcademicGridDate({weekName: 'A3', type: 'earliest'}).toISOString(),
                latestDate: getAcademicGridDate({weekName: 'C5', type: 'latest'}).toISOString(),
            }];
            break;
    }

    // Spezifische Konfigurationen basierend auf course.id
    switch (course.id) {
        /*
        // Allgemeinmedizin: bestimmte Vorlesungen mittwochs oder freitags
         */
        case "recG5mHCa1AQU0cum": // Vorlesung Allgemeinmedizin 5-I-MED2
        case "recUawGn2NFZK0h5d": // Vorlesung Allgemeinmedizin 5-I-MED4
        case "rec5xKyVwNNgzVq9I": // Vorlesung Allgemeinmedizin 5-I-MED3
        case "reccIexMJKenDZAyE": // Vorlesung Allgemeinmedizin 5-I-MED5
            schedulingConfig = [{
                excludeDays: [0,1,2,4,6],
                earliestHour: 14
            }];
            break;
        /*
        // Erste-Hilfe-Kurs im 1. FS an ersten beiden Freitagen und Samstagen
        */
        case "recz0EqQH5tNLE8ZY": //
            schedulingConfig = [{
                earliestDate: getAcademicGridDate({weekName: 'A1', type: 'earliest'}).toISOString(),
                latestDate: getAcademicGridDate({weekName: 'A3', type: 'latest'}).toISOString(),
                excludeDays: [0,1,2,3,4],
                mandatoryStartHour: 8,
                frequency: null
            }];
            break;
        /*
        // Modul 5-I-MED1: Kein Start Laborpraktika vor 4. Woche
        */
        case "recahFChsfwIVJRXo": // Laborpraktikum Physik
        case "reccvpZ498OjutelH": // Laborpraktikum Chemie
        case "recPniQB3dhP8ScHN": // Laborpraktikum Physiologie und Pathophysiologie
        case "rec3qfLBFcrgEYAcG": // Laborpraktikum Biologie
            schedulingConfig = [{
                earliestDate: getAcademicGridDate({weekName: 'A4', type: 'earliest'}).toISOString()
            }];
            break;
        /*
        // Rotation 5-I-MEDV1
        */
        case "recI4SyTr6mFNYk26": // Vorlesung Digitale Medizin Bielefelder Schwerpunkt 2
        case "recIAViQCa20FDFIs": // Seminar Digitale Medizin Bielefelder Schwerpunkt 2
        case "rec6RMymCK5LrzZIG": // Vorlesung Digitale Medizin (Medizinische Assistenzsysteme) Bielefelder Schwerpunkt 2
        case "reci9fAMmuf3RWXZk": // Seminar Digitale Medizin (Medizinische Assistenzsysteme) Bielefelder Schwerpunkt 2
            schedulingConfig = getStandardSchedulingConfig(medv1Rotation[0]);
            break;
        case "rec8unFDI2DaCmgnr": // Vorlesung Gendermedizin Bielefelder Schwerpunkt 3
        case "recqnczaTH4eLWB73": // Seminar Gendermedizin Bielefelder Schwerpunkt 3
            schedulingConfig = getStandardSchedulingConfig(medv1Rotation[1]);
            break;
        case "recA4L93UADg87VDo": // Seminar Interprofessionalität Bielefelder Schwerpunkt 1
        case "recN0EOkei6eeJ145": // Vorlesung Interprofessionalität Bielefelder Schwerpunkt 1
            schedulingConfig = getStandardSchedulingConfig(medv1Rotation[2]);
            break;
        default:
            if (!schedulingConfig) {
                return false; // Wenn keine Konfiguration gefunden wurde
            }
            break;
    }

    // Anwendung der Konfiguration auf alle Events in allen Eventgruppen
    course.eventGroups.forEach(eventGroup => {
        eventGroup.events.forEach(event => {
            event.extendedProps.schedulingConfig = schedulingConfig;
        });
    });

    return true;
}


function getModuleBasedSchedulingConfig(course) {
    const moduleConfig = moduleConfigs[course.module];
    if (!moduleConfig) return;

    course.eventGroups.forEach(eventGroup => {
        eventGroup.events.forEach(event => {
            let eventSpecificConfigs = [];  // Array um Konfigurationen spezifisch für dieses Event zu sammeln
            const group = getSupGroup(event.extendedProps.group, 60);
            const groupConfigs = moduleConfig[group];

            if (groupConfigs && Array.isArray(groupConfigs)) {
                groupConfigs.forEach(config => {
                    const { semester, weekRange } = config;
                    const schedulingConfig = {
                        semester: semester,
                        earliestDate: getAcademicGridDate({weekName: weekRange.start, type: 'earliest'}).toISOString(),
                        latestDate: new Date(getAcademicGridDate({weekName: weekRange.end, type: 'latest'})),
                        roomOptions: []
                    };
                    schedulingConfig.latestDate.setHours(23, 59, 59, 999);
                    schedulingConfig.latestDate = schedulingConfig.latestDate.toISOString();
                    eventSpecificConfigs.push(schedulingConfig);
                });
            }
            event.extendedProps.schedulingConfig = eventSpecificConfigs;  // Weise die spezifischen Konfigurationen direkt dem Event zu
        });
    });
}

function getSeason(semester) {
    for (const [key, value] of Object.entries(config.lecturePeriods)) {
        if (value.semesters.includes(semester)) {
            return key.includes("Winter") ? "winter" : "summer";
        }
    }
    return "winter"; // Fallback, falls nicht gefunden
}

function getStandardSchedulingConfig(semesterOptions) {
    if (semesterOptions.length !== 1) {
        console.warn("Kein eindeutiges Semester für die Planungskonfiguration verfügbar.");
        return null;
    }
    const frequency = 'weekly';
    const semester = semesterOptions[0];
    const season = getSeason(semester);
    const latestDate = new Date(getAcademicGridDate({ season: season, type: 'latest' }));
    latestDate.setHours(23, 59, 59, 999);  // Setze die Uhrzeit auf eine Millisekunde vor Mitternacht

    return [{
        semester: semester,
        earliestDate: getAcademicGridDate({ season: season, type: 'earliest' }).toISOString(),
        latestDate: latestDate.toISOString(),
        roomOptions: [],
        excludeDays: [0,6],
        earliestHour: config.workHours.startHour,
        latestHour: config.workHours.endHour,
        frequency: frequency,
        interval: 1
    }];
}

// Ergänzt fehlende Konfigurationsdaten aus spezifischen Konfigurationen um die Standardkonfiguration
function mergeConfigs(specificConfig, defaultConfig) {

    let mergedExcludeDays;
    if (Array.isArray(specificConfig.excludeDays) && specificConfig.excludeDays.length > 0) {
        mergedExcludeDays = specificConfig.excludeDays;
    } else {
        mergedExcludeDays = defaultConfig.excludeDays;
    }

    return {
        semester: specificConfig.semester || defaultConfig.semester,
        earliestDate: specificConfig.earliestDate || defaultConfig.earliestDate,
        latestDate: specificConfig.latestDate || defaultConfig.latestDate,
        roomOptions: specificConfig.roomOptions && specificConfig.roomOptions.length > 0 ? specificConfig.roomOptions : defaultConfig.roomOptions,
        excludeDays: mergedExcludeDays,
        mandatoryStartHour: specificConfig.mandatoryStartHour || null,
        earliestHour: specificConfig.hasOwnProperty('earliestHour') ? specificConfig.earliestHour : defaultConfig.earliestHour,
        latestHour: specificConfig.hasOwnProperty('latestHour') ? specificConfig.latestHour : defaultConfig.latestHour,
        frequency: specificConfig.hasOwnProperty('frequency') ? specificConfig.frequency : defaultConfig.frequency,
        interval: specificConfig.interval || defaultConfig.interval
    };
}

