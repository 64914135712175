    import moment from 'moment-timezone';
    import config from '../../config';
    import {findGroupNamesByPrefix} from "../utilityFunctions";
    import { groups } from "../../groups";

    const LOCAL_TIMEZONE = config.timeZone;

    // Hauptfunktion
    export const addStaticPlanning = (allEvents, allRooms) => {

        // console.log(findGroupNamesByPrefix(20, groups));
        console.log('Aufteilung in 5 Teile: ', getPartGroups(5, 20));
        console.log('Aufteilung in 9 Teile: ', getPartGroups(9, 20));

        /*
        // Entferne nicht benötigte Termine
        */
        // Seminare Wahl Ana/Bio 1. FS, 2.FS
            allEvents = filterEventsByGroups(allEvents,
                ['recwYRVKTPaOEY65e', 'rec86AOobsYGf34b3', 'recNPZK7YY95Gqt16'],
                ['20G-1-1-1-1', '20G-1-1-2-1']);
        // Tutorien Wahl Ana/Bio 1. FS, 2. FS
            allEvents = filterEventsByGroups(allEvents,
                ['recObxy0x1qsY3Rn2', 'recDVN0ktKq1JNudC', 'recwAOlOYGe6llGZY'],
                ['20G-1-1-1-2', '20G-1-1-1-3', '20G-1-2-1-2', '20G-1-1-2-2', '20G-1-1-2-3', '20G-1-2-2-2']);
        // Seminare Wahl Che/Phy 1. FS
            allEvents = filterEventsByGroups(allEvents,
                ['recO8iaw0LptiAXpu', 'reclQbKB1IKjkb0rz'],
                ['20G-1-2-1-1', '20G-1-2-2-1']);
        // Tutorien Wahl Che/Phy 1. FS
            allEvents = filterEventsByGroups(allEvents,
                ['recbVkgRtvS7c2u5f', 'rec7D0xUSZfyeFwc4'],
                ['20G-1-2-1-3', '20G-1-3-1-1', '20G-1-3-1-2', '20G-1-3-1-3', '20G-1-2-2-3', '20G-1-3-2-1', '20G-1-3-2-2']);
        // Seminare Wahl BCh/Che 2. FS
            allEvents = filterEventsByGroups(allEvents,
                ['recSNxy0igE8WfKK6', 'recC4f53OqHyLu43U'],
                ['20G-1-2-1-1', '20G-1-2-2-1']);
        // Tutorien Wahl BCh/Che 2. FS
            allEvents = filterEventsByGroups(allEvents,
                ['recrQRpIyQrSM4Ahw', 'recfsMwRodqK2Gaht'],
                ['20G-1-2-1-3', '20G-1-3-1-1', '20G-1-3-1-2', '20G-1-3-1-3', '20G-1-2-2-3', '20G-1-3-2-1', '20G-1-3-2-2']);

        /*
        // Durchlaufe alle Termine, um spezifische statische Planungsdaten hinzuzufügen
        */
        allEvents.forEach(event => {
            const courseId = event.extendedProps.id.split('.')[0];
            const eventGroupIndex = parseInt(event.extendedProps.id.split('.')[1], 10);
            const eventIndex = parseInt(event.extendedProps.id.split('.')[2], 10);
            const eventGroupId = event.extendedProps.id.split('.').slice(0, -1).join('.');
            const groupSize = event.extendedProps.groupSize;
            const group = event.extendedProps.group;
            let offsetMultiplier = 0;
            let scheduleData = [];

            switch (courseId) {
                /*
                // 1. Fachsemester
                */
                // Extracurriculare Veranstaltung Einführungsveranstaltung
                    case 'recIygaYSMTOSi3gr':
                        scheduleData = [{
                            dates: ['2025-10-06 09:00:00'],
                            roomIds: ['rec5vneXF3FUFLvzd'],
                            semester: 1,
                            preventChanges: true
                        }];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        console.log(event);
                        break;
                // PTM Medizindidaktik
                    case 'recm8Ztl5f2wzrLFQ':
                        scheduleData = [{
                            dates: ['2025-10-13 08:00:00'],
                            roomIds: ['rec5vneXF3FUFLvzd'],
                            semester: 1,
                            preventChanges: true
                        }];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;
                // Vorlesung Notfallmedizin Erste Hilfe
                    case 'recgFDcogvezJNHnR':
                        scheduleData = [{
                            dates: ['2025-10-10 08:00:00'],
                            roomIds: ['rec5vneXF3FUFLvzd'],
                            semester: 1,
                            preventChanges: true
                        }];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;
                // Praktikum Medizindidaktik pPTM
                    case 'recmhzAddvxUR5KJQ':
                        if (eventIndex >= 1 && eventIndex <= 6) { offsetMultiplier = eventIndex - 1; }
                        else if (eventIndex >= 7 && eventIndex <= 12) { offsetMultiplier = eventIndex - 7; }
                        else if (eventIndex >= 13 && eventIndex <= 17) { offsetMultiplier = eventIndex - 13; }
                        else { offsetMultiplier = 0; }
                        scheduleData = [
                            {
                                groups: getPartGroups(3, groupSize)[0],
                                dates: [adjustTime('2025-10-14 08:00:00', offsetMultiplier, 105)],
                                semester: 1,
                                preventChanges: true
                            },
                            {
                                groups: getPartGroups(3, groupSize)[1],
                                dates: [adjustTime('2025-10-15 08:00:00', offsetMultiplier, 105)],
                                semester: 1,
                                preventChanges: true
                            },
                            {
                                groups: getPartGroups(3, groupSize)[2],
                                dates: [adjustTime('2025-10-16 08:00:00', offsetMultiplier, 105)],
                                semester: 1,
                                preventChanges: true
                            },
                        ];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;
                // Hands On Seminar Notfallmedizin Erste Hilfe
                    case 'reczYbkzAqgqZZ7Zd':
                        scheduleData = [
                            {
                                groups: getPartGroups(2, groupSize)[0],
                                dates: ['2025-10-10 10:00:00'],
                                semester: 1,
                                preventChanges: true
                            },
                            {
                                groups: getPartGroups(2, groupSize)[1],
                                dates: ['2025-10-17 10:00:00'],
                                semester: 1,
                                preventChanges: true
                            },
                        ];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;
                // UaKD Notfallmedizin Erste Hilfe
                    case 'rec6NEV19YIFkxRfn':
                        scheduleData = [
                            {
                                groups: getPartGroups(2, groupSize)[0],
                                dates: ['2025-10-11 09:00:00'],
                                semester: 1,
                                preventChanges: true
                            },
                            {
                                groups: getPartGroups(2, groupSize)[1],
                                dates: ['2025-10-18 09:00:00'],
                                semester: 1,
                                preventChanges: true
                            },
                        ];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;
                // Seminar Anatomie Wahl Ana/Bio
                    case 'recwYRVKTPaOEY65e':
                        scheduleData = [
                            {
                                groups: ['20G-1-1-1-1'],
                                dates: [
                                    '2025-10-20 16:00:00',
                                    '2025-10-27 16:00:00',
                                    '2025-11-03 16:00:00',
                                    '2025-11-10 16:00:00',
                                    '2025-11-17 16:00:00',
                                    '2025-11-24 16:00:00',
                                    '2025-12-01 16:00:00',
                                    '2025-12-08 16:00:00',
                                    '2025-12-15 16:00:00',
                                    '2026-01-05 16:00:00',
                                    '2026-01-12 16:00:00',
                                    '2026-01-19 16:00:00',
                                ],
                                semester: 1,
                                preventChanges: true
                            },
                            {
                                groups: ['20G-1-1-2-1'],
                                dates: [
                                    '2025-10-21 16:00:00',
                                    '2025-10-28 16:00:00',
                                    '2025-11-04 16:00:00',
                                    '2025-11-11 16:00:00',
                                    '2025-11-18 16:00:00',
                                    '2025-11-25 16:00:00',
                                    '2025-12-02 16:00:00',
                                    '2025-12-09 16:00:00',
                                    '2025-12-16 16:00:00',
                                    '2026-01-06 16:00:00',
                                    '2026-01-13 16:00:00',
                                    '2026-01-20 16:00:00',
                                ],
                                semester: 1,
                                preventChanges: true
                            },
                        ];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;
                // Seminar Biologie Wahl Ana/Bio
                    case 'rec86AOobsYGf34b3':
                        scheduleData = [
                            {
                                groups: ['20G-1-1-1-1'],
                                dates: [
                                    '2026-01-26 16:00:00',
                                    '2026-01-19 18:00:00',
                                    '2026-01-26 18:00:00',
                                ],
                                semester: 1,
                                preventChanges: true
                            },
                            {
                                groups: ['20G-1-1-2-1'],
                                dates: [
                                    '2026-01-27 16:00:00',
                                    '2026-01-20 18:00:00',
                                    '2026-01-27 18:00:00',
                                ],
                                semester: 1,
                                preventChanges: true
                            },
                        ];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;
                // Tutorium Anatomie Wahl Ana/Bio
                    case 'recObxy0x1qsY3Rn2':
                        scheduleData = [
                            {
                                groups: ['20G-1-1-1-2', '20G-1-1-1-3', '20G-1-2-1-2'],
                                dates: [
                                    '2025-10-20 16:00:00',
                                    '2025-10-27 16:00:00',
                                    '2025-11-03 16:00:00',
                                    '2025-11-10 16:00:00',
                                    '2025-11-17 16:00:00',
                                    '2025-11-24 16:00:00',
                                    '2025-12-01 16:00:00',
                                    '2025-12-08 16:00:00',
                                    '2025-12-15 16:00:00',
                                    '2026-01-05 16:00:00',
                                    '2026-01-12 16:00:00',
                                    '2026-01-19 16:00:00',
                                ],
                                semester: 1,
                                preventChanges: true
                            },
                            {
                                groups: ['20G-1-1-2-2', '20G-1-1-2-3', '20G-1-2-2-2'],
                                dates: [
                                    '2025-10-21 16:00:00',
                                    '2025-10-28 16:00:00',
                                    '2025-11-04 16:00:00',
                                    '2025-11-11 16:00:00',
                                    '2025-11-18 16:00:00',
                                    '2025-11-25 16:00:00',
                                    '2025-12-02 16:00:00',
                                    '2025-12-09 16:00:00',
                                    '2025-12-16 16:00:00',
                                    '2026-01-06 16:00:00',
                                    '2026-01-13 16:00:00',
                                    '2026-01-20 16:00:00',
                                ],
                                semester: 1,
                                preventChanges: true
                            },
                        ];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;
                // Tutorium Biologie Wahl Ana/Bio
                    case 'recDVN0ktKq1JNudC':
                        scheduleData = [
                            {
                                groups: ['20G-1-1-1-2', '20G-1-1-1-3', '20G-1-2-1-2'],
                                dates: [
                                    '2026-01-26 16:00:00',
                                    '2026-01-19 18:00:00',
                                    '2026-01-26 18:00:00',
                                ],
                                semester: 1,
                                preventChanges: true
                            },
                            {
                                groups: ['20G-1-1-2-2', '20G-1-1-2-3', '20G-1-2-2-2'],
                                dates: [
                                    '2026-01-27 16:00:00',
                                    '2026-01-20 18:00:00',
                                    '2026-01-27 18:00:00',
                                ],
                                semester: 1,
                                preventChanges: true
                            },
                        ];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;
                // Seminar Chemie Wahl Che/Phy
                    case 'recO8iaw0LptiAXpu':
                        scheduleData = [
                            {
                                groups: ['20G-1-2-1-1'],
                                dates: [
                                    '2025-10-20 16:00:00',
                                    '2025-10-27 16:00:00',
                                    '2025-11-03 16:00:00',
                                    '2025-11-10 16:00:00',
                                    '2025-11-17 16:00:00',
                                    '2025-11-24 16:00:00',
                                    '2025-12-01 16:00:00',
                                    '2025-12-08 16:00:00',
                                ],
                                semester: 1,
                                preventChanges: true
                            },
                            {
                                groups: ['20G-1-2-2-1'],
                                dates: [
                                    '2025-10-21 16:00:00',
                                    '2025-10-28 16:00:00',
                                    '2025-11-04 16:00:00',
                                    '2025-11-11 16:00:00',
                                    '2025-11-18 16:00:00',
                                    '2025-11-25 16:00:00',
                                    '2025-12-02 16:00:00',
                                    '2025-12-09 16:00:00',
                                ],
                                semester: 1,
                                preventChanges: true
                            },
                        ];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;
                // Seminar Physik Wahl Che/Phy
                    case 'reclQbKB1IKjkb0rz':
                        scheduleData = [
                            {
                                groups: ['20G-1-2-1-1'],
                                dates: [
                                    '2025-12-15 16:00:00',
                                    '2026-01-05 16:00:00',
                                    '2026-01-12 16:00:00',
                                    '2026-01-19 16:00:00',
                                    '2026-01-26 16:00:00',
                                    '2026-01-19 18:00:00',
                                    '2026-01-26 18:00:00',
                                ],
                                semester: 1,
                                preventChanges: true
                            },
                            {
                                groups: ['20G-1-2-2-1'],
                                dates: [
                                    '2025-12-16 16:00:00',
                                    '2026-01-06 16:00:00',
                                    '2026-01-13 16:00:00',
                                    '2026-01-20 16:00:00',
                                    '2026-01-27 16:00:00',
                                    '2026-01-20 18:00:00',
                                    '2026-01-27 18:00:00',
                                ],
                                semester: 1,
                                preventChanges: true
                            },
                        ];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;
                // Tutorium Chemie Wahl Che/Phy
                    case 'recbVkgRtvS7c2u5f':
                        scheduleData = [
                            {
                                groups: ['20G-1-2-1-3', '20G-1-3-1-1', '20G-1-3-1-2', '20G-1-3-1-3'],
                                dates: [
                                    '2025-10-20 16:00:00',
                                    '2025-10-27 16:00:00',
                                    '2025-11-03 16:00:00',
                                    '2025-11-10 16:00:00',
                                    '2025-11-17 16:00:00',
                                    '2025-11-24 16:00:00',
                                    '2025-12-01 16:00:00',
                                    '2025-12-08 16:00:00',
                                ],
                                semester: 1,
                                preventChanges: true
                            },
                            {
                                groups: ['20G-1-2-2-3', '20G-1-3-2-1', '20G-1-3-2-2'],
                                dates: [
                                    '2025-10-21 16:00:00',
                                    '2025-10-28 16:00:00',
                                    '2025-11-04 16:00:00',
                                    '2025-11-11 16:00:00',
                                    '2025-11-18 16:00:00',
                                    '2025-11-25 16:00:00',
                                    '2025-12-02 16:00:00',
                                    '2025-12-09 16:00:00',
                                ],
                                semester: 1,
                                preventChanges: true
                            },
                        ];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;
                // Tutorium Physik Wahl Che/Phy
                    case 'rec7D0xUSZfyeFwc4':
                        scheduleData = [
                            {
                                groups: ['20G-1-2-1-3', '20G-1-3-1-1', '20G-1-3-1-2', '20G-1-3-1-3'],
                                dates: [
                                    '2025-12-15 16:00:00',
                                    '2026-01-05 16:00:00',
                                    '2026-01-12 16:00:00',
                                    '2026-01-19 16:00:00',
                                    '2026-01-26 16:00:00',
                                    '2026-01-19 18:00:00',
                                    '2026-01-26 18:00:00',
                                ],
                                semester: 1,
                                preventChanges: true
                            },
                            {
                                groups: ['20G-1-2-2-3', '20G-1-3-2-1', '20G-1-3-2-2'],
                                dates: [
                                    '2025-12-16 16:00:00',
                                    '2026-01-06 16:00:00',
                                    '2026-01-13 16:00:00',
                                    '2026-01-20 16:00:00',
                                    '2026-01-27 16:00:00',
                                    '2026-01-20 18:00:00',
                                    '2026-01-27 18:00:00',
                                ],
                                semester: 1,
                                preventChanges: true
                            },
                        ];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;
                // Prüfung mdl.-praktische Prüfung 5-I-MEDEINF T2
                    case 'recQ36Pf3MjBDgGq0':
                        scheduleData = [{
                            dates: ['2025-11-03 08:00:00'],
                            roomIds: ['recIcWybNbBWaK6WJ', 'recb0ZmSGDNy70eZD'],
                            semester: 1,
                            preventChanges: true
                        }];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;
                // Präparierkurs Anatomie
                    case 'rec9jlYO9li69Oo7P':
                        scheduleData = [
                            {
                                groups: getPartGroups(2, groupSize)[0],
                                dates: [
                                    '2025-10-29 10:00:00',
                                    '2025-11-05 10:00:00',
                                    '2025-11-12 10:00:00',
                                    '2025-11-19 10:00:00',
                                    '2025-11-26 10:00:00',
                                    '2025-12-03 10:00:00',
                                    '2025-12-10 10:00:00',
                                    '2025-12-17 10:00:00',
                                    '2026-01-07 10:00:00',
                                    '2026-01-14 10:00:00',
                                ],
                                roomIds: ['receUmIj00kE1eB6M'],
                                semester: 1,
                                preventChanges: true
                            },
                            {
                                groups: getPartGroups(2, groupSize)[1],
                                dates: [
                                    '2025-10-30 10:00:00',
                                    '2025-11-06 10:00:00',
                                    '2025-11-13 10:00:00',
                                    '2025-11-20 10:00:00',
                                    '2025-11-27 10:00:00',
                                    '2025-12-04 10:00:00',
                                    '2025-12-11 10:00:00',
                                    '2025-12-18 10:00:00',
                                    '2026-01-08 10:00:00',
                                    '2026-01-15 10:00:00',
                                ],
                                roomIds: ['receUmIj00kE1eB6M'],
                                semester: 1,
                                preventChanges: true
                            },
                        ];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;

                /*
                // 2. Fachsemester
                */
                // Vorlesung Lernbegleitung Einführung in das Modul
                    case 'recNjWiYe8VS45CPl':
                        scheduleData = [{
                            dates: ['2026-04-13 08:00:00'],
                            roomIds: ['rec5vneXF3FUFLvzd'],
                            semester: 2,
                            preventChanges: true
                        }];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;
                // PTM Medizindidaktik
                    case 'rechkPK36o3Tw7fNO':
                        scheduleData = [{
                            dates: ['2026-04-20 08:00:00'],
                            roomIds: ['rec5vneXF3FUFLvzd'],
                            semester: 2,
                            preventChanges: true
                        }];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;
                // Blockpraktikum Allgemeinmedizin
                    case 'rec4DHF5zXVdziFpK':
                        scheduleData = [{
                            dates: [
                                '2026-06-10 08:00:00',
                                '2026-06-11 08:00:00',
                                '2026-06-12 08:00:00',
                                '2026-06-15 08:00:00',
                                '2026-06-16 08:00:00',
                                '2026-06-17 08:00:00',
                                '2026-06-18 08:00:00',
                            ],
                            semester: 2,
                            preventChanges: true
                        }];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;
                // Patient*innenbegleitung Allgemeinmedizin
                    case 'recPumPeRF6AF24xY':
                        scheduleData = [{
                            dates: [
                                '2026-06-19 08:00:00',
                            ],
                            semester: 2,
                            preventChanges: true
                        }];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;
                // Seminar Allgemeinmedizin Nachbereitung Blockpraktikum
                    case 'reciX34CGXVkdWtrR':
                        scheduleData = [
                            {
                                groups: getPartGroups(3, groupSize)[0],
                                dates: ['2026-06-19 14:00:00'],
                                semester: 2,
                                preventChanges: true
                            },
                            {
                                groups: getPartGroups(3, groupSize)[1],
                                dates: ['2026-09-11 14:00:00'],
                                semester: 2,
                                preventChanges: true
                            },
                            {
                                groups: getPartGroups(3, groupSize)[2],
                                dates: ['2026-09-25 14:00:00'],
                                semester: 2,
                                preventChanges: true
                            },
                        ];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;
                // Präparierkurs Anatomie
                    case 'rec53DOP5jQtBQSd8':
                        scheduleData = [
                            {
                                groups: getPartGroups(2, groupSize)[0],
                                dates: [
                                    '2026-04-21 10:00:00',
                                    '2026-04-28 10:00:00',
                                    '2026-05-05 10:00:00',
                                    '2026-05-12 10:00:00',
                                    '2026-05-19 10:00:00',
                                    '2026-05-26 10:00:00',
                                    '2026-06-02 10:00:00',
                                    '2026-06-09 10:00:00',
                                    '2026-06-23 10:00:00',
                                    '2026-06-30 10:00:00',
                                    '2026-07-07 10:00:00',
                                ],
                                roomIds: ['receUmIj00kE1eB6M'],
                                semester: 2,
                                preventChanges: true
                            },
                            {
                                groups: getPartGroups(2, groupSize)[1],
                                dates: [
                                    '2026-04-22 10:00:00',
                                    '2026-04-29 10:00:00',
                                    '2026-05-06 10:00:00',
                                    '2026-05-13 10:00:00',
                                    '2026-05-20 10:00:00',
                                    '2026-05-27 10:00:00',
                                    '2026-06-03 10:00:00',
                                    '2026-06-09 14:00:00',
                                    '2026-06-24 10:00:00',
                                    '2026-07-01 10:00:00',
                                    '2026-07-08 10:00:00',
                                ],
                                roomIds: ['receUmIj00kE1eB6M'],
                                semester: 2,
                                preventChanges: true
                            },
                        ];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;
                // Tutorium KoMED
                    case 'recCZsOt6JDEXfNF6':
                        scheduleData = [
                            {
                                groups: getPartGroups(6, groupSize)[0],
                                dates: ['2026-04-21 08:00:00'],
                                semester: 2,
                                preventChanges: false
                            },
                            {
                                groups: getPartGroups(6, groupSize)[1],
                                dates: ['2026-04-21 10:00:00'],
                                semester: 2,
                                preventChanges: false
                            },
                            {
                                groups: getPartGroups(6, groupSize)[2],
                                dates: ['2026-04-22 08:00:00'],
                                semester: 2,
                                preventChanges: false
                            },
                            {
                                groups: getPartGroups(6, groupSize)[3],
                                dates: ['2026-04-22 10:00:00'],
                                semester: 2,
                                preventChanges: false
                            },
                            {
                                groups: getPartGroups(6, groupSize)[4],
                                dates: ['2026-04-23 08:00:00'],
                                semester: 2,
                                preventChanges: false
                            },
                            {
                                groups: getPartGroups(6, groupSize)[5],
                                dates: ['2026-04-23 10:00:00'],
                                semester: 2,
                                preventChanges: false
                            },
                        ];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;
                // Tutorium Anatomie Wahl Ana/Bio
                    case 'recwAOlOYGe6llGZY':
                        scheduleData = [
                            {
                                groups: ['20G-1-1-1-2', '20G-1-1-1-3', '20G-1-2-1-2'],
                                dates: [
                                    '2026-04-27 16:00:00',
                                    '2026-05-04 16:00:00',
                                    '2026-05-11 16:00:00',
                                    '2026-05-18 16:00:00',
                                    '2026-05-18 18:00:00',
                                    '2026-06-01 16:00:00',
                                    '2026-06-08 16:00:00',
                                    '2026-06-22 16:00:00',
                                    '2026-06-29 16:00:00',
                                    '2026-07-06 16:00:00',
                                    '2026-07-06 18:00:00',
                                    '2026-07-13 16:00:00',
                                    '2026-07-13 18:00:00',
                                    '2026-07-20 16:00:00',
                                    '2026-07-20 18:00:00',
                                ],
                                semester: 2,
                                preventChanges: true
                            },
                            {
                                groups: ['20G-1-1-2-2', '20G-1-1-2-3', '20G-1-2-2-2'],
                                dates: [
                                    '2026-04-28 16:00:00',
                                    '2026-05-05 16:00:00',
                                    '2026-05-12 16:00:00',
                                    '2026-05-19 16:00:00',
                                    '2026-05-19 18:00:00',
                                    '2026-06-02 16:00:00',
                                    '2026-06-09 16:00:00',
                                    '2026-06-23 16:00:00',
                                    '2026-06-30 16:00:00',
                                    '2026-07-07 16:00:00',
                                    '2026-07-07 18:00:00',
                                    '2026-07-14 16:00:00',
                                    '2026-07-14 18:00:00',
                                    '2026-07-21 16:00:00',
                                    '2026-07-21 18:00:00',
                                ],
                                semester: 2,
                                preventChanges: true
                            },
                        ];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;
                // Seminar Anatomie Wahl Ana/Bio
                    case 'recNPZK7YY95Gqt16':
                        scheduleData = [
                            {
                                groups: ['20G-1-1-1-1'],
                                dates: [
                                    '2026-04-27 16:00:00',
                                    '2026-05-04 16:00:00',
                                    '2026-05-11 16:00:00',
                                    '2026-05-18 16:00:00',
                                    '2026-05-18 18:00:00',
                                    '2026-06-01 16:00:00',
                                    '2026-06-08 16:00:00',
                                    '2026-06-22 16:00:00',
                                    '2026-06-29 16:00:00',
                                    '2026-07-06 16:00:00',
                                    '2026-07-06 18:00:00',
                                    '2026-07-13 16:00:00',
                                    '2026-07-13 18:00:00',
                                    '2026-07-20 16:00:00',
                                    '2026-07-20 18:00:00',
                                ],
                                semester: 2,
                                preventChanges: true
                            },
                            {
                                groups: ['20G-1-1-2-1'],
                                dates: [
                                    '2026-04-28 16:00:00',
                                    '2026-05-05 16:00:00',
                                    '2026-05-12 16:00:00',
                                    '2026-05-19 16:00:00',
                                    '2026-05-19 18:00:00',
                                    '2026-06-02 16:00:00',
                                    '2026-06-09 16:00:00',
                                    '2026-06-23 16:00:00',
                                    '2026-06-30 16:00:00',
                                    '2026-07-07 16:00:00',
                                    '2026-07-07 18:00:00',
                                    '2026-07-14 16:00:00',
                                    '2026-07-14 18:00:00',
                                    '2026-07-21 16:00:00',
                                    '2026-07-21 18:00:00',
                                ],
                                semester: 2,
                                preventChanges: true
                            },
                        ];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;
                // Seminar Chemie Wahl BCh/Che
                    case 'recC4f53OqHyLu43U':
                        scheduleData = [
                            {
                                groups: ['20G-1-2-1-1'],
                                dates: [
                                    '2026-04-27 16:00:00',
                                    '2026-05-04 16:00:00',
                                    '2026-05-11 16:00:00',
                                    '2026-05-18 16:00:00',
                                    '2026-05-18 18:00:00',
                                ],
                                semester: 2,
                                preventChanges: true
                            },
                            {
                                groups: ['20G-1-2-2-1'],
                                dates: [
                                    '2026-04-28 16:00:00',
                                    '2026-05-05 16:00:00',
                                    '2026-05-12 16:00:00',
                                    '2026-05-19 16:00:00',
                                    '2026-05-19 18:00:00',
                                ],
                                semester: 2,
                                preventChanges: true
                            },
                        ];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;
                // Seminar Biochemie Wahl BCh/Che
                    case 'recSNxy0igE8WfKK6':
                        scheduleData = [
                            {
                                groups: ['20G-1-2-1-1'],
                                dates: [
                                    '2026-06-01 16:00:00',
                                    '2026-06-08 16:00:00',
                                    '2026-06-22 16:00:00',
                                    '2026-06-29 16:00:00',
                                    '2026-07-06 16:00:00',
                                    '2026-07-06 18:00:00',
                                    '2026-07-13 16:00:00',
                                    '2026-07-13 18:00:00',
                                    '2026-07-20 16:00:00',
                                    '2026-07-20 18:00:00',
                                ],
                                semester: 2,
                                preventChanges: true
                            },
                            {
                                groups: ['20G-1-2-2-1'],
                                dates: [
                                    '2026-06-02 16:00:00',
                                    '2026-06-09 16:00:00',
                                    '2026-06-23 16:00:00',
                                    '2026-06-30 16:00:00',
                                    '2026-07-07 16:00:00',
                                    '2026-07-07 18:00:00',
                                    '2026-07-14 16:00:00',
                                    '2026-07-14 18:00:00',
                                    '2026-07-21 16:00:00',
                                    '2026-07-21 18:00:00',
                                ],
                                semester: 2,
                                preventChanges: true
                            },
                        ];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;
                // Tutorium Chemie Wahl BCh/Che
                    case 'recfsMwRodqK2Gaht':
                        scheduleData = [
                            {
                                groups: ['20G-1-2-1-3', '20G-1-3-1-1', '20G-1-3-1-2', '20G-1-3-1-3'],
                                dates: [
                                    '2026-04-27 16:00:00',
                                    '2026-05-04 16:00:00',
                                    '2026-05-11 16:00:00',
                                    '2026-05-18 16:00:00',
                                    '2026-05-18 18:00:00',
                                ],
                                semester: 2,
                                preventChanges: true
                            },
                            {
                                groups: ['20G-1-2-2-3', '20G-1-3-2-1', '20G-1-3-2-2'],
                                dates: [
                                    '2026-04-28 16:00:00',
                                    '2026-05-05 16:00:00',
                                    '2026-05-12 16:00:00',
                                    '2026-05-19 16:00:00',
                                    '2026-05-19 18:00:00',
                                ],
                                semester: 2,
                                preventChanges: true
                            },
                        ];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;
                // Tutorium Biochemie Wahl BCh/Che
                    case 'recrQRpIyQrSM4Ahw':
                        scheduleData = [
                            {
                                groups: ['20G-1-2-1-3', '20G-1-3-1-1', '20G-1-3-1-2', '20G-1-3-1-3'],
                                dates: [
                                    '2026-06-01 16:00:00',
                                    '2026-06-08 16:00:00',
                                    '2026-06-22 16:00:00',
                                    '2026-06-29 16:00:00',
                                    '2026-07-06 16:00:00',
                                    '2026-07-06 18:00:00',
                                    '2026-07-13 16:00:00',
                                    '2026-07-13 18:00:00',
                                    '2026-07-20 16:00:00',
                                    '2026-07-20 18:00:00',
                                ],
                                semester: 2,
                                preventChanges: true
                            },
                            {
                                groups: ['20G-1-2-2-3', '20G-1-3-2-1', '20G-1-3-2-2'],
                                dates: [
                                    '2026-06-02 16:00:00',
                                    '2026-06-09 16:00:00',
                                    '2026-06-23 16:00:00',
                                    '2026-06-30 16:00:00',
                                    '2026-07-07 16:00:00',
                                    '2026-07-07 18:00:00',
                                    '2026-07-14 16:00:00',
                                    '2026-07-14 18:00:00',
                                    '2026-07-21 16:00:00',
                                    '2026-07-21 18:00:00',
                                ],
                                semester: 2,
                                preventChanges: true
                            },
                        ];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;

                /*
                // 3. Fachsemester
                */
                // PTM Medizindidaktik
                    case 'recyBbhc2QbSHQc6C':
                        scheduleData = [{
                            dates: ['2025-10-14 08:00:00'],
                            roomIds: ['rec5vneXF3FUFLvzd'],
                            semester: 3,
                            preventChanges: true
                        }];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;

                /*
                // 4. Fachsemester
                */
                // PTM Medizindidaktik
                    case 'recYjxetWEZc4HRfU':
                        scheduleData = [{
                            dates: ['2026-04-21 08:00:00'],
                            roomIds: ['rec5vneXF3FUFLvzd'],
                            semester: 4,
                            preventChanges: true
                        }];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;

                /*
                // 5. Fachsemester
                */
                // PTM Medizindidaktik
                    case 'recFVWwCZIaPnRcfA':
                        scheduleData = [{
                            dates: ['2025-10-15 08:00:00'],
                            roomIds: ['rec5vneXF3FUFLvzd'],
                            semester: 5,
                            preventChanges: true
                        }];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;
                // Praktikum Medizindidaktik pPTM
                    case 'recfR1kaEse2cH3Uo':
                        if (eventIndex >= 1 && eventIndex <= 6) { offsetMultiplier = eventIndex - 1; }
                        else if (eventIndex >= 7 && eventIndex <= 12) { offsetMultiplier = eventIndex - 7; }
                        else if (eventIndex >= 13 && eventIndex <= 17) { offsetMultiplier = eventIndex - 13; }
                        else { offsetMultiplier = 0; }
                        scheduleData = [
                            {
                                groups: getPartGroups(3, groupSize)[0],
                                dates: [adjustTime('2025-10-21 08:00:00', offsetMultiplier, 105)],
                                semester: 1,
                                preventChanges: true
                            },
                            {
                                groups: getPartGroups(3, groupSize)[1],
                                dates: [adjustTime('2025-10-22 08:00:00', offsetMultiplier, 105)],
                                semester: 1,
                                preventChanges: true
                            },
                            {
                                groups: getPartGroups(3, groupSize)[2],
                                dates: [adjustTime('2025-10-23 08:00:00', offsetMultiplier, 105)],
                                semester: 1,
                                preventChanges: true
                            },
                        ];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;

                /*
                // 6. Fachsemester
                */
                // PTM Medizindidaktik
                    case 'reclWkQF5BOCPLXo4':
                        scheduleData = [{
                            dates: ['2026-04-22 08:00:00'],
                            roomIds: ['rec5vneXF3FUFLvzd'],
                            semester: 6,
                            preventChanges: true
                        }];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;

                /*
                // 7. Fachsemester
                */
                // PTM Medizindidaktik
                    case 'recIjZoJujN5PWzOp':
                        scheduleData = [{
                            dates: ['2025-10-16 08:00:00'],
                            roomIds: ['rec5vneXF3FUFLvzd'],
                            semester: 7,
                            preventChanges: true
                        }];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;

                /*
                // 8. Fachsemester
                */
                // PTM Medizindidaktik
                    case 'recQJrQiqPDuI2RYn':
                        scheduleData = [{
                            dates: ['2026-04-23 08:00:00'],
                            roomIds: ['rec5vneXF3FUFLvzd'],
                            semester: 8,
                            preventChanges: true
                        }];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;

                /*
                // 9. Fachsemester
                */
                // PTM Medizindidaktik
                    case 'recQI30CT7tK37vZR':
                        scheduleData = [{
                            dates: ['2025-10-17 08:00:00'],
                            roomIds: ['rec5vneXF3FUFLvzd'],
                            semester: 9,
                            preventChanges: true
                        }];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;
                // Praktikum Medizindidaktik pPTM
                    case 'recEEykK70uCDcmfd':
                        if (eventIndex >= 1 && eventIndex <= 6) { offsetMultiplier = eventIndex - 1; }
                        else if (eventIndex >= 7 && eventIndex <= 12) { offsetMultiplier = eventIndex - 7; }
                        else if (eventIndex >= 13 && eventIndex <= 17) { offsetMultiplier = eventIndex - 13; }
                        else { offsetMultiplier = 0; }
                        scheduleData = [
                            {
                                groups: getPartGroups(3, groupSize)[0],
                                dates: [adjustTime('2025-10-28 08:00:00', offsetMultiplier, 105)],
                                semester: 1,
                                preventChanges: true
                            },
                            {
                                groups: getPartGroups(3, groupSize)[1],
                                dates: [adjustTime('2025-10-29 08:00:00', offsetMultiplier, 105)],
                                semester: 1,
                                preventChanges: true
                            },
                            {
                                groups: getPartGroups(3, groupSize)[2],
                                dates: [adjustTime('2025-10-30 08:00:00', offsetMultiplier, 105)],
                                semester: 1,
                                preventChanges: true
                            },
                        ];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;

                /*
                // 10. Fachsemester
                */
                // PTM Medizindidaktik
                    case 'rec2PcNQ3pfd5Jmiq':
                        scheduleData = [{
                            dates: ['2026-04-24 08:00:00'],
                            roomIds: ['rec5vneXF3FUFLvzd'],
                            semester: 10,
                            preventChanges: true
                        }];
                        scheduleData.forEach(scheduleEntry => addIdSpecificPlanning(event, allRooms, scheduleEntry));
                        break;
            }

        });

        return allEvents;
    };

    // Hilfsfunktion, um eine ID-spezifische manuelle Planung hinzuzufügen
    const addIdSpecificPlanning = (event, allRooms, scheduleEntry) => {

        const eventGroupIndex = parseInt(event.extendedProps.id.split('.')[1], 10);
        const groups = scheduleEntry.groups || [event.extendedProps.group];
        const roomIds = scheduleEntry.roomIds || [];
        const courseId = event.extendedProps.id.split('.')[0];

        if (groups.includes(event.extendedProps.group)) {

            if (scheduleEntry.dates[eventGroupIndex - 1]) {
                const startTime = moment.tz(scheduleEntry.dates[eventGroupIndex - 1], LOCAL_TIMEZONE);
                const endTime = moment(startTime).add(event.extendedProps.eventDuration, 'minutes');

                event.start = startTime.format();
                event.end = endTime.format();
                event.locations = roomIds.map(roomId => getRoomData(roomId, allRooms));
                event.extendedProps.semester = scheduleEntry.semester;
                event.extendedProps.preventChanges = scheduleEntry.preventChanges;

                if (courseId === 'recahFChsfwIVJRXo') {
                    console.group(`%c${event.title}`, 'background-color: rgba(0,255,255,0.2); font-weight: normal;');
                    const formattedStart = moment(event.start).format('DD.MM.YYYY, HH:mm');
                    const formattedEnd = moment(event.end).format('DD.MM.YYYY, HH:mm');
                    const locationNames = event.locations.map(location => location.roomName).join(', ');
                    console.log(`ID:       ${event.extendedProps.id}`);
                    console.log(`Start:    ${formattedStart} Uhr`);
                    console.log(`Ende:     ${formattedEnd} Uhr`);
                    console.log(`Räume:    ${locationNames}`);
                    console.log(`Gruppe:   ${event.extendedProps.group}`);
                    console.log(`Semester: ${event.extendedProps.semester}`);
                    console.groupEnd();
                }
            }
            else {
                console.warn('Keine Planungsdaten für ', event);
            }

        }
    };

    // Hilfsfunktion, um nicht benötigte Termine bestimmter Veranstaltungen und Gruppen herauszufiltern
    function filterEventsByGroups(allEvents, courseIds, groupsToBePreserved) {
        return allEvents.filter(event => {
            const courseId = event.extendedProps.id.split('.')[0];
            const group = event.extendedProps.group;
            // Prüft, ob die courseId im Array der zu filternden courseIds enthalten ist
            const isCourseIdMatched = courseIds.includes(courseId);
            // Prüft, ob die Gruppe NICHT im Array der erlaubten Gruppen enthalten ist
            const isGroupExcluded = !groupsToBePreserved.includes(group);
            // Filtert Events, die beiden Bedingungen entsprechen
            return !(isCourseIdMatched && isGroupExcluded);
        });
    }

    // Hilfsfunktion, um Gruppenhälften zu erhalten
    const getPartGroups = (partsNumber, groupSize) => {
        const allGroups = findGroupNamesByPrefix(groupSize, groups);
        const partSize = Math.ceil(allGroups.length / partsNumber);
        const parts = [];

        for (let i = 0; i < partsNumber; i++) {
            const start = i * partSize;
            const end = Math.min(start + partSize, allGroups.length);
            parts.push(allGroups.slice(start, end));
        }

        return parts;
    };

    // Hilfsfunktion zur Erhöhung der Zeit basierend auf einem Offset
    function adjustTime(startTime, offsetMultiplier, offset = 0) {
        return moment(startTime, "YYYY-MM-DD HH:mm:ss")
            .add(offsetMultiplier * offset, 'minutes')
            .format("YYYY-MM-DD HH:mm:ss");
    }

    // Hilfsfunktion, um Raumdaten zu finden und zu formatieren
    const getRoomData = (roomId, allRooms) => {
        const room = allRooms.find(r => r.id === roomId);
        if (!room) return { roomId: "Unbekannt", roomName: "NN", buildingId: "Unbekannt", buildingName: "Unbekanntes Gebäude", campus: "Unbekannter Campus" };

        return {
            roomId: room.id,
            roomName: room.name || "NN",
            buildingId: room.building && room.building.length > 0 ? room.building[0] : "Unbekannt",
            buildingName: room.buildingName && room.buildingName.length > 0 ? room.buildingName[0] : "Unbekanntes Gebäude",
            campus: room.campus && room.campus.length > 0 ? room.campus[0] : "Unbekannter Campus"
        };
    };
