import React, { useState } from 'react';
import SolutionAnalyzer from '../features/SolutionAnalyzer/SolutionAnalyzer';

function AnalyseKostenvergleich() {
    const [selectedResultId] = useState("");

    return (
        <div className="page-content no-sidebar">
            <div className="page-item colspan">
                <SolutionAnalyzer selectedResultId={selectedResultId} />
            </div>
        </div>
    );
}

export default AnalyseKostenvergleich;
