import React from 'react';
import {
    calculateAverageSubjectMinutesPerHour,
    getDailyWorkloadHeatmapColor
} from "../../../utilities/analyse/analyseUtilityFunctions";
import '../../Analyse/Analyse.css';
import moment from 'moment';

function HeatmapLegend() {
    const legendItems = [
        { min: 1, max: 15, label: "bis 15 Min." },
        { min: 16, max: 30, label: "bis 30 Min." },
        { min: 31, max: 45, label: "bis 45 Min." },
        { min: 46, max: 60, label: "bis 60 Min." },
        { min: 61, max: 75, label: "bis 75 Min." },
        { min: 76, max: 90, label: "bis 90 Min." },
        { min: 91, max: 105, label: "bis 105 Min." },
        { min: 106, label: "über 105 Min." }
    ];

    return (
        <div className="heatmap-legend">
            {legendItems.map((item, index) => (
                <div key={index} className="legend-item">
                    <div className={`legend-color ${getDailyWorkloadHeatmapColor(item.min)}`}></div>
                    <div className="legend-label">{item.label}</div>
                </div>
            ))}
        </div>
    );
}

function DailySubjectWorkloadHeatmap({ subject, startDate, endDate, allEvents, title, loading }) {
    const data = calculateAverageSubjectMinutesPerHour(subject, startDate, endDate, allEvents);
    const hours = Array.from({ length: 12 }, (_, i) => i + 8);  // Stunden von 6:00 bis 23:00
    const days = ['S', 'M', 'D', 'M', 'D', 'F', 'S'];

    return (
        <>
            <h2>{title}</h2>
            <p>
                Die Abbildung zeigt, wie viele Terminminuten im Zeitraum
                vom {moment(startDate).format('DD.MM.YYYY')} bis
                zum {moment(endDate).format('DD.MM.YYYY')} pro Stunde durchschnittlich durch das
                Fach {subject} gelehrt werden.
            </p>
            <div className="calendar-heatmap-container">
            {loading ? <p>Lade Daten...</p> : (
                <>
                    <svg width="235" height="310" className="daily-subject-workload-heatmap">
                        {days.map((day, i) => (
                            <text key={`${day}-${i}`} x={23 * i + 70.5} y="20" textAnchor="middle">{day}</text>
                        ))}
                        {hours.map((hour, j) => (
                            <text key={hour} x="50" y={23 * j + 44} textAnchor="end">{`${hour} Uhr`}</text>
                        ))}

                        {data.map((dayData, i) => {
                            return hours.map((hour, j) => {
                                const value = dayData.hours[hour] || 0;
                                const color = getDailyWorkloadHeatmapColor(value);
                                return (
                                    <rect
                                        key={`${dayData.day}-${hour}`}
                                        x={23 * i + 60}  // Koordinatenkorrektur, um Überlappungen zu vermeiden
                                        y={23 * j + 30}  // Anpassung des y-Wertes um 45, um die Rechtecke unter der Stundenlegende zu positionieren
                                        width="20"
                                        height="20"
                                        className={color}
                                        rx="5"
                                        ry="5"
                                        data-tooltip-id="tooltip"
                                        data-tooltip-html={`${dayData.day}s<br />${hour} bis ${hour + 1} Uhr<br />${value.toFixed(0)} Min.`}
                                    />
                                );
                            });
                        })}
                    </svg>
                </>
            )}
            </div>
            <HeatmapLegend/>
        </>
    );
}

export default DailySubjectWorkloadHeatmap;
