import {db} from '../firebase';
import {collection, doc, getDocs} from 'firebase/firestore';

// Globale Variable zum Speichern der Räume
let roomCache = null;

export const loadRoomData = async (selectedSourceId) => {
    const sourceDataRef = doc(db, 'sourceData', selectedSourceId);
    const roomsCollection = collection(sourceDataRef, 'roomResources');
    const querySnapshot = await getDocs(roomsCollection);
    roomCache = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
    }));
    return roomCache;
}

function fetchRoomResources(roomIds) {
    if (!roomIds || roomIds.length === 0) {
        return roomCache;
    } else {
        return roomCache.filter(room => roomIds.includes(room.id));
    }
}

export const selectBestRoom = async (roomOptions, groupSize, courseType, campusOptions, subject, selectedSourceId) => {
    const rooms = await fetchRoomResources(roomOptions);

    // Filtern der Räume basierend auf den angegebenen Kriterien
    let filteredRooms = rooms
        .filter(room => room.active)  // Nur aktive Räume berücksichtigen
        .filter(room => room.allowedCourseTypesNames.includes(courseType))  // Überprüfe, ob der Raum für die Veranstaltungsart zugelassen ist
        .filter(room => room.allowedSubjectsNames.length === 0 || room.allowedSubjectsNames.includes(subject))  // Überprüfe, ob der Raum für das Fach zugelassen ist
        .filter(room => {
            const sizes = room.allowedGroupSizes.map(size => Number(size));
            return sizes.includes(groupSize);  // Überprüfe, ob die Gruppengröße explizit erlaubt ist
        })
        .filter(room => campusOptions.includes(room.campus[0]));  // Überprüfe, ob der Campus in den campusOptions enthalten ist

    const scoredRooms = filteredRooms.map(room => {
        let score = 0;
        return { room, score };
    });

    if (scoredRooms.length === 0) {
        console.error("Keine Räume entsprechen den Auswahlkriterien.");
        return null;
    }

    // Gesamtscore berechnen
    const totalScore = scoredRooms.reduce((sum, { score }) => sum + score, 0);

    // Gewichtete Zufallsauswahl
    let randomIndex = Math.random() * totalScore;
    for (let { room, score } of scoredRooms) {
        randomIndex -= score;
        if (randomIndex <= 0) {
            return room;
        }
    }

    // Fallback, falls kein Raum durch die Schleife ausgewählt wurde
    if (randomIndex > 0) {
        console.log("Kein Raum konnte nach gewichteter Zufallsauswahl gefunden werden, nehme letzten Raum in der Liste.");
        return scoredRooms[scoredRooms.length - 1].room;
    }
}

