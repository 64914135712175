// Globales Cache-Objekt
const functionCache = {};

// Exportiere Funktionen zum Zugriff auf den Cache
export const getCache = (key) => functionCache[key];
export const setCache = (key, value) => {
    functionCache[key] = value;
};
export const clearCache = () => {
    // console.log('Cache: ', functionCache);
    Object.keys(functionCache).forEach(key => delete functionCache[key]);
};
