// Farbskalen für Heatmaps
export const getWorkloadHeatmapColor = (value) => {
    if (value === 0) return 'color-empty';
    if (value >= 1 && value <= 2) return 'color-scale-1';
    if (value >= 3 && value <= 4) return 'color-scale-2';
    if (value >= 5 && value <= 6) return 'color-scale-3';
    if (value >= 7 && value <= 8) return 'color-scale-4';
    if (value >= 9 && value <= 10) return 'color-scale-5';
    if (value >= 11 && value <= 12) return 'color-scale-6';
    if (value >= 13 && value <= 14) return 'color-scale-7';
    if (value >= 15) return 'color-scale-8';
    return 'color-empty'; // Fallback für unerwartete Werte
}

export const getDailyWorkloadHeatmapColor = (value) => {
    if (value === 0) return 'color-empty';
    if (value > 0 && value <= 15) return 'color-scale-1';
    if (value > 15 && value <= 30) return 'color-scale-2';
    if (value > 30 && value <= 45) return 'color-scale-3';
    if (value > 45 && value <= 60) return 'color-scale-4';
    if (value > 60 && value <= 75) return 'color-scale-5';
    if (value > 75 && value <= 90) return 'color-scale-6';
    if (value > 90 && value <= 105) return 'color-scale-7';
    if (value > 105) return 'color-scale-8';
    return 'color-empty'; // Fallback für unerwartete Werte
}

export const getOverlapHeatmapColor = (value) => {
    if (value === 0) return 'color-empty';
    if (value === 1) return 'color-scale-1';
    if (value === 2) return 'color-scale-2';
    if (value === 3) return 'color-scale-3';
    if (value === 4) return 'color-scale-4';
    if (value === 5) return 'color-scale-5';
    if (value === 6) return 'color-scale-6';
    if (value === 7) return 'color-scale-7';
    if (value >= 8) return 'color-scale-8';
    return 'color-empty'; // Fallback für unerwartete Werte
}

// Ermittelt die maximale Anzahl gleichzeitiger Termine für ein Fach an einem Tag innerhalb des übermittelten Zeitraums
export const countMaxSubjectOverlaps = (subject, startDate, endDate, allEvents) => {

    if (!Array.isArray(allEvents)) {
        console.error('Invalid or undefined allEvents:', allEvents);
        return []; // oder eine andere geeignete Fehlerbehandlung
    }

    // Umwandlung der Start- und Enddaten in Date-Objekte für korrekten Vergleich
    const startDateTime = new Date(startDate);
    const endDateTime = new Date(endDate);

    const eventsByDate = {};

    // Filtern der Events nach Fach und Datumsbereich
    const filteredEvents = allEvents.filter(event => {
        const eventStartTime = new Date(event.start);
        const eventEndTime = new Date(event.end);
        return event.extendedProps.subject === subject &&
            eventStartTime >= startDateTime && eventEndTime <= endDateTime;
    });

    // Veranstaltungen in ein nach Datum organisiertes Format überführen
    filteredEvents.forEach(event => {
        const eventDate = event.start.split('T')[0];
        if (!eventsByDate[eventDate]) {
            eventsByDate[eventDate] = [];
        }
        eventsByDate[eventDate].push({
            start: new Date(event.start).getTime(),
            end: new Date(event.end).getTime() + (15 * 60000) // Puffer hinzufügen
        });
    });

    // Berechnen der maximalen Gleichzeitigkeit pro Tag
    return Object.keys(eventsByDate).map(date => {
        const events = eventsByDate[date];
        const timePoints = [];

        // Zeitpunkte aller Start- und Endzeiten sammeln
        events.forEach(event => {
            timePoints.push({time: event.start, type: 'start'});
            timePoints.push({time: event.end, type: 'end'});
        });

        // Zeitpunkte sortieren
        timePoints.sort((a, b) => a.time - b.time);

        // Maximale Gleichzeitigkeit berechnen
        let currentConcurrent = 0;
        let maxConcurrent = 0;
        timePoints.forEach(point => {
            if (point.type === 'start') {
                currentConcurrent++;
            } else {
                currentConcurrent--;
            }
            maxConcurrent = Math.max(maxConcurrent, currentConcurrent);
        });

        return {
            date: date,
            value: maxConcurrent
        };
    });
}

// Gibt die Anzahl der Termine pro Tag für ein Fach aus
export const countEventsPerDay = (subject, startDate, endDate, allEvents) => {
    // Umwandlung der Start- und Enddaten in Date-Objekte für korrekten Vergleich
    const startDateTime = new Date(startDate);
    const endDateTime = new Date(endDate);

    // Initialisierung eines Objekts zur Zählung der Termine pro Tag
    const eventCountByDate = {};

    // Filtern der Events nach Fach und Datumsbereich
    const filteredEvents = allEvents.filter(event => {
        const eventStartTime = new Date(event.start);
        const eventEndTime = new Date(event.end);
        return event.extendedProps.subject === subject &&
            eventStartTime >= startDateTime && eventEndTime <= endDateTime;
    });

    // Zählen der Termine pro Tag
    filteredEvents.forEach(event => {
        const eventStartDate = event.start.split('T')[0]; // Extrahieren des Datumsanteils (YYYY-MM-DD)

        if (!eventCountByDate[eventStartDate]) {
            eventCountByDate[eventStartDate] = 0;
        }
        eventCountByDate[eventStartDate]++;
    });

    // Umwandlung des Zählobjekts in das gewünschte Array-Format
    return Object.keys(eventCountByDate).map(date => ({
        date,
        value: eventCountByDate[date]
    }));
};


// Gibt die durchschnittlichen Minuten pro Zeitstunde im Tagesverlauf für ein Fach aus
export const calculateAverageSubjectMinutesPerHour = (subject, startDate, endDate, allEvents) => {
    // Initialisierung der Arrays für jeden Wochentag
    const days = Array.from({ length: 7 }, () => ({
        hours: Array(24).fill(0),
        counts: Array(24).fill(0)
    }));

    // Umwandlung der Start- und Enddaten in Date-Objekte für korrekten Vergleich
    const startDateTime = new Date(startDate);
    const endDateTime = new Date(endDate);

    // Filtern der Events nach Fach und Datumsbereich
    const filteredEvents = allEvents.filter(event => {
        const eventStartTime = new Date(event.start);
        const eventEndTime = new Date(event.end);
        return event.extendedProps.subject === subject &&
            eventStartTime >= startDateTime && eventEndTime <= endDateTime;
    });

    filteredEvents.forEach(event => {
        const eventStart = new Date(event.start);
        const eventEnd = new Date(event.end);

        const startHour = eventStart.getHours();
        const endHour = eventEnd.getHours();
        const dayOfWeek = eventStart.getDay(); // 0 ist Sonntag, 1 ist Montag, ..., 6 ist Samstag

        for (let hour = startHour; hour <= endHour; hour++) {
            let startMinute = hour === startHour ? eventStart.getMinutes() : 0;
            let endMinute = hour === endHour ? eventEnd.getMinutes() : 59;

            // Adjust the endMinute if the event ends exactly at the hour
            if (hour === endHour && eventEnd.getMinutes() === 0 && eventEnd.getSeconds() === 0) {
                endMinute = 59;  // Set to 59 to avoid counting an additional minute
            }

            const minutesInHour = (hour === endHour && eventEnd.getMinutes() === 0 && eventEnd.getSeconds() === 0) ? 0 : endMinute - startMinute + 1;

            days[dayOfWeek].hours[hour] += minutesInHour;
            days[dayOfWeek].counts[hour]++;
        }
    });

    // Berechnen der Durchschnittswerte für jede Stunde und jeden Tag
    return days.map((day, index) => {
        const averages = day.hours.map((total, hourIndex) => {
            return day.counts[hourIndex] > 0 ? total / day.counts[hourIndex] : 0;
        });
        return {
            day: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'][index],
            hours: averages.reduce((acc, avg, hour) => {
                acc[hour] = avg;
                return acc;
            }, {})
        };
    });
};
