import config from '../../config';

// Funktion zur Berechnung der vorlesungsfreien Zeiten
export function generateLectureFreePeriodEvents() {
    const breakEvents = [];

    const winterSemester = config.lecturePeriods.lecturePeriodWinter;
    const summerSemester = config.lecturePeriods.lecturePeriodSummer;

    // Vorlesungsfreie Zeit vor dem Wintersemester
    breakEvents.push({
        title: 'Vorlesungsfreie Zeit',
        start: '2025-01-01',
        end: winterSemester.startDate,
        display: 'background',
        color: 'rgba(49, 56, 61, 0.2)'
    });

    // Vorlesungsfreie Zeit zwischen den Semestern
    breakEvents.push({
        title: 'Vorlesungsfreie Zeit',
        start: new Date(new Date(winterSemester.endDate).getTime() + (1000 * 60 * 60 * 24)).toISOString().split('T')[0],
        end: summerSemester.startDate,
        display: 'background',
        color: 'rgba(49, 56, 61, 0.2)'
    });

    // Vorlesungsfreie Zeit nach dem Sommersemester
    breakEvents.push({
        title: 'Vorlesungsfreie Zeit',
        start: new Date(new Date(summerSemester.endDate).getTime() + (1000 * 60 * 60 * 24)).toISOString().split('T')[0],
        end: '2026-12-31',
        display: 'background',
        color: 'rgba(49, 56, 61, 0.2)'
    });

    return breakEvents;
}
