import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { currentUser, login } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser) {
            navigate('/dashboard'); // Leitet automatisch zum Dashboard um, wenn bereits eingeloggt
        }
    }, [currentUser, navigate]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await login(email, password);
            navigate('/dashboard');
        } catch (error) {
            console.error("Login failed: ", error);
        }
    };

    return (
        <div className="page-content no-sidebar">
            <div className="page-item">
                <h2>Login</h2>
                <form onSubmit={handleSubmit}>
                    <label>E-Mail-Adresse</label>
                    <input type="email" value={email} autoComplete="email" onChange={e => setEmail(e.target.value)}/>
                    <label>Passwort</label>
                    <input type="password" value={password} autoComplete="current-password"
                           onChange={e => setPassword(e.target.value)}/>
                    <button type="submit">Login</button>
                </form>
            </div>
        </div>
    );
}

export default Login;
