import React, {useEffect, useState} from 'react';
import SolutionSelector from "../SolutionSelector/SolutionSelector";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { fetchSolution } from "../../../utilities/utilityFunctions";
import {
    calculateSingleSoftConstraintCosts,
    calculateSoftConstraintCosts,
    fetchSoftConstraints
} from "../../../utilities/utilityConstraintFunctions";

function SoftConstraintCostCalculator() {

    const [solutionList, setSolutionList] = useState([]);
    const [solutionId, setSolutionId] = useState(null);
    const [selectedSolutionDetails, setSelectedSolutionDetails] = useState(null);
    const [courseObject, setCourseObject] = useState(null);
    const [softConstraints, setSoftConstraints] = useState([]);
    const [loadingConstraints, setLoadingConstraints] = useState({});

    useEffect(() => {
        async function fetchSolutionDetails(solutionId) {
            if (solutionId && !selectedSolutionDetails) {
                try {
                    const docRef = doc(db, 'solutions', solutionId);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        setSelectedSolutionDetails(docSnap.data());
                    }

                    // Veranstaltungsobjekt abrufen
                    const solutionData = await fetchSolution(solutionId);
                    setCourseObject(solutionData.courseObject);

                    // Soft constraints laden
                    const constraints = await fetchSoftConstraints();
                    const sortedConstraints = constraints.sort((a, b) => {
                        // Sortiere zuerst nach Vorhandensein der `function`, dann nach `weighting`
                        if (a.function && !b.function) {
                            return -1;
                        } else if (!a.function && b.function) {
                            return 1;
                        } else {
                            return b.weighting - a.weighting; // Absteigende Sortierung, für aufsteigende umdrehen
                        }
                    });
                    setSoftConstraints(sortedConstraints);

                } catch (error) {
                    console.error("Fehler beim Laden der Lösung: ", error);
                }
            }
        }

        fetchSolutionDetails(solutionId);
    }, [solutionId, selectedSolutionDetails]);

    const handleCalculation = async (constraint) => {
        if (!courseObject) {
            console.error('Kein courseObject vorhanden.');
            return;
        }

        try {
            setLoadingConstraints(prev => ({ ...prev, [constraint.id]: true }));

            const result = await calculateSoftConstraintCosts(courseObject, [constraint.id], true);
            console.log('Ergebnis der Kostenberechnung: ', result);

            if (!result) {
                throw new Error("Das Berechnungsergebnis ist null");
            }

            setSoftConstraints(prevConstraints =>
                prevConstraints.map(item =>
                    item.id === constraint.id
                        ? {
                            ...item,
                            result: result.costsPerConstraint[constraint.id] || { error: 'Keine Daten verfügbar' }
                        }
                        : item
                )
            );
        } catch (error) {
            console.error('Fehler bei der Kostenberechnung: ', error);
            setSoftConstraints(prevConstraints =>
                prevConstraints.map(item =>
                    item.id === constraint.id
                        ? { ...item, result: { error: error.message } }
                        : item
                )
            );
        } finally {
            setLoadingConstraints(prev => ({ ...prev, [constraint.id]: false }));
        }
    };

    return (
        <div className="page-item page-item-fullwidth">
            <h2>Soft-Constraint-Kosten berechnen</h2>
            <div>
                <SolutionSelector
                    setSolutionList={setSolutionList}
                    onSelect={setSolutionId}
                />
            </div>
            {selectedSolutionDetails && (
                <>
                    <div>
                        <table className="">
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Beschreibung</th>
                                <th>Funktion</th>
                                <th>Gewichtung</th>
                                <th>Kosten</th>
                                <th>&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody>
                            {softConstraints.map(constraint => (
                                <tr key={constraint.id}>
                                    <td>{constraint.id}</td>
                                    <td>{constraint.name}</td>
                                    <td>{constraint.description}</td>
                                    <td>{constraint.function}</td>
                                    <td>{constraint.weighting}</td>
                                    <td>
                                        {constraint.function ? (
                                            loadingConstraints[constraint.id] ? (
                                                "Lädt ..."
                                            ) : constraint.result ? (
                                                Math.round(constraint.result.totalCost)
                                            ) : null
                                        ) : null }
                                    </td>
                                    <td>
                                        {constraint.function ? (
                                            <button onClick={() => handleCalculation(constraint)}>
                                                berechnen
                                            </button>
                                        ) : null}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </div>
    );
}

export default SoftConstraintCostCalculator;
