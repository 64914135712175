import {checkHardConstraints, fetchConstraints} from "../utilityConstraintFunctions";
import { clearCache } from "../constraintCacheManager";
import {flattenCourseObject, isCourseToBeScheduled, restructureCourseObject} from "../utilityFunctions";
import {
    assignRoomsToEvents,
    calculateAverageSchedulingRange, getProposedStartDate,
    groupEvents,
    scheduleEvents
} from "./initialSolutionUtilityFunctions";
import { loadRoomData } from "../utilityRoomFunctions";
import { loadDistanceData } from "../utilityDistanceFunctions";
import {addStaticPlanning} from "./addStaticPlanning";
import cloneDeep from "lodash/cloneDeep";

// Globale Variable für Distanzen
let globalDistances = null;

// Hauptfunktion zur Generierung der Anfangslösung
export const createInitialSolution = async (courseObject, selectedSourceId, processId) => {

    if (!courseObject) {
        console.error("Kein gültiges courseObject übergeben.");
        return [];
    }

    // Prüfe, ob eine Prozess-ID in den Daten vorhanden ist
    if (!processId) {
        console.error('invalid-argument', 'Es wurde keine Prozess-ID übergeben.');
        return [];
    }

    // Vorbereitung: Constraints laden und Cache leeren
    clearCache();
    const constraints = await fetchConstraints(true, true, true);

    // Lade alle Raum- und Entfernungsdaten
    let roomCache = await loadRoomData(selectedSourceId);
    console.log('roomCache: ', roomCache);

    globalDistances = await loadDistanceData(globalDistances, selectedSourceId);
    // console.log('globalDistances: ', globalDistances);

    // Schritt 1: Vorbereitung der Daten
    const validCourses = courseObject.filter(isCourseToBeScheduled)
        .map(course => ({
            ...course,
            averageRange: calculateAverageSchedulingRange(course.eventGroups.flatMap(group => group.events)),
            groupSize: course.groupSize,
            eventGroupsLength: course.eventGroups.length
        }))
        .sort((a, b) => {
            // Zuerst nach averageRange sortieren
            if (a.averageRange !== b.averageRange) {
                return a.averageRange - b.averageRange; // Aufsteigend sortieren nach averageRange
            }
            // Wenn averageRange gleich ist, nach groupSize sortieren
            if (a.groupSize !== b.groupSize) {
                return b.groupSize - a.groupSize; // Absteigend sortieren nach groupSize
            }
            // Wenn groupSize auch gleich ist, nach der Länge von eventGroups sortieren
            return b.eventGroupsLength - a.eventGroupsLength; // Absteigend sortieren nach der Länge von eventGroups
        });

    let allEvents = flattenCourseObject(validCourses);
    const originalAllEvents = cloneDeep(allEvents);
    // console.log(originalAllEvents);

    // Schritt 2: Statische Planungsdaten hinzufügen
    allEvents = addStaticPlanning(allEvents, roomCache);

    // Zwischendurch: Hard constraints prüfen
    async function checkConstraintsOnStaticPlanning() {
        for (const event of allEvents) {
            const checkResult = await checkHardConstraints(event, allEvents, constraints);
            if (!checkResult.success) {
                console.log(checkResult);
            }
        }
    }
    // await checkConstraintsOnStaticPlanning();

    // Schritt 3: Zeitliche Planung für jedes Semester
    await scheduleEvents(allEvents, constraints, processId);

    // Schritt 4: Raumzuweisung nach der zeitlichen Planung
    // await assignRoomsToEvents(allEvents, selectedSourceId, constraints, roomCache, processId);

    // Schritt 5: Veranstaltungsobjekt wiederherstellen
    const scheduledCourseObject = restructureCourseObject(allEvents, courseObject);

    // Schritt 6: Validierung
    // const checkDistances = considerTravelTimes(allEvents, globalDistances);

    // Ausgabe der Ergebnisse
    return {
        courseObject: scheduledCourseObject, // Das aktualisierte courseObject mit zugewiesenen Zeiten und Räumen
        totalEvents: allEvents.length
    };

};
