import { selectBestRoom } from "../utilityRoomFunctions";
import { checkGroupConstraints, checkHardConstraints } from "../utilityConstraintFunctions";
import { checkIfStopped } from "../processControlling";
import cloneDeep from "lodash/cloneDeep";
import {getRandomSchedulingConfig, isHoliday} from "../utilityFunctions";
import moment from "moment-timezone";
import config from "../../config";

// Termine nach Semester, Veranstaltungstitel und Gruppe gruppieren
export const groupEvents = (events) => {
    return events.reduce((acc, event) => {
        const semester = event.extendedProps.schedulingConfig[0].semester;
        const title = event.title;
        const group = event.extendedProps.group;

        // Stelle sicher, dass die entsprechenden Strukturen vorhanden sind
        if (!acc[semester]) acc[semester] = {};
        if (!acc[semester][title]) acc[semester][title] = {};
        if (!acc[semester][title][group]) acc[semester][title][group] = [];

        // Füge das Event zur richtigen Gruppe hinzu
        acc[semester][title][group].push(event);

        return acc;
    }, {});
}

// Berechnung der durchschnittlichen Länge der Zeitfenster in den Planungskonfigurationen für eine Veranstaltung
export const calculateAverageSchedulingRange = (events) => {
    let totalRange = 0;
    let configCount = 0;

    events.forEach(event => {
        event.extendedProps.schedulingConfig.forEach(config => {
            const start = new Date(config.earliestDate);
            const end = new Date(config.latestDate);
            const diff = (end - start) / (1000 * 3600 * 24); // Differenz in Tagen
            totalRange += diff;
            configCount++;
        });
    });

    return configCount > 0 ? (totalRange / configCount).toFixed(1) : 0;
}

/*
// Zeitzuweisung
*/

// Zeitplanung für jedes Event im gegebenen Semester
export const scheduleEvents = async (allEvents, constraints, processId) => {

    const originalEvents = cloneDeep(allEvents);  // Speichere eine tiefe Kopie der ursprünglichen Events

    // Performancemessung und Initialisierung Variablen
    let processedEvents = 0;
    let performanceStart = performance.now(); // Startzeit für die ersten 100 Iterationen
    let performanceBatchSize = 100; // Standard: 100
    let countFailedAttempts = 0;
    let checkResult = null;
    const maxFailedAttempts = 1000;

    // Hauptschleife Zuordnung Zeiten
    for (const event of allEvents) {
        if (!event.start || !event.end) {
            try {

                const maxAttempts = 500;  // Gesamtanzahl der Versuche für den gesamten Try-Block
                const maxFirstAttempts = Math.floor(maxAttempts * 0.3);  // 30 % der Versuche für die erste Schleife
                let totalAttempts = 0;
                let successful = false;

                // Überprüfen, ob die Simulation gestoppt werden soll
                if (await checkIfStopped(processId) === true) {
                    allEvents = originalEvents;
                    return {
                        result: 'stopped',
                        processId
                    };
                }

                while (!successful && totalAttempts < maxFirstAttempts) {
                    totalAttempts++;
                    // Prüfen, ob Termine derselben Veranstaltung und Gruppe mit Startzeit existieren
                    const similarEvents = allEvents.filter(e =>
                        e.extendedProps.group === event.extendedProps.group &&
                        e.extendedProps.id.split('.')[0] === event.extendedProps.id.split('.')[0] &&
                        e.start
                    );

                    // Versuche Verschiebung um eine zufällige Anzahl an Wochen
                    if (similarEvents.length > 0) {
                        const schedulingConfig = getRandomSchedulingConfig(event);
                        const newStartDate = await findWeeklyShiftedDate(similarEvents, schedulingConfig.earliestDate, schedulingConfig.latestDate);

                        if (newStartDate) {
                            const newEndDate = new Date(newStartDate.getTime() + event.extendedProps.eventDuration * 60000);

                            // Prüfen der Hard Constraints für das neue Datum
                            const tempEvent = {
                                ...event,
                                start: newStartDate.toISOString(),
                                end: newEndDate.toISOString(),
                                extendedProps: {
                                    ...event.extendedProps,
                                    semester: schedulingConfig.semester
                                }
                            };

                            checkResult = await checkHardConstraints(tempEvent, allEvents, constraints);
                            if (checkResult.success) {
                                // console.log(`Wöchentliche Verschiebung nach ${totalAttempts} Versuchen`);
                                event.start = newStartDate.toISOString();
                                event.end = newEndDate.toISOString();
                                event.extendedProps.semester = schedulingConfig.semester;
                                successful = true;  // Abbruch der Schleife, wenn erfolgreich
                            }
                        }
                    }
                }

                // Weiter mit konventioneller Zeitplanung, wenn wöchentliche Verschiebung fehlschlägt
                while (!successful && totalAttempts < maxAttempts) {
                    totalAttempts++;

                    // Initiierung Planungsvariablen
                    let eventHoursStart = config.workHours.startHour;
                    let eventHoursEnd = config.workHours.endHour;
                    let schedulingConfig = getRandomSchedulingConfig(event);

                    const excludeDays = schedulingConfig.excludeDays ? schedulingConfig.excludeDays : [0];

                    const proposedStartDate = getProposedStartDate(
                        new Date(schedulingConfig.earliestDate),
                        new Date(schedulingConfig.latestDate),
                        eventHoursStart,
                        eventHoursEnd,
                        event.extendedProps.eventDuration,
                        excludeDays
                    );
                    const proposedEndDate = new Date(proposedStartDate.getTime() + event.extendedProps.eventDuration * 60000);

                    // Erstellen eines temporären Events zur Prüfung der Constraints
                    const tempEvent = {
                        ...event,
                        start: proposedStartDate.toISOString(),
                        end: proposedEndDate.toISOString(),
                        extendedProps: {
                            ...event.extendedProps,
                            semester: schedulingConfig.semester
                        }
                    };

                    // Prüfen der Hard Constraints
                    checkResult = await checkHardConstraints(tempEvent, allEvents, constraints);
                    if (checkResult.success) {
                        // console.log(`Konventionelle Verschiebung nach ${totalAttempts} Versuchen`);
                        event.start = proposedStartDate.toISOString();
                        event.end = proposedEndDate.toISOString();
                        event.extendedProps.semester = schedulingConfig.semester;
                        successful = true;  // Abbruch der Schleife, wenn erfolgreich
                    }
                }

                if (!successful) {
                    countFailedAttempts++;
                    console.error("Zeitzuweisung fehlgeschlagen:", event);
                    console.log(`Details Hard-Constraint-Überprüfung: ${JSON.stringify(checkResult, null, 2)}`);

                    if (countFailedAttempts >= maxFailedAttempts) {
                        console.error("Maximale Anzahl fehlgeschlagener Versuche erreicht.");
                        // break;  // Abbruch der gesamten Schleife, wenn zu viele Fehlschläge
                    }
                }
            } catch (error) {
                console.error("Fehler bei der Planung des Events:", error);
            }
        }

        // Performancemessung
        processedEvents++;
        if (processedEvents % performanceBatchSize === 0) {
            let now = performance.now();
            let duration = now - performanceStart;
            let averageDurationPerIteration = duration / performanceBatchSize;
            console.log(`${Math.round((processedEvents / allEvents.length) * 100)} % (${processedEvents} von ${allEvents.length} Terminen)`);
            console.log(`${averageDurationPerIteration.toFixed(2)} ms pro Termin`);
            console.log(`Bisher ${countFailedAttempts} fehlgeschlagene Versuche`);
            performanceStart = now;  // Reset der Startzeit für die nächsten batchSize Iterationen
        }

    }
}

// Hilfsfunktion zum Auffinden eines passenden Datums mit wöchentlicher Verschiebung
async function findWeeklyShiftedDate(similarEvents, earliestDate, latestDate, maxAttempts = 10) {
    for (let attempt = 0; attempt < maxAttempts; attempt++) {
        const randomEvent = similarEvents[Math.floor(Math.random() * similarEvents.length)];
        const weeksOffset = (Math.floor(Math.random() * 31) - 15) * 7 * 24 * 60 * 60 * 1000; // -15 bis +15 Wochen in Millisekunden
        const newStartDate = new Date(new Date(randomEvent.start).getTime() + weeksOffset);

        // Überprüfen, ob das neue Datum innerhalb der erlaubten Grenzen liegt
        if (newStartDate >= new Date(earliestDate) && newStartDate <= new Date(latestDate)) {
            return newStartDate;
        }
    }
    return null; // Kein gültiges Datum gefunden
}

// Generierung eines gewichteten, zufälligen Zeitraums zur vollen Viertelstunde
export const getProposedStartDate = (
    startDate,
    endDate,
    startHour,
    endHour,
    eventDuration = 90,
    excludeDays = [0]
) => {
    let start = new Date(startDate);
    let end = new Date(endDate);
    // start.setHours(0, 0, 0, 0); // Setzt die Zeit auf Mitternacht

    let validQuarters = [];
    let weights = [];

    while (start <= end) {
        if (!excludeDays.includes(start.getDay())) {  // Prüfe, ob der Tag ausgeschlossen werden soll
            let dayStart = new Date(start);
            dayStart.setHours(startHour, 0, 0, 0);
            let dayEnd = new Date(start);
            dayEnd.setHours(endHour, 0, 0, 0);

            let effectiveEnd = new Date(dayEnd.getTime() - eventDuration * 60000);

            // Füge alle gültigen Viertelstundenintervalle des Tages hinzu, wenn der Tag nicht ausgeschlossen ist
            while (dayStart <= effectiveEnd) {
                validQuarters.push(new Date(dayStart));
                // Gewichtung hinzufügen
                let weight = 1;
                // Volle Stunden stärker gewichten
                if (dayStart.getMinutes() === 0) {
                    weight *= 6; // Gewichtungsfaktor für volle Stunden
                }
                // Exponentielle Gewichtung für frühere Stunden
                weight *= Math.pow((endHour - dayStart.getHours()) + 1, 2);
                weights.push(weight);

                dayStart.setMinutes(dayStart.getMinutes() + 15);
            }
        }
        start.setDate(start.getDate() + 1);
    }

    // Gewichtete Auswahl
    if (validQuarters.length > 0) {
        let weightedIndex = weightedRandomChoice(weights);
        return validQuarters[weightedIndex];
    } else {
        throw new Error("Keine gültigen Viertelstunden-Intervalle gefunden");
    }
}

function weightedRandomChoice(weights) {
    let totalWeight = weights.reduce((a, b) => a + b, 0);
    let randomNum = Math.random() * totalWeight;
    let weightSum = 0;

    for (let i = 0; i < weights.length; i++) {
        weightSum += weights[i];
        if (randomNum < weightSum) {
            return i;
        }
    }
    return -1;
}


/*
// Raumzuweisung
*/

// Raumzuweisung für alle Termine
export const assignRoomsToEvents = async (allEvents, selectedSourceId, constraints, roomCache, processId) => {

    // Performancemessung und Initialisierung Variablen
    let processedEvents = 0;
    let performanceStart = performance.now(); // Startzeit für die ersten 100 Iterationen
    let performanceBatchSize = 100; // Standard: 100
    let countFailedAttempts = 0;
    const maxFailedAttempts = 100; // sollte später 1 sein

    // Hauptschleife Zuordnung Räume
    for (const event of allEvents) {
        processedEvents++;

        let attempts = 0;
        const maxAttempts = 250;
        let successful = false;

        if (
            event.extendedProps.courseType === 'UaKU' ||
            event.extendedProps.courseType === 'UaKD' ||
            event.extendedProps.courseType === 'UaKU (Blockpraktikum)' ||
            event.extendedProps.campusOptions.length === 0 ||
            (event.locations && Array.isArray(event.locations) && event.locations.length > 0)
        ) {
            continue;
        }

        while (!successful && attempts < maxAttempts) {
            attempts++;

            // Überprüfen, ob die Simulation gestoppt werden soll
            if (await checkIfStopped(processId) === true) {
                return {
                    result: 'stopped',
                    processId
                };
            }

            // Planungskonfiguration zufällig wählen
            let schedulingConfig = getRandomSchedulingConfig(event);

            const room = await selectBestRoom(
                schedulingConfig.roomOptions,
                event.extendedProps.groupSize,
                event.extendedProps.courseType,
                event.extendedProps.campusOptions,
                event.extendedProps.subject,
                selectedSourceId
            );

            // Erstellen eines temporären Events zur Prüfung der Constraints
            if (room) {
                const tempEvent = {
                    ...event,
                    locations: [{
                        roomId: room.id,
                        roomName: room.name || "NN",
                        buildingId: room.building && room.building.length > 0 ? room.building[0] : "Unbekannt",
                        buildingName: room.buildingName && room.buildingName.length > 0 ? room.buildingName[0] : "Unbekanntes Gebäude",
                        campus: room.campus && room.campus.length > 0 ? room.campus[0] : "Unbekannter Campus"
                    }]
                };

                let checkResult = await checkHardConstraints(tempEvent, allEvents, constraints, roomCache);
                if (checkResult.success) {
                    // event.locations = tempEvent.locations;
                    Object.assign(event, tempEvent);
                    successful = true;
                } else {
                    console.log(`Details Hard-Constraint-Überprüfung: ${JSON.stringify(checkResult, null, 2)}`);
                }
            } else {
                console.error("Kein Raum gefunden für:", event);
                break; // Kein Raum verfügbar, breche Versuche ab
            }
        }

        if (!successful) {
            countFailedAttempts++;
            console.error("Raumzuweisung fehlgeschlagen:", event);

            if (countFailedAttempts >= maxFailedAttempts) {
                console.error("Maximale Anzahl fehlgeschlagener Versuche erreicht.");
                break; // Abbruch der gesamten Schleife, wenn zu viele Fehlschläge
            }
        }

        if (processedEvents % performanceBatchSize === 0) {
            let now = performance.now();
            let duration = now - performanceStart;
            let averageDurationPerIteration = duration / performanceBatchSize;
            console.log(`${Math.round((processedEvents / allEvents.length) * 100)} % (${processedEvents} von ${allEvents.length} Raumzuweisungen)`);
            console.log(`${averageDurationPerIteration.toFixed(2)} ms pro Raumzuweisung`);
            performanceStart = now;  // Reset der Startzeit für die nächsten batchSize Iterationen
        }
    }
}