import React, { useEffect, useState } from 'react';
import {clearCache} from "../../../utilities/constraintCacheManager";
import {fetchConstraints} from "../../../utilities/utilityConstraintFunctions";

function HardConstraintSelector({ onSelect, hardConstraintId }) {
    const [hardConstraintList, setHardConstraintList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const loadConstraints = async () => {
            try {
                clearCache();
                setIsLoading(true);
                const constraints = await fetchConstraints(true, true, true);
                setHardConstraintList(constraints);
                setIsLoading(false);
            } catch (error) {
                console.error("Error loading constraints: ", error);
                setIsLoading(false);
            }
        };

        loadConstraints();
    }, []);

    const handleSelectChange = (e) => {
        const selectedId = e.target.value;
        const selectedConstraint = hardConstraintList.find(constraint => constraint.id === selectedId);
        onSelect(selectedConstraint);
    };

    return (
        <div>
            <label htmlFor="hardConstraintSelect">Hard Constraint</label>
            <select id="hardConstraintSelect" value={hardConstraintId ? hardConstraintId : ''} onChange={handleSelectChange} disabled={isLoading}>
                <option value="">Bitte wählen ...</option>
                {hardConstraintList.map(hardConstraint => (
                    <option key={hardConstraint.id} value={hardConstraint.id}>{hardConstraint.name}</option>
                ))}
            </select>
        </div>
    );
}

export default HardConstraintSelector;
