import React, { useState, useEffect, useRef } from 'react';
import { dbRT, ref, onValue, remove } from "../../../firebase";

function ProcessController({
        processId,
        onStart,
        onStop,
        progress,
        logs,
        onSaveAndAnalyze,
        onDiscard,
        title = "Prozess durchführen",
        startButtonLabel = "Prozess starten"
    }) {
    const [localProgress, setLocalProgress] = useState(progress);
    const [localLogs, setLocalLogs] = useState(logs);
    const [isRunning, setIsRunning] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isDiscardEnabled, setIsDiscardEnabled] = useState(false);

    // Ref für die logs-container Div
    const logsContainerRef = useRef(null);

    useEffect(() => {
        // Listener für den Fortschritt
        if (processId) {
            const progressRef = ref(dbRT, `processes/${processId}/progress`);
            const logsRef = ref(dbRT, `processes/${processId}/logs`);

            // Listen for progress updates
            const progressListener = onValue(progressRef, (snapshot) => {
                const progressData = snapshot.val();
                setLocalProgress(progressData ? progressData.value : 0);
                setIsRunning(progressData ? progressData.value > 0 && progressData.value < 100 : false);
                setIsCompleted(progressData ? progressData.value >= 100 : false);
                setIsDiscardEnabled(progressData ? progressData.value > 0 : false);
            });

            // Listen for log updates
            const logsListener = onValue(logsRef, (snapshot) => {
                const logsData = snapshot.val();
                setLocalLogs(logsData ? Object.values(logsData) : []);
            });

            // Clean up listeners on unmount
            return () => {
                progressListener();
                logsListener();
            };
        }
    }, [processId]);

    useEffect(() => {
        if (logsContainerRef.current) {
            // Scrollt zum unteren Rand
            logsContainerRef.current.scrollTop = logsContainerRef.current.scrollHeight;
        }
    }, [localLogs]);

    const handleButtonClick = () => {
        setIsButtonDisabled(true);
        if (isRunning) {
            onStop();
        } else {
            onStart();
        }
    };

    useEffect(() => {
        if (isRunning) {
            setIsButtonDisabled(false);
        }
    }, [isRunning]);

    const handleDiscard = () => {
        setIsButtonDisabled(false);
        setIsRunning(false);
        setIsCompleted(false);
        setIsDiscardEnabled(false);
        setLocalProgress(0);
        setLocalLogs([]);
        if (processId) {
            // Entfernt die Unterkollektion processes/${processId}/
            const processRef = ref(dbRT, `processes/${processId}`);
            remove(processRef)
                .then(() => {
                    console.log(`Process ${processId} gelöscht.`);
                })
                .catch((error) => {
                    console.error(`Fehler beim Löschen des Prozesses ${processId}:`, error);
                });
        }
        onDiscard();
    };

    return (
        <>
            <h3>{title}</h3>
            <div className="page-item-subitem progress-container">
                <button
                    type="button"
                    onClick={handleButtonClick}
                    disabled={isButtonDisabled || isCompleted}
                    className={isRunning ? "stop-button" : ""}
                >
                    {isRunning ? "Prozess stoppen" : startButtonLabel}
                </button>
                <progress value={localProgress} max="100"></progress>
                <span>{localProgress}&#8198;% abgeschlossen</span>
                <div className="logs-container" ref={logsContainerRef}>
                    {localLogs.map((log, index) => (
                        <div key={index} className="log-entry">
                            <span className="log-time">{log.time}</span>
                            <span
                                className="log-message"
                                dangerouslySetInnerHTML={{__html: log.message}}
                            />
                        </div>
                    ))}
                </div>
                <div className="grid-buttons">
                    <button className="stop-button" type="button" onClick={handleDiscard} disabled={!isDiscardEnabled}>
                        Verwerfen
                    </button>
                    <button type="button" onClick={onSaveAndAnalyze} disabled={!isCompleted}>
                        Speichern
                    </button>
                </div>
            </div>
        </>
    );
}

export default ProcessController;
