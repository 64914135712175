import React from 'react';
import {NavLink} from "react-router-dom";

function Dashboard() {

    return (
        <div>
            <div className="page-content no-sidebar">
                <div className="page-item colspan">
                    <h2>Musterstundenplan der Medizinischen Fakultät OWL</h2>
                    <ul className="standard">
                        <li>
                            <strong><NavLink to="/datenbasis">Datenbasis</NavLink>:</strong><br/>
                            Import der Datenbasis aus Airtable und Generierung einer Anfangs-Stundenplanlösung
                        </li>
                        <li>
                            <strong><NavLink to="/simulation">Simulation</NavLink>:</strong><br/>
                            Optimierung einer bestehenden Stundenplanlösung durch Simulated Annealing
                        </li>
                        <li>
                            <strong><NavLink to="/ergebnis">Ergebnis</NavLink>:</strong><br/>
                            Kalender- und Listenansicht einer bestehenden Stundenplanlösung
                        </li>
                        <li>
                            <strong><NavLink to="/analyse">Analyse</NavLink>:</strong><br/>
                            Vergleich von Stundenplanlösungen, fachbezogene Auswertungen und Validierung der Erfüllung von Anforderungen
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
