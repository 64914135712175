import { initializeApp } from 'firebase/app';
import { getFirestore, writeBatch } from 'firebase/firestore';
import { getDatabase, ref, set, get, push, serverTimestamp, onValue, update, remove } from 'firebase/database';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// Initialisiere Firebase
const app = initializeApp(firebaseConfig);

// Erhalte eine Instanz von Firestore
export const db = getFirestore(app);

// Firebase Functions
const functions = getFunctions(app);

// Erhalte eine Instanz von Firebase Auth
const auth = getAuth(app);

// Realtime Database
const dbRT = getDatabase(app);

// Entwicklungsumgebung
if (process.env.NODE_ENV === 'development') {
    // Verbinde mit Firebase Emulatoren
    connectFunctionsEmulator(functions, "localhost", 5001);
    // connectFirestoreEmulator(db, "localhost", 8080);
    // connectAuthEmulator(auth, "http://localhost:9099");
}

// Export
export { auth, signInWithEmailAndPassword, signOut, functions, dbRT, ref, set, get, push, serverTimestamp, onValue, update, remove, writeBatch };