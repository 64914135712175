import React, {useState} from 'react';
import { AuthProvider, useAuth } from './AuthContext';
import { BrowserRouter as Router, Routes, Route, Navigate, NavLink } from 'react-router-dom';
import './App.css';

import Dashboard from './components/Dashboard';
import Datenbasis from './components/Datenbasis/Datenbasis';
import Anforderungen from './components/Anforderungen';
import Simulation from './components/Simulation';
import Ergebnis from './components/Ergebnis';
import Login from './components/Login';
import Analyse from './components/Analyse/Analyse';
import AnalyseHome from "./components/Analyse/AnalyseHome";
import AnalyseKostenvergleich from "./components/Analyse/AnalyseKostenvergleich";
import AnalyseFachbezogen from "./components/Analyse/AnalyseFachbezogen";
import {CloseLineIcon, MenuLineIcon} from "./components/assets/Icons";
import AnalyseValidierung from "./components/Analyse/AnalyseValidierung";

function App() {
  return (
      <AuthProvider>
        <Router>
          <AuthenticatedApp />
        </Router>
      </AuthProvider>
  );
}

function AuthenticatedApp() {

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    const { currentUser, logout } = useAuth();
    const handleLogout = async () => {
        try {
            await logout();
        } catch (error) {
            console.error("Fehler beim Logout: ", error);
        }
    };

  return (
    <div className="container">
        <div className="navbar">
            <div className="logo">Siman<sup>B+T</sup></div>
            <button onClick={toggleMenu} className="nav-toggle icon-button">
                {isMenuOpen ? <CloseLineIcon color="rgba(161, 179, 195, 1)"/> : <MenuLineIcon color="rgba(161, 179, 195, 1)"/>}
            </button>
            <ul className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
                {currentUser && (
                    <>
                        <li><NavLink to="/dashboard" activeClassName="active">Dashboard</NavLink></li>
                        <li><NavLink to="/datenbasis" activeClassName="active">Datenbasis</NavLink></li>
                        <li><NavLink to="/simulation" activeClassName="active">Simulation</NavLink></li>
                        <li><NavLink to="/ergebnis" activeClassName="active">Ergebnis</NavLink></li>
                        <li><NavLink to="/analyse" activeClassName="active">Analyse</NavLink></li>
                        <li><NavLink to="/" onClick={handleLogout} activeClassName="active">Logout</NavLink></li>
                    </>
                )}
             </ul>
        </div>
        <Routes>
            <Route path="/" element={<Navigate replace to="/login"/>}/>
            <Route path="/login" element={<Login/>} />
            <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="/datenbasis" element={<PrivateRoute><Datenbasis /></PrivateRoute>} />
            <Route path="/simulation" element={<PrivateRoute><Simulation /></PrivateRoute>} />
            <Route path="/ergebnis" element={<PrivateRoute><Ergebnis /></PrivateRoute>} />
            <Route path="/analyse" element={<PrivateRoute><Analyse /></PrivateRoute>}>
                <Route index element={<AnalyseHome />} />
                <Route path="kostenvergleich" element={<AnalyseKostenvergleich />} />
                <Route path="fachbezogen" element={<AnalyseFachbezogen />} />
                <Route path="validierung" element={<AnalyseValidierung />} />
            </Route>
        </Routes>
    </div>
  );
}

function PrivateRoute({ children }) {
  const { currentUser } = useAuth();

  return currentUser ? children : <Navigate to="/login" />;
}

export default App;
