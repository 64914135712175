import React from 'react';

const ValidationResultDisplay = ({ result }) => {
    // console.log(result);
    switch (result.type) {
        case 'text':
            return <p>{result.data}</p>;
        case 'table':
            return <TableView data={result.data} />;
        case 'error':
            return <p>Fehler: {result.message}</p>;
        default:
            return <p>Unbekannter Ergebnistyp</p>;
    }
};

const TableView = ({ data }) => {
    return (
        <table>
            <thead>
            <tr>
                <th>Titel</th>
                <th>Startzeit</th>
                <th>Raumname</th>
                <th>Raum-ID</th>
                <th className="right">Status</th>
            </tr>
            </thead>
            <tbody>
            {data.map((item, index) => (
                <tr key={index}>
                    <td>{item.title}</td>
                    <td>{item.start}</td>
                    <td>{item.roomName}</td>
                    <td>{item.roomId}</td>
                    <td className="right">{item.icon}</td>
                </tr>
            ))}
            </tbody>
        </table>
    );
};

export default ValidationResultDisplay;
