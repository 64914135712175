import React, { useEffect, useState } from 'react';
import {collection, getDocs, orderBy, query, limit} from "firebase/firestore";
import { db } from "../../firebase";
import 'react-circular-progressbar/dist/styles.css';
import './Datenbasis.css';
import {formatDateWithDay, shuffleSemester} from "../../utilities/utilityFunctions";
import { validateCourseObject } from "../../utilities/datenbasis/datenbasisUtilityFunctions";
import createCourseObject from "../../utilities/datenbasis/createCourseObject";
import { addSchedulingConfig } from "../../utilities/datenbasis/addSchedulingConfig";
import { importSourceData } from "../../utilities/datenbasis/importSourceData";
import { createInitialSolution } from "../../utilities/datenbasis/createInitialSolution";
import { saveSolution } from "../../utilities/saveSolution";
import { initializeNewProcess, stopProcess } from "../../utilities/processControlling";

function Datenbasis() {

    const [courses, setCourses] = useState([]);
    const [courseObject, setCourseObject] = useState([]);
    const [isImporting, setIsImporting] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState(null);
    const [processId, setProcessId] = useState(null);
    const [selectedSource, setSelectedSource] = useState({
        createdAt: '',
        coursesTotal: 0,
        humanResourcesTotal: 0,
        roomResourcesTotal: 0,
        subjectsTotal: 0
    });

    const handleImport = () => {
        setIsImporting(true);
        setError(null);
        importSourceData()
            .then(response => {
                console.log("Daten erfolgreich synchronisiert:", response);
                fetchData(); // Rufe fetchData auf, um die neuesten Daten zu laden
            })
            .catch(err => {
                console.error("Fehler beim Datenimport:", err);
                setError(err.message);
            })
            .finally(() => {
                setIsImporting(false);
            });
    };

    const handleCreateInitialSolution = async () => {
        if (!selectedSource.id) {
            console.error("Keine Quelldaten-ID verfügbar für die Anfangslösung.");
            return;
        }
        setIsGenerating(true);
        await new Promise(resolve => setTimeout(resolve, 500));

        // Generierung processId und Initialisierung Realtime DB
        const newProcessId = await initializeNewProcess();
        setProcessId(newProcessId);

        const initialSolutionResult = await createInitialSolution(courseObject, selectedSource.id, newProcessId);
        setIsGenerating(false);

        // Verwende das zurückgegebene courseObject für weitere Operationen
        if (initialSolutionResult && initialSolutionResult.courseObject) {
            console.log('Anfangslösung erfolgreich erstellt: ', initialSolutionResult);
            // Optional: Update den Zustand mit dem neuen courseObject
            setCourseObject(initialSolutionResult.courseObject);
        } else {
            console.error("Erstellung der Anfangslösung fehlgeschlagen.");
        }
    };

    const handleSaveInitialSolution = () => {
        if (!selectedSource.id) {
            console.error("Keine Quelldaten-ID verfügbar.");
            return;
        }
        setIsSaving(true);
        saveSolution(courseObject, null, selectedSource.id, 0).then(result => {
            setIsSaving(false);
        }).catch(error => {
            console.error("Fehler beim Speichern der Anfangslösung: ", error);
            setIsSaving(false);
        });
    };

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const sourceCollection = query(collection(db, "sourceData"), orderBy("createdAt", "desc"), limit(1));
            const querySnapshot = await getDocs(sourceCollection);
            if (!querySnapshot.empty) {
                const latestSourceDoc = querySnapshot.docs[0];
                const latestSourceData = latestSourceDoc.data();

                setSelectedSource({
                    id: latestSourceDoc.id,
                    createdAt: latestSourceDoc.data().createdAt,
                    coursesTotal: latestSourceData.coursesTotal || 0,
                    humanResourcesTotal: latestSourceData.humanResourcesTotal || 0,
                    roomResourcesTotal: latestSourceData.roomResourcesTotal || 0,
                    subjectsTotal: latestSourceData.subjectsTotal || 0
                });

                const coursesCollection = collection(db, `sourceData/${latestSourceDoc.id}/courses`);
                const coursesSnapshot = await getDocs(coursesCollection);
                const coursesData = coursesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setCourses(coursesData);
            }
        } catch (error) {
            console.error('Fehler beim Laden der Daten:', error);
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();  // Rufe fetchData beim Initialisieren der Komponente auf
    }, []);

    const handleCreateCourseObject = async () => {
        setIsLoading(true);
        await new Promise(resolve => setTimeout(resolve, 500));
        try {

            // 1. Erstelle das Veranstaltungsobjekt
            let courseObjectData = createCourseObject(courses); // zum Testen reduzieren mit .slice(0,150) // Semesterauswahl mit , [3]

            // 2. Füge die Planungskonfiguration hinzu
            const medv1Rotation = shuffleSemester([3,4,5]);
            courseObjectData = courseObjectData.map(course => addSchedulingConfig(course, medv1Rotation));

            // 3. Zustand aktualisieren
            setCourseObject(courseObjectData);

        } catch (error) {
            console.error('Fehler bei der Erstellung des Veranstaltungsobjekts:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // useEffect, der auf Änderungen von courseObject reagiert
    /*
    useEffect(() => {
        if (courseObject.length > 0) {
            console.log('Alle Kurse: ', courseObject);
            // console.log('5-II-MED1 synchron: ', filterByNotAsynchronous(filterByModule(courseObject, '5-II-MED1')));
            // console.log('Asynchron: ', filterByAsynchronous(courseObject));
            // console.log('Wird nicht terminiert: ', filterByNonScheduled(courseObject));
        }
    }, [courseObject]); // Abhängigkeit zu courseObject hinzugefügt
    */

    const handleStop = async () => {
        console.log("Stop wird aufgerufen mit processId:", processId);
        await stopProcess(processId);
        setIsGenerating(false);
        console.log("Generierung der Anfangslösung gestoppt.");
    };

    return (
        <div className="page-content no-sidebar">
            <div className="page-item">
                <h2>1. Datenbasis initialisieren</h2>
                <h3>Aktuelle Datenbasis</h3>
                <p>Die Initialisierung erfolgt mit dem zuletzt importierten Quelldatensatz
                    vom {selectedSource.createdAt ? formatDateWithDay(new Date(selectedSource.createdAt)) : 'Laden...'}:</p>
                <ul>
                    <li>Veranstaltungen: {selectedSource.coursesTotal}</li>
                    <li>Personalressourcen: {selectedSource.humanResourcesTotal}</li>
                    <li>Raumressourcen: {selectedSource.roomResourcesTotal}</li>
                    <li>Fächer: {selectedSource.subjectsTotal}</li>
                </ul>

                <button type="button" onClick={handleCreateCourseObject} disabled={isLoading || courses.length === 0}>
                    {isLoading ? 'Initialisiere Daten ...' : 'Initialisierung starten'}
                </button>
                {error && <p>Fehler: {error}</p>}
                <h3>Neue Datenbasis importieren</h3>
                <p>Um eine neue Datenbasis zu nutzen, können die aktuellen Airtable-Daten importiert werden:</p>
                <button type="button" onClick={handleImport} disabled={isImporting}>
                    {isImporting ? 'Importiere Daten ...' : 'Import starten'}
                </button>
            </div>
            <div className="page-item">
                <h2>2. Anfangslösung erstellen</h2>
                <h3>Initialisierte Datenbasis</h3>
                {courseObject.length > 0 ? (
                        <>
                            {validateCourseObject(courseObject)}
                            <div className="grid-buttons">
                                <button type="button" onClick={handleCreateInitialSolution} disabled={isGenerating}>
                                    {isGenerating ? 'Erstelle Anfangslösung ...' : 'Anfangslösung erstellen'}
                                </button>
                                <button type="button" className="stop-button" onClick={handleStop}
                                        disabled={!isGenerating}>Stop
                                </button>
                            </div>
                        </>
                    )
                    : <p>Die Datenbasis wurde noch nicht initialisiert.</p>
                }
                <h3>Anfangslösung speichern</h3>
                <button type="button" onClick={handleSaveInitialSolution} disabled={isSaving}>
                    {isSaving ? 'Speichern ...' : 'Speichern'}
                </button>
            </div>
        </div>
    );
}

export default Datenbasis;
