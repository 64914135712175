export const importSourceData = async (setIsImporting) => {
    const functionUrl = 'https://us-central1-siman-24c0c.cloudfunctions.net/pushAirtableDataToFirestore';

    try {
        const response = await fetch(functionUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                tables: [
                    {
                        tableId: 'tblg099MU4SAYck7Z', // dataBasisCoursesSource
                        fields: ['Studienabschnitt', 'Name', 'Modulkuerzel NEU', 'Fachsemester', 'Unterrichtsform', 'Asynchron', 'Fach', 'UE-Präsenz', 'Campus (Name)', 'Termindauer (Min.)', 'Gruppengröße (Bielefeld)', 'Feiertage ignorieren', 'Geprüft', 'Wird nicht terminiert'],
                        collectionName: 'courses'
                    },
                    {
                        tableId: 'tbldMrVLJSBd7PpSy', // subjects
                        fields: ['name'],
                        collectionName: 'subjects'
                    },
                    {
                        tableId: 'tbluj09JLzk63Tn9E', // roomResources
                        fields: ['name', 'room', 'building', 'buildingName', 'buildingSubjects', 'campus', 'allowedSubjects', 'allowedSubjectsNames', 'allowedCourseTypes', 'allowedCourseTypesNames', 'allowedGroupSizes', 'maxParallelGroups', 'active'],
                        collectionName: 'roomResources'
                    },
                    {
                        tableId: 'tblqYYqHgI5ktYeUh', // distances
                        fields: ['distance', 'buildingA', 'buildingB', 'travelDurationByFoot', 'travelDurationByBike', 'travelDurationByPublicTransport', 'travelDurationByCar', 'roomsBuildingA', 'roomsBuildingB'],
                        collectionName: 'distances'
                    },
                    {
                        tableId: 'tblHycPCSr38dyGJ1', // humanResources
                        fields: ['parallelAllowed', 'campusName', 'subjectsNames', 'comment'],
                        collectionName: 'humanResources'
                    },
                    {
                        tableId: 'tbl4qPQZfstF4B4n9', // objectives
                        fields: ['name', 'description', 'weighting', 'constraints'],
                        collectionName: 'objectives'
                    },
                ]
            })
        });

        if (!response.ok) {
            const text = await response.text();
            throw new Error('Network response was not ok: ' + text);
        }

        const responseData = await response.json();
        console.log('Data sync triggered successfully:', responseData);
    } catch (error) {
        console.error('Error triggering data sync:', error);
    }
};