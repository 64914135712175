import React from 'react';
import SoftConstraintCostCalculator from "../features/SoftConstraintCostCalculator/SoftConstraintCostCalculator";
import HardConstraintValidator from "../features/HardConstraintValidator/HardConstraintValidator";

function AnalyseHome() {
    return (
        <div className="page-content no-sidebar">
            <SoftConstraintCostCalculator />
            <HardConstraintValidator />
        </div>
    );
}

export default AnalyseHome;
