import { db } from '../firebase';
import {collection, doc, getDoc, getDocs, query, setDoc, writeBatch} from 'firebase/firestore';
import {calcTotalCostMetrics, generateCustomId} from "./utilityFunctions";
import { calculateSoftConstraintCosts } from "./utilityConstraintFunctions";

export const saveSolution = async (courseObject, parentSolutionId = null, sourceDataId = null, iterationCount = 0) => {
    if (!courseObject) {
        console.error("Kein gültiges courseObject übergeben.");
        return null;
    }

    // Berechne eventsTotal
    let eventsTotal = 0;
    courseObject.forEach(course => {
        if (Array.isArray(course.eventGroups)) {
            course.eventGroups.forEach(eventGroup => {
                eventsTotal += eventGroup.events.length;
            });
        }
    });

    // Berechne Kosten des übermittelten Veranstaltungsobjekts
    const softConstraintCosts = await calculateSoftConstraintCosts(courseObject);
    if (!softConstraintCosts) {
        console.error("Fehler bei der Berechnung der Kosten.");
        return null;
    }

    try {
        // Erstelle das Objekt für Firestore
        let uniqueIdFound = false;
        let customId;
        let solutionRef;

        while (!uniqueIdFound) {
            customId = generateCustomId('MSP', 'alphanumeric');
            solutionRef = doc(collection(db, "solutions"), customId);
            const docSnap = await getDoc(solutionRef);

            if (!docSnap.exists()) {
                uniqueIdFound = true;

                // Speichere die Basisdaten des Dokuments
                await setDoc(solutionRef, {
                    createdAt: new Date(),
                    eventsTotal: eventsTotal || 0,
                    iterationCount: iterationCount,
                    parentSolutionId: parentSolutionId,
                    parentSolutionRef: parentSolutionId ? doc(collection(db, "solutions"), parentSolutionId) : null,
                    sourceDataId: sourceDataId,
                    sourceDataRef: sourceDataId ? doc(collection(db, "sourceData"), sourceDataId) : null,
                    totalConstraintCosts: softConstraintCosts.totalConstraintCosts,
                    costsPerConstraint: softConstraintCosts.costsPerConstraint
                });

                let batch = writeBatch(db);
                let operationCount = 0;
                let i = 1;

                for (const course of courseObject) {
                    const courseRef = doc(collection(solutionRef, "courses"), course.id);
                    batch.set(courseRef, {...course, eventGroups: null});
                    operationCount++;

                    if (operationCount >= 500) {
                        await batch.commit();
                        batch = writeBatch(db);
                        operationCount = 0;
                    }

                    if (Array.isArray(course.eventGroups)) {
                        // EventGroups als Untersammlungen speichern
                        for (const group of course.eventGroups) {
                            const groupRef = doc(collection(solutionRef, "eventGroups"), group.id);
                            // Hinzufügen einer Referenz zur courseId im group-Objekt
                            batch.set(groupRef, {...group, courseId: doc(solutionRef, "courses", course.id), events: null});
                            operationCount++;

                            if (operationCount >= 500) {
                                await batch.commit();
                                batch = writeBatch(db);
                                operationCount = 0;
                            }

                            if (Array.isArray(group.events)) {
                                // Events als Untersammlungen speichern
                                for (const event of group.events) {
                                    const eventRef = doc(collection(solutionRef, "events"), event.extendedProps.id);
                                    // Hinzufügen einer Referenz zur groupId im event-Objekt
                                    batch.set(eventRef, {...event, eventGroupId: groupRef});
                                    operationCount++;

                                    if (operationCount >= 500) {
                                        await batch.commit();
                                        batch = writeBatch(db);
                                        operationCount = 0;
                                    }
                                }
                            }
                        }
                    }


                    // Fortschritt loggen
                    console.log(`${i} von ${courseObject.length} Veranstaltungen gespeichert`);
                    i++;
                }

                if (operationCount > 0) {
                    await batch.commit();
                }

                console.log("Stundenplanlösung erfolgreich gespeichert mit ID: ", solutionRef.id);
                return solutionRef.id;
            }
        }
    } catch (error) {
        console.error("Fehler beim Speichern des Dokuments: ", error);
        return null;
    }
};