import moment from 'moment';
import { CheckboxCircleFill, ErrorWarningFill } from '../../../components/assets/Icons'
import { fetchAllEvents } from "../../utilityFunctions";
import noParticipantOverlap from "../../../hardConstraints/noParticipantOverlap";

export const validate_rec3aPxLLFh4Ssbb5 = async (solutionId) => {

    try {
        let isValid = true;  // Annahme, dass alle Events gültig sind, bis das Gegenteil bewiesen wird

        console.time('fetchAllEvents');
        const allEvents = await fetchAllEvents(solutionId);
        console.timeEnd('fetchAllEvents');

        console.time('noParticipantOverlap');
        const eventsWithParticipantOverlap = allEvents.filter(event => !noParticipantOverlap(event, allEvents.slice(1000,3000)));
        console.timeEnd('noParticipantOverlap');

        console.log('eventsWithParticipantOverlap: ', eventsWithParticipantOverlap);

        if (eventsWithParticipantOverlap && eventsWithParticipantOverlap.length > 0) {
            isValid = false;
        }

        const tableData = eventsWithParticipantOverlap.map(event => {
            const iconComponent = <ErrorWarningFill color='rgba(233, 85, 51, 1)' size='20px' />;
            return {
                title: event.title,
                start: moment(event.start).format("DD.MM.YYYY, HH:mm") + ' Uhr', // Formatieren des Datums
                roomName: event.location && event.location.roomName ? event.location.roomName : 'Kein Raum',
                roomId: event.location && event.location.roomId ? event.location.roomId : 'Keine ID',
                icon: iconComponent  // Hier fügen wir das Icon direkt als Komponente hinzu
            };
        });

        // Das Gesamticon wird basierend auf der Gültigkeit des Ergebnisses bestimmt
        const overallIcon = isValid
            ? <CheckboxCircleFill color='rgba(26, 198, 179, 1)' size='18px' />
            : <ErrorWarningFill color='rgba(233, 85, 51, 1)' size='18px' />;

        return {
            type: 'table',
            data: tableData,
            isValid: isValid,  // Rückgabe des Gesamtergebnisses
            overallIcon: overallIcon  // Rückgabe des Icons, das den Gesamtzustand anzeigt
        };
    } catch (error) {
        console.error('Fehler beim Abrufen der Events:', error);
        return {
            type: 'error',
            message: 'Es ist ein Fehler aufgetreten.',
            isValid: false,  // Rückgabe, dass bei einem Fehler die Validierung als ungültig betrachtet wird
            overallIcon: <ErrorWarningFill color='rgba(233, 85, 51, 1)' size='18px' />
        };
    }
}