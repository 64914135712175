import React, { useEffect, useState } from 'react';
import SolutionSelector from "./features/SolutionSelector/SolutionSelector";
import ProcessController from "./features/ProcessController/ProcessController";
import { doc, getDoc } from "firebase/firestore";
import { db, dbRT, ref, set} from '../firebase';

import { simulatedAnnealing } from "../utilities/simulation/simulatedAnnealing";
import {saveSolution} from "../utilities/saveSolution";

function Simulation() {

    // Initialisierungszustände
    // eslint-disable-next-line
    const [solutionList, setSolutionList] = useState([]);
    const [solutionId, setSolutionId] = useState(null);
    const [selectedSolutionDetails, setSelectedSolutionDetails] = useState(null);
    const [progress, setProgress] = useState(0);
    const [logs, setLogs] = useState([]);
    const [processId, setProcessId] = useState(null);
    const [newSolution, setNewSolution] = useState(null);
    const [newSolutionIterationCount, setNewSolutionIterationCount] = useState(0);
    const [sourceDataId, setSourceDataId] = useState(null);


    const handleStart = async () => {
        if (!solutionId) {
            console.error('Keine ID einer Anfangslösung vorhanden');
            return;
        }

        // Generiere eine neue processId
        const newProcessId = `process-${Date.now()}`;
        setProcessId(newProcessId);

        // Referenzen für Realtime Database
        const logsRef = ref(dbRT, `processes/${newProcessId}/logs`);
        const progressRef = ref(dbRT, `processes/${newProcessId}/progress`);
        await set(logsRef, []); // Log initialisieren
        await set(progressRef, { value: 0 }); // Fortschritt initialisieren

        const result = await simulatedAnnealing(
            solutionId,
            newProcessId,
            100, // temperature
            0.001, // threshold
            0.95, // coolingFactor
            500 // maxIterations
        );

        if (result.result === "completed") {
            console.log("Simulated Annealing abgeschlossen!");
            setNewSolution(result.newSolution);
            setNewSolutionIterationCount(result.iterationCount);
            setSourceDataId(result.sourceDataId);
        } else {
            console.log("Simulated Annealing gestoppt.");
        }

        setLogs((prevLogs) => [
            ...prevLogs,
            {
                time: new Date().toLocaleTimeString(),
                message: `Prozess abgeschlossen: ${result.processId}`
            }
        ]);
    };

    const handleStop = async () => {
        // Aktualisiere die gestoppte Flag in der RT-DB
        if (processId) {
            const stoppedRef = ref(dbRT, `processes/${processId}/stopped`);
            try {
                await set(stoppedRef, true);
            } catch (error) {
                console.error("Fehler beim Setzen des Stop-Flags:", error);
            }
        }

        setProgress(0);
        setLogs([]);
        console.log("Simulated Annealing gestoppt.");
    };

    const handleSaveAndAnalyze = async () => {
        if (!newSolution) {
            console.error("Keine Lösung verfügbar zum Speichern.");
            return;
        }

        console.log("Beginne mit dem Speichern der Lösung...");
        const savedDocRef = await saveSolution(newSolution, solutionId, sourceDataId, newSolutionIterationCount);
        if (savedDocRef) {
            console.log("Lösung erfolgreich gespeichert:", savedDocRef);
            // Führe hier weitere Analysen durch, nachdem die Lösung gespeichert wurde
        } else {
            console.error("Die Lösung konnte nicht gespeichert werden.");
        }
    };

    const handleDiscard = () => {
        // Verwerfen hier
        setProgress(0);
        setLogs([]);
        console.log("Lösung verworfen.");
    };

    useEffect(() => {
        async function fetchSolutionDetails(solutionId) {
            if (solutionId) {
                try {
                    const docRef = doc(db, 'solutions', solutionId);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        setSelectedSolutionDetails(docSnap.data());
                    }
                } catch (error) {
                    console.error("Fehler beim Laden der Lösung: ", error);
                }
            }
        }

        fetchSolutionDetails(solutionId);
    }, [solutionId]);

    return (
        <div className="page-content no-sidebar">
            <div className="page-item">
                <h2>Ausgangslösung</h2>
                <h3>Ausgangslösung wählen</h3>
                <div>
                    <SolutionSelector
                        setSolutionList={setSolutionList}
                        onSelect={setSolutionId}
                    />
                </div>
                {selectedSolutionDetails && (
                    <>
                        <div>
                            <table className="siman-data">
                                <tbody>
                                <tr>
                                    <td>Eindeutige ID</td>
                                    <td className="data data-info"><strong>{solutionId}</strong></td>
                                </tr>
                                <tr>
                                    <td>Erstellt am</td>
                                    <td className="data data-info">{new Date(selectedSolutionDetails.createdAt.seconds * 1000).toLocaleString()}</td>
                                </tr>
                                <tr>
                                    <td>Anzahl der Termine</td>
                                    <td className="data data-info">{selectedSolutionDetails.eventsTotal}</td>
                                </tr>
                                <tr>
                                    <td>Iteration:</td>
                                    <td className="data data-info">{selectedSolutionDetails.iterationCount > 0 ? selectedSolutionDetails.iterationCount : "Anfangslösung"}</td>
                                </tr>
                                <tr>
                                    <td>Gesamtkosten:</td>
                                    <td className="data data-info">{Math.round(selectedSolutionDetails.totalConstraintCosts)}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
            </div>
            <div className="page-item">
                <h2>Simulated Annealing</h2>
                {selectedSolutionDetails ? (
                    <ProcessController
                        processId={processId}
                        onStart={handleStart}
                        onStop={handleStop}
                        progress={progress}
                        logs={logs}
                        onSaveAndAnalyze={handleSaveAndAnalyze}
                        onDiscard={handleDiscard}
                        title="Simulation durchführen"
                        startButtonLabel="Simulated Annealing starten"
                    />
                ) : (
                    <>
                        <div className="page-item-subitem">Bitte Anfangslösung wählen</div>
                    </>
                )}
            </div>
        </div>
    );
}

export default Simulation;
