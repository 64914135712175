import {groups} from "../../groups";

export default function getStudentIntersectionGroups(groupName) {
    // Finde die Gruppe, die dem groupName entspricht
    const targetGroup = groups.find(group => group.id === groupName);
    if (!targetGroup) {
        return []; // Keine Gruppe gefunden
    }

    // Extrahiere die Mitgliederliste der Zielgruppe
    const targetMembers = new Set(targetGroup.members);

    // Filtere die Gruppen, die eine Schnittmenge mit der Zielgruppe haben
    const relatedGroups = groups.filter(group => {
        // Sicherstellen, dass wir die Zielgruppe selbst nicht in die Ergebnisse aufnehmen
        if (group.id === groupName) {
            return false;
        }

        // Überprüfe, ob es eine Überschneidung der Mitglieder gibt
        const currentMembers = new Set(group.members);
        return Array.from(targetMembers).some(member => currentMembers.has(member));
    });

    // Rückgabe der IDs der Gruppen mit Schnittmengen
    return relatedGroups.map(group => group.id);
}
