import React, { useEffect, useState } from 'react';
import { db } from '../../../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import './ConstraintListGenerator.css';

function ConstraintListGenerator({ onSelect, validationFunctions, solutionId }) {
    const [constraints, setConstraints] = useState([]);
    const [validationResults, setValidationResults] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function fetchConstraints() {
            setIsLoading(true);
            try {
                const constraintsCol = collection(db, 'constraints');
                const constraintsQuery = query(
                    constraintsCol,
                    where("deleted", "!=", true)  // Abfrage nach Dokumenten, bei denen 'deleted' nicht 'true' ist
                );
                const constraintsSnapshot = await getDocs(constraintsQuery);
                const fetchedConstraints = constraintsSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                })).sort((a, b) => a.name.localeCompare(b.name));
                setConstraints(fetchedConstraints);
                fetchedConstraints.forEach(constraint => {
                let validationFunction = validationFunctions[constraint.id];
                /*
                if (validationFunction) {
                    validationFunction(solutionId).then(result => {
                        setValidationResults(prevResults => ({
                            ...prevResults,
                            [constraint.id]: result.overallIcon
                        }));
                    });
                }
                */
            });

            } catch (error) {
                console.error("Fehler beim Laden der Anforderungen: ", error);
            } finally {
                setIsLoading(false);
            }
        }
        fetchConstraints();
    }, [solutionId]);

    const handleSelect = async (constraint) => {
        const validate = validationFunctions[constraint.id];
        if (validate && solutionId) {
            const result = await validate(solutionId);
            setValidationResults(prev => ({ ...prev, [constraint.id]: result.overallIcon }));
        }
        onSelect(constraint);
    };

    return (
        <>
            <div className="constraints-container">
                <div className="constraints-content">
                    {isLoading ? (
                        <p>Lade Anforderungen...</p>
                    ) : (
                        <ul>
                            {constraints.map(constraint => (
                                <li key={constraint.id} onClick={() => handleSelect(constraint)}>
                                    <div>
                                        {validationResults[constraint.id] !== undefined &&
                                            validationResults[constraint.id]
                                        }
                                    </div>
                                    <div>
                                        {constraint.name}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </>
    );
}

export default ConstraintListGenerator;
