import { dbRT, get, push, ref, serverTimestamp, set} from '../../firebase';
import {
    calculateSoftConstraintCosts,
    checkHardConstraints,
    fetchConstraints
} from '../utilityConstraintFunctions';
import { clearCache } from "../constraintCacheManager";
import {
    calcTotalCostMetrics,
    chooseRandomSemester,
    fetchSolution,
    flattenCourseObject,
    isCourseToBeScheduled,
    restructureCourseObject
} from "../utilityFunctions";
import config from '../../config';
import cloneDeep from 'lodash/cloneDeep';
import {checkIfStopped} from "../processControlling";

export async function simulatedAnnealing(  // Hauptfunktion Simulated Annealing
    solutionId,
    processId,
    temperature = 100,
    threshold = 0.001,
    coolingFactor = 0.95,
    maxIterations = 250
) {

    // Prüfe, ob eine Prozess-ID in den Daten vorhanden ist
    if (!processId) {
        console.error('invalid-argument', 'Es wurde keine Prozess-ID übergeben.');
        return;
    }

    // Prüfe, ob die ID einer Anfangslösung in den Daten vorhanden ist
    if (!solutionId) {
        console.error('invalid-argument', 'Es wurde keine ID einer Anfangslösung übergeben.');
        return;
    }

    clearCache();

    // Referenzen Realtime DB für Log und Fortschritt
    const logsRef = ref(dbRT, `processes/${processId}/logs`);
    const stoppedRef = ref(dbRT, `processes/${processId}/stopped`);
    const progressRef = ref(dbRT, `processes/${processId}/progress`);
    const hardConstraints = await fetchConstraints(true, true, true);

    // Weitere Konfiguration
    const neededIterations = calculateIterations(temperature, threshold, coolingFactor, maxIterations);
    const solutionData = await fetchSolution(solutionId, logsRef); // übermittelte Anfangslösung als aktuelle Lösung nehmen
    const iterationCount = solutionData.iterationCount + 1;
    const sourceDataId = solutionData.sourceDataId;
    let currentSolution = solutionData.courseObject.filter(isCourseToBeScheduled); // Filtere die Kurse, die geplant werden sollen
    const currentSolutionCostsResult = await calculateSoftConstraintCosts(currentSolution); // Berechne die Kosten für die anfängliche Lösung
    let currentSolutionCosts = currentSolutionCostsResult.totalConstraintCosts;
    console.log('currentSolutionCosts vor der Schleife: ', currentSolutionCosts);

    await push(logsRef, {
        message: `Simulated Annealing von ${solutionId} mit ${neededIterations} geplanten Iterationen gestartet`,
        timestamp: serverTimestamp()
    });

    let lastLoggedPercentage = -1;
    let totalAccepted = 0;
    let i = 0;  // Initialisiere Zählvariable für Iterationen

    while (temperature > threshold && i < maxIterations) {

        i++; // Zähler Iterationen
        console.log(`\n##### ${i}. Iteration #####`);

        // Überprüfen, ob die Simulation gestoppt werden soll
        if (await checkIfStopped(processId) === true) {
            return {
                result: 'stopped',
                processId
            };
        }

        // const randomSemester = chooseRandomSemester();
        const randomSemester = 1;
        console.log('randomSemester: ', randomSemester);

        let newSolution = await generateNeighborSolution(
            currentSolution,
            hardConstraints,
            2,
            100,
            200,
            randomSemester
        );
        const newSolutionCostsResult = await calculateSoftConstraintCosts(newSolution);
        let newSolutionCosts = newSolutionCostsResult.totalConstraintCosts;
        let costDifference = newSolutionCosts - currentSolutionCosts;

        let accepted = costDifference < 0 || Math.exp(-costDifference / temperature) > Math.random();
        if (accepted) {
            currentSolution = newSolution;
            currentSolutionCosts = newSolutionCosts;
            totalAccepted++;
        }

        let acceptanceRate = (totalAccepted / (i + 1)) * 100;
        temperature = await calcCooledTemperature(temperature, coolingFactor);

        await push(logsRef, {
            message: `<strong>${i}. Iteration:</strong><br />
                Temperatur ${temperature.toFixed(2)}<br />
                Aktuelle Gesamtkosten: ${currentSolutionCosts.toFixed(0)}<br />
                Kostendifferenz für Semester: ${costDifference.toFixed(0)}<br />
                Akzeptanzrate: ${acceptanceRate.toFixed(0)} %,
                Lösung angenommen: ${accepted}`,
            timestamp: serverTimestamp()
        });

        const currentPercentage = Math.floor(((i + 1) / neededIterations) * 100);
        if (currentPercentage > lastLoggedPercentage) {
            await set(progressRef, { value: currentPercentage });
            lastLoggedPercentage = currentPercentage;
        }

    }

    if (neededIterations % 100 !== 0) {
        await push(logsRef, {
            message: `${i} Iterationen durchgeführt (Ende)`,
            timestamp: serverTimestamp()
        });
    }

    return {
        newSolution: currentSolution,
        iterationCount,
        sourceDataId,
        // constraintFailures,
        // finalViolations,
        result: 'completed',
        processId
    };
}

// Hilfsfunktion zur Berechnung der Anzahl an Iterationen
function calculateIterations(startTemperature, threshold, coolingFactor, maxIterations) {
    const iterations = Math.ceil(Math.log(threshold / startTemperature) / Math.log(coolingFactor));
    return Math.min(iterations, maxIterations);
}

async function calcCooledTemperature(temperature, coolingFactor = 0.95) {
    return temperature * coolingFactor;
}

async function generateNeighborSolution(
    currentSolution,
    hardConstraints,
    percentToModify = 1,
    attemptsPerEvent = 10,
    maxAttemptsPerValidation = 100,
    semester = null
) {
    // Tiefe Kopie der aktuellen Lösung erstellen und flatten
    let allEvents = flattenCourseObject(cloneDeep(currentSolution));
    let filteredEvents = allEvents.filter(event => event.extendedProps.semester === semester && !event.extendedProps.preventChanges);
    let lastFailedConstraints = [];
    let modifications = [];

    // Berechne die Anzahl der zu modifizierenden Events basierend auf gefilterten Events
    const numEventsToModify = Math.ceil((percentToModify / 100) * filteredEvents.length);
    const eventIndicesToModify = new Set();

    // Initiiere Performance-Messung
    let performanceStart = performance.now(); // Startzeit für Gesamtdauer

    // Wähle zufällig `numEventsToModify` Events aus
    while (eventIndicesToModify.size < numEventsToModify) {
        const randomEventIndex = Math.floor(Math.random() * filteredEvents.length);
        eventIndicesToModify.add(randomEventIndex);
    }

    // Versuche, jedes ausgewählte Event zu modifizieren
    for (const index of eventIndicesToModify) {
        const event = filteredEvents[index];

        for (let attempt = 0; attempt < attemptsPerEvent; attempt++) {
            const schedulingConfig = event.extendedProps.schedulingConfig[
                Math.floor(Math.random() * event.extendedProps.schedulingConfig.length)
            ];

            if (!schedulingConfig.earliestDate || !schedulingConfig.latestDate) continue;

            let validTime = false;
            let validationAttempts = 0; // Lokale Variable für Versuche der Zeitvalidierung

            // Zufällige Auswahl der Mutationsart
            const mutationType = Math.random() > 0.5 ? 'timeShift' : 'dateShift';

            if (mutationType === 'timeShift') {
                while (!validTime && validationAttempts < maxAttemptsPerValidation) {
                    validationAttempts++;
                    const randomQuarterHours = Math.floor(Math.random() * 2 + 1) * 15;
                    const direction = Math.random() > 0.5 ? 1 : -1;

                    let proposedStartTime = new Date(event.start);
                    let proposedEndTime = new Date(event.end);

                    proposedStartTime.setMinutes(proposedStartTime.getMinutes() + direction * randomQuarterHours);
                    proposedEndTime.setMinutes(proposedEndTime.getMinutes() + direction * randomQuarterHours);

                    if (proposedStartTime.getHours() >= config.workHours.startHour && proposedEndTime.getHours() < config.workHours.endHour &&
                        proposedStartTime >= new Date(schedulingConfig.earliestDate) && proposedEndTime <= new Date(schedulingConfig.latestDate)) {
                        validTime = true; // Gültige Zeit gefunden
                        event.start = proposedStartTime.toISOString();
                        event.end = proposedEndTime.toISOString();
                    }
                }
            } else if (mutationType === 'dateShift') {
                const daysToShift = Math.floor(Math.random() * 7 + 1) * (Math.random() > 0.5 ? 1 : -1);
                let proposedStartTime = new Date(event.start);
                let proposedEndTime = new Date(event.end);

                proposedStartTime.setDate(proposedStartTime.getDate() + daysToShift);
                proposedEndTime.setDate(proposedEndTime.getDate() + daysToShift);

                if (proposedStartTime >= new Date(schedulingConfig.earliestDate) && proposedEndTime <= new Date(schedulingConfig.latestDate)) {
                    validTime = true;
                    event.start = proposedStartTime.toISOString();
                    event.end = proposedEndTime.toISOString();
                }
            }

            if (validTime) {
                const checkResult = await checkHardConstraints(event, allEvents, hardConstraints);
                if (checkResult.success) {
                    modifications.push(event);
                    break; // Stop bei Erfolg
                } else {
                    lastFailedConstraints = checkResult.failedConstraints;
                    event.start = filteredEvents[index].start; // Rückgängig machen bei Nichterfolg
                    event.end = filteredEvents[index].end;
                }
            }
        }
    }

    // Performance-Log
    let now = performance.now();
    let duration = now - performanceStart;
    let averageDurationPerEvent = duration / numEventsToModify;
    console.log(`${Math.round(averageDurationPerEvent)} ms pro Termin`);
    console.log(`${Math.round(duration)} ms insgesamt`);

    // Loggen der Modifikationen und Rückgabe der neuen Lösung
    if (modifications.length === numEventsToModify) {
        console.log('Modifikation erfolgreich:', modifications.length);
        return restructureCourseObject(allEvents, currentSolution); // Rekonstruktion der courseObject-Struktur
    } else {
        console.log('Modifikation fehlgeschlagen:', lastFailedConstraints);
        return currentSolution; // Rückgabe der ursprünglichen Lösung, wenn nicht alle Modifikationen erfolgreich waren
    }
}