import React, {useEffect, useState} from 'react';
import SolutionSelector from "../SolutionSelector/SolutionSelector";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import {fetchSolution, flattenCourseObject, isCourseToBeScheduled} from "../../../utilities/utilityFunctions";
import HardConstraintSelector from "../HardConstraintSelector/HardConstraintSelector";
import {checkHardConstraints} from "../../../utilities/utilityConstraintFunctions";

function HardConstraintValidator() {

    const [solutionList, setSolutionList] = useState([]);
    const [solutionId, setSolutionId] = useState(null);
    const [selectedSolutionDetails, setSelectedSolutionDetails] = useState(null);
    const [allEvents, setAllEvents] = useState(null);
    const [selectedConstraint, setSelectedConstraint] = useState(null);
    const [constraintViolations, setConstraintViolations] = useState([]);

    useEffect(() => {
        async function fetchSolutionDetails(solutionId) {
            if (solutionId && !selectedSolutionDetails) {
                try {
                    const docRef = doc(db, 'solutions', solutionId);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        setSelectedSolutionDetails(docSnap.data());
                    }

                    // Veranstaltungsobjekt abrufen
                    const solutionData = await fetchSolution(solutionId);
                    const validCourses = solutionData.courseObject.filter(isCourseToBeScheduled);
                    let allEvents = flattenCourseObject(validCourses);
                    setAllEvents(allEvents);
                    console.log(allEvents);

                } catch (error) {
                    console.error("Fehler beim Laden der Lösung: ", error);
                }
            }
        }

        fetchSolutionDetails(solutionId);
    }, [solutionId, selectedSolutionDetails]);

    useEffect(() => {
        const validateHardConstraints = async () => {
            if (selectedConstraint && allEvents.length > 0) {
                let violations = [];
                console.log('selectedConstraint: ', selectedConstraint);
                for (const event of allEvents) {
                    const checkResult = await checkHardConstraints(event, allEvents, [selectedConstraint]);
                    if (!checkResult.success) {
                        violations.push(event);
                        console.log('checkResult: ', checkResult);
                    }
                }
                setConstraintViolations(violations);
            }
        };

        validateHardConstraints();
    }, [selectedConstraint, allEvents]);

    return (
        <div className="page-item page-item-fullwidth">
            <h2>Hard Constraint validieren</h2>
            <div>
                <SolutionSelector
                    setSolutionList={setSolutionList}
                    onSelect={setSolutionId}
                />
                {selectedSolutionDetails && (
                    <>
                        <HardConstraintSelector
                            onSelect={setSelectedConstraint}
                        />
                        {selectedConstraint && (
                            <>
                                <h3>Prüfergebnis <q>{selectedConstraint.name}</q></h3>
                                <table className="">
                                    <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Semester</th>
                                        <th>Termin</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {constraintViolations.map(event => (
                                        <tr key={event.extendedProps.id}>
                                            <td>{event.extendedProps.id}</td>
                                            <td>{event.extendedProps.semester}</td>
                                            <td>{event.title}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default HardConstraintValidator;
