import React, {useEffect, useState} from 'react';
import SolutionSelector from "../features/SolutionSelector/SolutionSelector";
import SubjectSelector from "../features/SubjectSelector/SubjectSelector";
import { db } from '../../firebase';
import {collection, doc, getDocs, query, where} from 'firebase/firestore';
import 'react-calendar-heatmap/dist/styles.css';
import {getAcademicYear} from "../../utilities/utilityFunctions";
import './Analyse.css';
import DailySubjectWorkloadHeatmap from "../features/DailySubjectWorkloadHeatmap/DailySubjectWorkloadHeatmap";
import YearlySubjectWorkloadHeatmap from "../features/YearlySubjectWorkloadHeatmap/YearlySubjectWorkloadHeatmap";
import YearlySubjectOverlapHeatmap from "../features/YearlySubjectOverlapHeatmap/YearlySubjectOverlapHeatmap";
import { Tooltip } from "react-tooltip";

function AnalyseFachbezogen() {
    const [solutionId, setSolutionId] = useState("");
    const [subjectId, setSubjectId] = useState("");
    const [subjectName, setSubjectName] = useState(""); // Name des Fachs zum Filtern der Events
    const [allEvents, setAllEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const { winter, summer } = getAcademicYear();

    // Handler für die Auswahl einer Lösung
    const handleSolutionSelected = (selectedId) => {
        setSolutionId(selectedId);
        setSubjectId("");  // Reset Subject ID, wenn neue Solution ausgewählt wird
    };

    // Handler für die Auswahl eines Fachs
    const handleSubjectSelected = (selectedId, selectedName) => {
        setSubjectId(selectedId);
        setSubjectName(selectedName);
    };

    // Daten laden
    useEffect(() => {
        const fetchEvents = async () => {
            if (solutionId && subjectId && subjectName) {
                setLoading(true);
                const solutionRef = doc(db, "solutions", solutionId);
                const queryConstraints = [
                    where("extendedProps.subject", "==", subjectName)
                ];
                const eventsQuery = query(collection(solutionRef, "events"), ...queryConstraints);
                const eventsSnapshot = await getDocs(eventsQuery);
                let allEvents = [];

                eventsSnapshot.forEach(eventDoc => {
                    const event = eventDoc.data();
                    allEvents.push(event);
                });
                setAllEvents(allEvents);
                setLoading(false);
            }
        };

        fetchEvents();
    }, [solutionId, subjectId, subjectName]);


    return (
        <div className="page-content">
            <div className="page-sidebar three-rows">
                <SolutionSelector
                    onSelect={handleSolutionSelected}
                />
                <SubjectSelector
                    solutionId={solutionId}
                    onSelect={(selectedId, selectedName) => handleSubjectSelected(selectedId, selectedName)}
                />
            </div>
            <div className="page-item">
                <h2>Analysedaten</h2>
                <table className="siman-data">
                    <tbody>
                    <tr>
                        <td>Stundenplanlösung:</td>
                        <td>{solutionId}</td>
                    </tr>
                    <tr>
                        <td>Fach:</td>
                        <td>{subjectName}</td>
                    </tr>
                    <tr>
                        <td>Fach-ID:</td>
                        <td>{subjectId}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className="page-item">
                <DailySubjectWorkloadHeatmap
                    subject={subjectName}
                    startDate={winter.startDate}
                    endDate={winter.endDate}
                    allEvents={allEvents}
                    title='Auslastung im Tagesverlauf'
                    loading={loading}
                />
            </div>
            <div className="page-item">
                <YearlySubjectWorkloadHeatmap
                    subject={subjectName}
                    startDate={winter.startDate}
                    endDate={winter.endDate}
                    allEvents={allEvents}
                    title='Termine pro Tag im Wintersemester'
                    loading={loading}
                />
            </div>
            <div className="page-item">
                <YearlySubjectWorkloadHeatmap
                    subject={subjectName}
                    startDate={summer.startDate}
                    endDate={summer.endDate}
                    allEvents={allEvents}
                    title='Termine pro Tag im Sommersemester'
                    loading={loading}
                />
            </div>
            <div className="page-item">
                <YearlySubjectOverlapHeatmap
                    subject={subjectName}
                    startDate={winter.startDate}
                    endDate={winter.endDate}
                    allEvents={allEvents}
                    title='Terminparallelität im Wintersemester'
                    loading={loading}
                />
            </div>
            <div className="page-item">
                <YearlySubjectOverlapHeatmap
                    subject={subjectName}
                    startDate={summer.startDate}
                    endDate={summer.endDate}
                    allEvents={allEvents}
                    title='Terminparallelität im Sommersemester'
                    loading={loading}
                />
            </div>
            <Tooltip id="tooltip" place="right" effect="solid" />
        </div>
    );
}

export default AnalyseFachbezogen;