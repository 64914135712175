import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../firebase";
import {clearCache, getCache, setCache} from './constraintCacheManager';
import {flattenCourseObject, isCourseToBeScheduled} from "./utilityFunctions";

export const fetchConstraints = async (isHardConstraint, isActive, hasFunction = true, constraintIds = []) => {
    console.log('constraintIds: ', constraintIds);
    let constraintsRef = query(
        collection(db, "constraints"),
        where("isHardConstraint", "==", isHardConstraint),
        where("active", "==", isActive)
    );

    const querySnapshot = await getDocs(constraintsRef);
    return querySnapshot.docs
        .map(doc => ({
            id: doc.id,
            ...doc.data()
        }))
        .filter(doc => {
            // Filtere nach nicht gelöschten Einträgen und prüfe das Vorhandensein von 'function'
            if (doc.deleted === true) return false;
            if (hasFunction && doc.function == null) return false;
            if (!hasFunction && doc.function != null) return false;

            // Filter nach constraintIds, wenn welche angegeben sind
            return constraintIds.length === 0 || constraintIds.includes(doc.id);
        });
}

export async function loadConstraintFunction(functionName, isHardConstraint, clearCacheFirst = false) {
    const directory = isHardConstraint ? 'hardConstraints' : 'softConstraints';
    const cacheKey = `${directory}/${functionName}`;

    // Wenn clearCacheFirst true ist, leere den Cache
    if (clearCacheFirst) {
        clearCache();
        console.log('Cache wurde geleert');
    }

    // Überprüfe, ob die Funktion bereits im Cache vorhanden ist
    let func = getCache(cacheKey);
    if (func) {
        return func;
    }

    // Standard-Import-Logik für Funktionen
    try {
        const module = await import(`../${directory}/${functionName}.js`);
        if (typeof module.default === 'function') {
            func = module.default;
            setCache(cacheKey, func);
            // console.log(`Funktion ${functionName} wurde importiert und im Cache gespeichert.`);
            return func;
        } else {
            throw new Error(`Importiertes Modul für ${functionName} ist keine Funktion`);
        }
    } catch (error) {
        console.error(`Fehler beim Laden der Funktion: ${functionName}`, error);
        return null;
    }
}

//
// Spezifische Hilfsfunktionen für Hard Constraints
//

export const checkHardConstraints = async (event, allEvents, constraints, roomCache = null) => {
    // Lade alle Constraint-Funktionen parallel
    const loadedFunctions = await Promise.all(constraints.map(constraint =>
        loadConstraintFunction(constraint.function, true).catch(() => null)
    ));

    // Führe die Constraints-Prüfung parallel durch
    const results = await Promise.all(loadedFunctions.map((func, index) => {
        if (!func) {
            return Promise.resolve('error');
        }
        try {
            return func(event, allEvents, roomCache);
        } catch (error) {
            console.error(`Fehler in Constraint-Funktion ${constraints[index].name}:`, error);
            return 'error';
        }
    }));

    // Überprüfe Ergebnisse
    const failedIndex = results.findIndex(result => result === false || result === 'error');
    if (failedIndex !== -1) {
        return {
            success: false,
            failedConstraints: [{
                constraint: constraints[failedIndex].name,
                status: results[failedIndex] === 'error' ? 'error' : 'failed',
                details: results[failedIndex] === 'error' ? 'Hard-Constraint-Funktion nicht gefunden' : `Hard Constraint nicht erfüllt für ${event.extendedProps.id}`
            }]
        };
    }

    // Wenn alle Constraints erfolgreich sind, geben wir Erfolg zurück
    return { success: true };
};

// Prüfe Hard Constraints für eine Gruppe von events
export const checkGroupConstraints = async (events, allEvents, constraints, roomCache = null) => {

    // Bereite eine Liste von Prüfungen für jedes Event in der Gruppe vor
    let checks = events.map(event => checkHardConstraints(event, allEvents, constraints, roomCache));

    // Führe alle Prüfungen parallel aus
    const results = await Promise.all(checks);
    // console.log('checkGroupConstraints: ', results);

    // Sammle alle gescheiterten Constraints
    let failedConstraints = results.reduce((acc, result, index) => {
        if (!result.success) {
            acc.push({
                event: events[index],
                failedConstraints: result.failedConstraints
            });
        }
        return acc;
    }, []);

    // Wenn ein Fehler in den Constraints vorliegt, geben wir ein Fehlerobjekt zurück
    if (failedConstraints.length > 0) {
        return {
            success: false,
            failedConstraints: failedConstraints
        };
    }

    // Wenn alle Events erfolgreich geprüft wurden, geben wir Erfolg zurück
    return { success: true };
};


export const finalHardConstraintCheck = async (courses, constraints) => {
    let constraintViolations = {};

    for (let course of courses) {

        // Prüfe anhand des Vorhandenseins von Start- und Endzeitpunkt, ob Objekt ein Event oder Kurs ist
        if (course.start && course.end) {
            course = convertEventToCourseObjects(course);
        }

        const checkResult = await checkHardConstraints(course, constraints, courses);
        if (!checkResult.success) {
            for (let failure of checkResult.failedConstraints) {
                if (constraintViolations[failure.constraint]) {
                    constraintViolations[failure.constraint]++;
                } else {
                    constraintViolations[failure.constraint] = 1;
                }
            }
        }
    }
    return constraintViolations;
}

// Hilfsfunktion, um Events (aus gespeicherten Lösungen) in Kursobjekte (wie in Datenbasis) umzuwandeln, um sie z.B. in Hilfsfunktionen wie finalHardConstraintCheck() benutzen zu können
function convertEventToCourseObjects(item) {
    return {
        courseName: item.title || 'Unbekannter Kurs',
        startTime: item.start,
        endTime: item.end,
        courseSubName: item.extendedProps.courseSubName || '',
        courseType: item.extendedProps.courseType || '',
        courseDuration: item.extendedProps.courseDuration || 0,
        group: item.extendedProps.group || '',
        relatedGroups: item.extendedProps.relatedGroups || [],
        groupSize: item.extendedProps.groupSize || 0,
        isAsynchronous: item.extendedProps.isAsynchronous || false,
        lessonUnits: item.extendedProps.lessonUnits || 0,
        module: item.extendedProps.module || '',
        semesterOptions: item.extendedProps.semesterOptions || [],
        studySection: item.extendedProps.studySection || '',
        subject: item.extendedProps.subject || '',
        venueArea: item.extendedProps.venueArea || '',
        venueBuilding: item.extendedProps.venueBuilding || '',
        venueRoom: item.extendedProps.venueRoom || '',
        courseSource: item.extendedProps.courseSource || ''
    };
}

//
// Spezifische Hilfsfunktionen für Soft Constraints
//

// Berechnung der gesamten Soft-Constraint-Kosten
export async function calculateSoftConstraintCosts(courseObject, constraintIds = [], clearCacheFirst = false) {

    if (!courseObject || courseObject.length === 0) {
        console.error('Kein gültiges Veranstaltungsobjekt übergeben oder das Veranstaltungsobjekt ist leer.');
        return null; // Stoppe die Ausführung und gebe null zurück
    }

    // Extrahiere alle Events aus dem Kursobjekt
    const validCourses = courseObject.filter(isCourseToBeScheduled);
    const events = flattenCourseObject(validCourses);

    // Lade die relevanten Constraints
    const softConstraints = await fetchConstraints(false, true, true, constraintIds);

    // Überprüfe, ob Constraints vorhanden sind
    if (!softConstraints || softConstraints.length === 0) {
        console.error('Keine Soft Constraints gefunden.');
        return null;
    }

    let costsPerConstraint = {};
    let totalConstraintCosts = 0;

    // Verarbeite jedes Constraint
    for (let constraint of softConstraints) {

        // Initiiere Performance-Messung
        let performanceStart = performance.now();

        const func = await loadConstraintFunction(constraint.function, false, clearCacheFirst);

        if (typeof func === 'function') {
            const weighting = constraint.weighting || 1;

            try {
                const result = await func(events, weighting);
                if (result) {
                    costsPerConstraint[constraint.id] = { totalCost: result.totalCost };
                    totalConstraintCosts += result.totalCost;
                } else {
                    console.error(`Keine Kostenwerte zurückgegeben für Constraint ${constraint.name} mit ID ${constraint.id}.`);
                }
            } catch (error) {
                console.error(`Fehler bei der Ausführung der Constraint-Funktion für ${constraint.name}:`, error);
            }
        } else {
            console.error(`Fehler: Die Funktion ${constraint.function} für Constraint ${constraint.name} konnte nicht geladen werden oder ist keine Funktion.`);
        }

        // Performance-Log
        let now = performance.now();
        let duration = now - performanceStart;
        console.log(`Dauer Soft Constraint ${constraint.name}: ${Math.round(duration)} ms`);
    }

    return {
        costsPerConstraint,
        totalConstraintCosts
    };
}

// Hilfsfunktion zum Abrufen einer Soft-Constraint-Liste
export async function fetchSoftConstraints() {
    try {
        const constraintsCol = collection(db, 'constraints');
        const constraintsQuery = query(
            constraintsCol,
            where("isHardConstraint", "==", false),
            where("deleted", "!=", true)
        );
        const constraintsSnapshot = await getDocs(constraintsQuery);
        return constraintsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        })).sort((a, b) => a.name.localeCompare(b.name));

    } catch (error) {
        console.error("Fehler beim Laden der Soft Constraints: ", error);
    }
}




