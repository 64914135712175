import { collection, doc, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import moment from 'moment';
import { CheckboxCircleFill, ErrorWarningFill } from '../../../components/assets/Icons'

export const validate_recSgOYbSbhOux6Cg = async (solutionId) => {
    try {
        const events = await fetchFilteredEvents(solutionId);
        let isValid = true;  // Annahme, dass alle Events gültig sind, bis das Gegenteil bewiesen wird

        const tableData = events.map(event => {
            // console.log('event: ', event);
            // Überprüfen, ob das Event im spezifischen Raum stattfindet
            const isEventValid = event.location && event.location.roomId === 'recP8K2M2BbzKaa2h';
            if (!isEventValid) {
                isValid = false;  // Ein ungültiges Event gefunden
            }

            const iconComponent = isEventValid
                ? <CheckboxCircleFill color='rgba(26, 198, 179, 1)' size='20px' />
                : <ErrorWarningFill color='rgba(233, 85, 51, 1)' size='20px' />;
            return {
                title: event.title,
                start: moment(event.start).format("DD.MM.YYYY, HH:mm") + ' Uhr', // Formatieren des Datums
                roomName: event.location && event.location.roomName ? event.location.roomName : 'Kein Raum',
                roomId: event.location && event.location.roomId ? event.location.roomId : 'Keine ID',
                icon: iconComponent  // Hier fügen wir das Icon direkt als Komponente hinzu
            };
        });

        // Das Gesamticon wird basierend auf der Gültigkeit des Ergebnisses bestimmt
        const overallIcon = isValid
            ? <CheckboxCircleFill color='rgba(26, 198, 179, 1)' size='18px' />
            : <ErrorWarningFill color='rgba(233, 85, 51, 1)' size='18px' />;

        return {
            type: 'table',
            data: tableData,
            isValid: isValid,  // Rückgabe des Gesamtergebnisses
            overallIcon: overallIcon  // Rückgabe des Icons, das den Gesamtzustand anzeigt
        };
    } catch (error) {
        console.error('Fehler beim Abrufen der Events:', error);
        return {
            type: 'error',
            message: 'Es ist ein Fehler aufgetreten.',
            isValid: false,  // Rückgabe, dass bei einem Fehler die Validierung als ungültig betrachtet wird
            overallIcon: <ErrorWarningFill color='rgba(233, 85, 51, 1)' size='18px' />
        };
    }
}

const fetchFilteredEvents = async (solutionId) => {
    if (!solutionId) {
        throw new Error('Keine solutionId übergeben.');
    }

    const solutionRef = doc(db, "solutions", solutionId);
    // Korrekte Anwendung mehrerer where-Filter in einer einzigen Query
    const eventsQuery = query(
        collection(solutionRef, "events"),
        where("extendedProps.courseType", "==", "Praktikum"),
        where("extendedProps.subject", "==", "Anatomie")
    );
    const eventsSnapshot = await getDocs(eventsQuery);
    return eventsSnapshot.docs.map(doc => doc.data());
};
