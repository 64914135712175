import React, {useEffect, useRef, useState} from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import deLocale from '@fullcalendar/core/locales/de';
import {db} from '../firebase';
import config from '../config';
import {collection, doc, getDoc, getDocs} from 'firebase/firestore';
import isStudentInGroup from "../utilities/isStudentInGroup";
import {generateLectureFreePeriodEvents} from '../utilities/ergebnis/generateLectureFreePeriodEvents';
import {extractSemesters, getStudentIds} from "../utilities/utilityFunctions";
import {getEventColor} from "../utilities/ergebnis/ergebnisUtilityFunctions";
import {loadPublicHolidays} from "../utilities/ergebnis/loadPublicHolidays";
import SolutionSelector from "./features/SolutionSelector/SolutionSelector";
import './Ergebnis.css';
import {CloseLineIcon} from "./assets/Icons";
import SubjectSelector from "./features/SubjectSelector/SubjectSelector";
import {loadRoomData} from "../utilities/utilityRoomFunctions";

function Ergebnis() {
    const [selectedResultId, setSelectedResultId] = useState("");
    const [selectedStudentId, setSelectedStudentId] = useState("all");
    const [selectedSemester, setSelectedSemester] = useState("1");
    const [selectedSubjectId, setSelectedSubjectId] = useState("");
    const [selectedSubjectName, setSelectedSubjectName] = useState("");
    const [roomData, setRoomData] = useState([]);
    const [resources, setResources] = useState([]);
    const [semesters, setSemesters] = useState([]);
    const [events, setEvents] = useState([]);
    const [initialDate, setInitialDate] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const calendarRef = useRef(null);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setSemesters(extractSemesters());  // Setze die Semester beim Laden der Komponente
    }, []);

    // Handler für die Auswahl eines Fachs
    const handleSubjectSelected = (selectedId, selectedName) => {
        setSelectedSubjectId(selectedId);
        setSelectedSubjectName(selectedName);
    };

    useEffect(() => {
        // Setze initialDate zurück, wenn eine neue Auswahl getroffen wird
        setInitialDate(null);
    }, [selectedResultId]);

    useEffect(() => {
        const fetchEvents = async () => {
            if (selectedResultId) {

                setLoading(true);
                const solutionRef = doc(db, "solutions", selectedResultId);
                const solutionSnapshot = await getDoc(solutionRef);
                if (solutionSnapshot.exists()) {
                    const sourceDataId = solutionSnapshot.data().sourceDataId;

                    // Lade alle Raum- und Entfernungsdaten
                    let loadedRoomData = await loadRoomData(sourceDataId);
                    setRoomData(loadedRoomData);

                    function sortResourcesByTitle(resources) {
                        return resources.sort((a, b) => (a.title || "").localeCompare(b.title || ""));
                    }

                    const roomResources = loadedRoomData.reduce((acc, room) => {
                        let campusTitle = room.campus[0];
                        let buildingTitle = room.buildingName[0];

                        // Finde oder erstelle die Campus-Ebene
                        let campusGroup = acc.find(g => g.title === campusTitle);
                        if (!campusGroup) {
                            campusGroup = {
                                id: `campus-${campusTitle}`,
                                title: campusTitle,
                                children: []
                            };
                            acc.push(campusGroup);
                        }

                        // Sortiere Campus, nachdem ein neuer Campus hinzugefügt wurde
                        sortResourcesByTitle(acc);

                        // Finde oder erstelle die Gebäude-Ebene innerhalb des Campus
                        let buildingGroup = campusGroup.children.find(g => g.title === buildingTitle);
                        if (!buildingGroup) {
                            buildingGroup = {
                                id: `building-${room.building[0]}`,
                                title: buildingTitle,
                                children: []
                            };
                            campusGroup.children.push(buildingGroup);
                        }

                        // Sortiere Gebäude, nachdem ein neues Gebäude hinzugefügt wurde
                        sortResourcesByTitle(campusGroup.children);

                        // Füge Räume dem entsprechenden Gebäude hinzu
                        buildingGroup.children.push({
                            id: room.id,
                            title: room.name,
                            occupancy: room.allowedGroupSizes.join(", ")
                        });

                        // Sortiere Räume, nachdem ein neuer Raum hinzugefügt wurde
                        sortResourcesByTitle(buildingGroup.children);

                        return acc;
                    }, []);

                    setResources(roomResources);
                }
                const eventsSnapshot = await getDocs(collection(solutionRef, "events"));
                let loadedEvents = [];

                eventsSnapshot.forEach(eventDoc => {
                    loadedEvents.push(eventDoc.data());
                });

                // Filtere Events basierend auf der ausgewählten Studenten-ID
                if (selectedStudentId !== "all") {
                    loadedEvents = loadedEvents.filter(event =>
                        isStudentInGroup(selectedStudentId, event.extendedProps.group)
                    );
                }
                // Filtere Events basierend auf dem gewählten Fachsemester
                if (selectedSemester !== "all") {
                    loadedEvents = loadedEvents.filter(event =>
                        event.extendedProps.semester === parseInt(selectedSemester, 10)
                    );
                }
                // Filtere Events basierend auf dem gewählten Fach
                if (selectedSubjectId !== "") {
                    loadedEvents = loadedEvents.filter(event =>
                        event.extendedProps.subject === selectedSubjectName
                    );
                }

                // Weihnachtspause als Hintergrundevent hinzufügen
                const christmasBreakEvent = {
                    title: config.christmasBreak.title,
                    start: config.christmasBreak.startDate,
                    end: new Date(new Date(config.christmasBreak.endDate).getTime() + (1000 * 60 * 60 * 24)).toISOString().split('T')[0], // Ein Tag hinzufügen, da das Ende exklusiv ist
                    display: 'background',
                    color: 'rgba(49, 56, 61, 0.2)'
                };

                // Vorlesungsfreie Zeiten berechnen und hinzufügen
                const lectureFreePeriodEvents = generateLectureFreePeriodEvents();

                // Gesetzliche Feiertage hinzufügen
                const holidays = loadPublicHolidays(initialDate);

                if (loadedEvents.length > 0) {

                    // Übertrage die Raum-IDs an die für FullCalendar notwendige Stelle
                    loadedEvents = loadedEvents.map(event => {
                        // Überprüfe, ob 'locations' existiert und mindestens ein Element enthält
                        const resourceIds = event.locations && event.locations.length > 0
                            ? event.locations.map(location => location.roomId).filter(id => id != null) // Filtere ungültige oder fehlende IDs
                            : []; // Rückgabe eines leeren Arrays, wenn keine 'locations' vorhanden sind

                        return {
                            ...event,
                            resourceIds
                        };
                    });

                    // Finde das älteste Datum unter allen Events
                    const oldestDate = loadedEvents.reduce((oldest, event) => {
                        const eventDate = new Date(event.start);
                        return eventDate < oldest ? eventDate : oldest;
                    }, new Date("9999-12-31")); // Sehr weit in der Zukunft als Anfangswert

                    setInitialDate(oldestDate.toISOString().slice(0, 10)); // Setzt das älteste Datum als initialDate
                } else {
                    setInitialDate(null); // Keine Events, kein initialDate
                }

                setEvents([...loadedEvents, ...holidays, christmasBreakEvent, ...lectureFreePeriodEvents]);
                setLoading(false);
            }
        };
        fetchEvents();
    }, [selectedResultId, selectedStudentId, selectedSemester, selectedSubjectId, selectedSubjectName, initialDate]);


    useEffect(() => {
        if (calendarRef.current && initialDate) {
            const api = calendarRef.current.getApi();
            const dateObject = new Date(initialDate); // Konvertiere initialDate in ein Date-Objekt
            if (!isNaN(dateObject)) { // Prüfe, ob dateObject ein gültiges Datum ist
                api.gotoDate(dateObject); // Navigiere explizit zum neuen initialDate
            }
        }
    }, [initialDate]);

    // Funktion zum Öffnen der Detailansicht
    const openDetails = (clickInfo) => {
        clickInfo.jsEvent.preventDefault();
        setSelectedEvent(clickInfo.event);
        setDetailsOpen(true);
        setTimeout(() => {
            if (calendarRef.current) {
                calendarRef.current.getApi().updateSize();
            }
        }, 300);
    };

    // Funktion zum Schließen der Detailansicht
    const closeDetails = () => {
        setDetailsOpen(false);
        setSelectedEvent(null);
        setTimeout(() => {
            if (calendarRef.current) {
                calendarRef.current.getApi().updateSize();
            }
        }, 300);
    };

    return (
        <div className="page-content">
            <div className="page-sidebar">
                <SolutionSelector
                    onSelect={setSelectedResultId}
                />
                <SubjectSelector
                    solutionId={selectedResultId}
                    onSelect={(selectedId, selectedName) => handleSubjectSelected(selectedId, selectedName)}
                />
                <div>
                    <label>Fachsemester</label>
                    <select value={selectedSemester} onChange={(e) => setSelectedSemester(e.target.value)}>
                        <option value="all">alle</option>
                        {semesters.map((semester, index) => (
                            <option key={index} value={semester}>{semester}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Studierende</label>
                    <select value={selectedStudentId} onChange={(e) => setSelectedStudentId(e.target.value)}>
                        <option value="all">alle</option>
                        {getStudentIds.map(id => (
                            <option key={id} value={id}>{id}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="page-item colspan-with-sidebar">
                <div className="ergebnis-container">
                    <div className="ergebnis-calendar-container">
                        {loading && <div className="spinner">Lädt ...</div>}
                        {selectedResultId && initialDate && (
                            <FullCalendar
                                ref={calendarRef}
                                plugins={[dayGridPlugin, timeGridPlugin, listPlugin, resourceTimelinePlugin]}
                                views={{
                                    multiYearList: {
                                        type: 'list',
                                        duration: { years: 2 },
                                        buttonText: 'Liste'
                                    }
                                }}
                                locale={deLocale}
                                initialView="timeGridWeek"
                                resources={resources}
                                resourceOrder="title"
                                resourceAreaWidth={380}
                                resourceAreaColumns={[
                                    {
                                        labelText: 'Räume',
                                        field: 'title',
                                        headerContent: 'Räume'
                                    }
                                ]}
                                initialDate={initialDate}
                                fixedWeekCount="true"
                                headerToolbar={{
                                    left: 'prev,next oldest',
                                    center: 'title',
                                    right: 'dayGridMonth,timeGridWeek,timeGridDay,multiYearList resourceTimelineMonth'
                                }}
                                customButtons={{
                                    oldest: {
                                        text: 'Startwoche',
                                        click: () => {
                                            setTimeout(() => {
                                                if (calendarRef.current) {
                                                    const api = calendarRef.current.getApi();
                                                    if (api) {
                                                        api.gotoDate(new Date(initialDate));
                                                    } else {
                                                        console.error('Failed to retrieve FullCalendar API');
                                                    }
                                                } else {
                                                    console.error('Calendar reference not available');
                                                }
                                            }, 10); // Kurze Verzögerung einfügen
                                        }
                                    },
                                    resourceTimelineMonth: {
                                        text: 'Räume',
                                        click: () => {
                                            const api = calendarRef.current?.getApi();
                                            if (api) {
                                                api.changeView('resourceTimelineMonth');
                                            } else {
                                                console.error('Failed to retrieve FullCalendar API');
                                            }
                                        }
                                    }
                                }}
                                events={events}
                                eventClick={openDetails}
                                eventTimeFormat={{
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: false // Nutze das 24-Stunden-Format
                                }}
                                slotMinTime="07:00:00"  // Beginn der Anzeige um 7 Uhr morgens
                                slotMaxTime="21:00:00"  // Ende der Anzeige um 21 Uhr abends
                                eventDidMount={function(info) {
                                    // Wende die Farbe basierend auf dem courseType des Events an
                                    info.el.style.backgroundColor = getEventColor(info.event.extendedProps.courseType);
                                    info.el.style.borderColor = getEventColor(info.event.extendedProps.courseType);
                                }}
                            />
                        )}
                    </div>
                    <div className={`ergebnis-event-details ${detailsOpen ? 'open' : ''}`}>
                        {selectedResultId && selectedEvent && (
                            <>
                                <button onClick={closeDetails} className="icon-button">
                                    <CloseLineIcon color="rgba(161, 179, 195, 1)"/>
                                </button>
                                <h3>{selectedEvent.title}</h3>
                                {selectedEvent.extendedProps.locations && selectedEvent.extendedProps.locations.length > 0 ? (
                                    selectedEvent.extendedProps.locations.map((location, index) => (
                                        <p className="location" key={index}>
                                            {location.roomName || 'Unbekannter Raum'},
                                            {location.buildingName || 'Unbekanntes Gebäude'}<br />
                                            {location.roomId}
                                        </p>
                                    ))
                                ) : (
                                    <p className="location">Kein Raum verfügbar</p>
                                )}
                                <p>
                                    <label>Beginn</label>
                                    {new Date(selectedEvent.start).toLocaleString('de-DE')} Uhr
                                </p>
                                <p>
                                    <label>Ende</label>
                                    {new Date(selectedEvent.end).toLocaleString('de-DE')} Uhr
                                </p>
                                <p>
                                    <label>Fach</label>
                                    {selectedEvent.extendedProps.subject || 'Nicht verfügbar'}
                                </p>
                                <p>
                                    <label>Unterrichtsform</label>
                                    {selectedEvent.extendedProps.courseType || 'Nicht verfügbar'}
                                </p>
                                <p>
                                    <label>Gruppe</label>
                                    {selectedEvent.extendedProps.group || 'Nicht verfügbar'}
                                </p>
                                <p>
                                    <label>Termin-ID</label>
                                    {selectedEvent.extendedProps.id || 'Nicht verfügbar'}
                                </p>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Ergebnis;