import {groups} from "../../groups";
import config from "../../config";
import {findGroupNamesByPrefix} from "../utilityFunctions";
import getStudentIntersectionGroups from "./getStudentIntersectionGroups";

export default function createCourseObject(courses, semesterLimit = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]) {
    function createEventGroups(course) {

        let groupsObject = course.groupSize === 1
            ? Array.from({ length: config.studentsPerYear }, (_, i) => `1G-${i + 1}`)
            : findGroupNamesByPrefix(course.groupSize, groups) || [];
        const totalEvents = Math.ceil((course.lessonUnits * 45) / course.eventDuration) || 0;
        let eventGroups = [];

        for (let i = 1; i <= totalEvents; i++) {
            let events = [];
            for (let j = 1; j <= groupsObject.length; j++) {
                let studentIntersectionGroups = getStudentIntersectionGroups(groupsObject[j-1]) || [];
                let event = {
                    title: course.title,
                    start: null,
                    end: null,
                    extendedProps: {
                        id: `${course.id}.${i}.${j}`,
                        group: groupsObject[j-1] || '',
                        eventDuration: course.eventDuration,
                        groupSize: course.groupSize,
                        courseType: course.courseType,
                        subject: course.subject,
                        module: course.module,
                        campusOptions: course.campusOptions,
                        studentIntersectionGroups,
                        ignorePublicHolidays: course.ignorePublicHolidays
                    }
                };

                // Setze semester nur, wenn es genau einen Wert gibt
                if (course.semesterOptions.length === 1) {
                    event.extendedProps.semester = course.semesterOptions[0];
                }

                events.push(event);
            }
            eventGroups.push({
                title: course.title,
                id: `${course.id}.${i}`,
                events
            });
        }
        return { ...course, eventGroups };
    }

    try {
        return courses.filter(course => {
                const semesterOptions = Array.isArray(course['Fachsemester']) ? course['Fachsemester'].map(Number) : [];
                return semesterOptions.some(semester => semesterLimit.includes(semester));
            }).map(course => {
                const isAsynchronous = !!course['Asynchron'];
                const isNonScheduledCourse = !!course['Wird nicht terminiert'] || isAsynchronous; // wird auch true, wenn isAsynchronous true ist (alle asynchronen Veranstaltungen werden nicht terminiert)

                return {
                    id: course.id || '',
                    title: course['Name'] || '',
                    module: course['Modulkuerzel NEU'] || '',
                    subject: course['Fach'] || '',
                    semesterOptions: Array.isArray(course['Fachsemester']) ? course['Fachsemester'].map(Number) : [],
                    campusOptions: Array.isArray(course['Campus (Name)']) ? course['Campus (Name)'] : [],
                    groupSize: course['Gruppengröße (Bielefeld)'] || 0,
                    studySection: course['Studienabschnitt'] || '',
                    isAsynchronous: isAsynchronous,
                    eventDuration: course['Termindauer (Min.)'] || 0,
                    lessonUnits: course['UE-Präsenz'] || 0,
                    courseType: course['Unterrichtsform'] || '',
                    ignorePublicHolidays: !!course['Feiertage ignorieren'],
                    isNonScheduledCourse: isNonScheduledCourse
                };
            }).map(course => {
                if (course.isAsynchronous || course.isNonScheduledCourse) {
                    return course;
                } else {
                    return createEventGroups(course);
                }
            });
    } catch (error) {
        console.error('Fehler beim Verarbeiten der Kurse:', error);
        return []; // Gebe ein leeres Array zurück oder handle den Fehler entsprechend
    }

}