import React, { useState, useEffect } from 'react';
import SolutionSelector from "../features/SolutionSelector/SolutionSelector";
import ConstraintListGenerator from "../features/ConstraintListGenerator/ConstraintListGenerator";
import {
    validate_recSgOYbSbhOux6Cg
} from "../../utilities/analyse/validateConstraintsFunctions/validate_recSgOYbSbhOux6Cg";
import ValidationResultDisplay from "../features/ValidationResultDisplay/ValidationResultDisplay";
import {
    validate_recAHN2Fs2Qyfh1ol
} from "../../utilities/analyse/validateConstraintsFunctions/validate_recAHN2Fs2Qyfh1ol";
import config from '../../config';
import {
    validate_rec3aPxLLFh4Ssbb5
} from "../../utilities/analyse/validateConstraintsFunctions/validate_rec3aPxLLFh4Ssbb5";

function AnalyseValidierung() {
    const [constraint, setConstraint] = useState(null);
    const [solutionId, setSolutionId] = useState("");
    const [validationResult, setValidationResult] = useState(null);

    // Handler für die Auswahl einer Anforderung
    const handleConstraintSelected = (selectedConstraint) => {
        setConstraint(selectedConstraint);
    };

    // Handler für die Auswahl einer Lösung
    const handleSolutionSelected = (selectedId) => {
        setSolutionId(selectedId);
        setConstraint(null);
    };

    // Mapping von Constraints zu Validierungsfunktionen
    const validationFunctions = {
        "recSgOYbSbhOux6Cg": validate_recSgOYbSbhOux6Cg,
        "recAHN2Fs2Qyfh1ol": validate_recAHN2Fs2Qyfh1ol, // Einführungsmodul vor Stütz- und Bewegungsapparat
        "rec3aPxLLFh4Ssbb5": validate_rec3aPxLLFh4Ssbb5 // Keine Überschneidungen (inklusive Pause)
        // Weitere Validierungsfunktionen
    };

    useEffect(() => {
        async function runValidation() {
            if (constraint && solutionId) {
                const validate = validationFunctions[constraint.id];
                if (validate) {
                    const result = await validate(solutionId);
                    setValidationResult(result);
                } else {
                    setValidationResult({ type: 'error', message: "Keine Validierungsfunktion gefunden." });
                }
            }
        }

        runValidation();
    }, [constraint, solutionId]);

    return (
        <div className="page-content">
            <div className="page-sidebar three-rows">
                <SolutionSelector onSelect={handleSolutionSelected}/>
                <label>Anforderungen</label>
                {solutionId ? (
                    <ConstraintListGenerator
                        onSelect={handleConstraintSelected}
                        validationFunctions={validationFunctions}
                        solutionId={solutionId}
                    />
                ) : (
                    <p className="hint">
                        Bitte Stundenplanlösung wählen, um Anforderungen zu validieren
                    </p>
                )}
            </div>
            <div className="page-item">
            <h2>Anforderungsdetails</h2>
                {constraint && solutionId ? (
                <div>
                    <table className="no-wrap">
                        <tbody>
                        <tr>
                            <td>Anforderung</td>
                            <td>{constraint.name}</td>
                        </tr>
                        <tr>
                            <td>ID</td>
                            <td>
                                <a href={`${config.airtableURLs.interfaceConstraintsURL}/${constraint.id}`}
                                   target="_blank" rel="noopener noreferrer">
                                    {constraint.id}
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>Aktiv</td>
                            <td>{constraint.active ? 'ja' : 'nein'}</td>
                        </tr>
                        <tr>
                            <td>Harte Anforderung</td>
                            <td>{constraint.isHardConstraint ? 'ja' : 'nein'}</td>
                        </tr>
                        <tr>
                            <td>Zielebenen</td>
                            <td>
                                {constraint.objectives.map((objective, index) => (
                                    <span key={index} className="tag">
                                        {objective}
                                    </span>
                                ))}
                            </td>
                        </tr>
                        <tr>
                            <td>Beschreibung</td>
                            <td>{constraint.description}</td>
                        </tr>
                        <tr>
                            <td>Kommentar</td>
                            <td>{constraint.comment ? constraint.comment : 'nicht vorhanden'}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                    ) :
                    <p>Bitte Anforderung wählen</p>
                }
            </div>
            <div className="page-item">
                <h2>Validierungsergebnis</h2>
                {constraint && solutionId && validationResult ? (
                    <ValidationResultDisplay result={validationResult} />
                ) : (
                    <p>Warten auf Auswahl und Ergebnisse...</p>
                )}
            </div>
        </div>
    );
}

export default AnalyseValidierung;
